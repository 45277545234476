// Chakra imports
import {
    AvatarGroup,
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useState} from "react";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import AuthService from "../../../views/services/authService";
import {useHistory} from "react-router-dom";

export default function ManagmentNFT(props) {
    const history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    let clientPic = currentUser.profilePicture
    const {
        title = props.name,
        layout,
        path,
        icon,
    } = props;
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");
//    const [activeRoute, setActiveRoute] = useState(updatedRoutesAdmin[0]);

    const navigateToRoute = (path) => {
        console.log(path)

        history.push(path);
    };
    return (
        <Card p="20px" onClick={() => {
            console.log(layout + path)
            navigateToRoute(layout + path);
        }}>
            <Flex direction={{base: "column"}} justify="center">
                <Flex flexDirection="column" justify="space-between" h="100%">
                    <Flex
                        justify="space-between"
                        direction={{
                            base: "row",
                            md: "column",
                            lg: "row",
                            xl: "column",
                            "2xl": "row",
                        }}
                        mb="auto"
                    >
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "xl",
                                    md: "lg",
                                    lg: "lg",
                                    xl: "lg",
                                    "2xl": "md",
                                    "3xl": "lg",
                                }}
                                mb="5px"
                                fontWeight="bold"
                                me="14px"
                            >
                                {title}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex direction="column" alignItems="center">
                        {/* Custom circular icon */}
                        <Box
                            size="60px"
                            bg="gray.200"
                            borderRadius="50%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {icon}                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}


/** <Link
 href={download}
 mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                                "2xl": "0px",
                            }}
 >
 <Button
 variant="darkBrand"
 color="white"
 fontSize="sm"
 fontWeight="500"
 borderRadius="70px"
 px="24px"
 py="5px"
 >
 Delete
 </Button>
 </Link> **/