import {
    // Existing imports...
    IoPencilOutline,
    IoTrashOutline,
} from "react-icons/io5";
import {
    Select,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
    FormControl,
    FormLabel
} from "@chakra-ui/react";

// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import BuildingDataService from "../../../views/services/buildingService"; // Import the BuildingDataService with the correct path
import AuthService from "../../../views/services/authService";
import UserDataService from "../../../views/services/userService";


const EditBuilding = ({ _id, address, enter, numberOfApartment, onClose, onSave }) => {
    const [manager, setManager] = useState("");

    const [editedAttributes, setEditedAttributes] = useState({
        address: address,
        enter: enter,
        numberOfApartment: numberOfApartment,
        manager: manager,
    });

    const handleAttributeChange = (attribute, value) => {
        setEditedAttributes({
            ...editedAttributes,
            [attribute]: value,
        });
    };

    const handleManagerAttributeChange = (attribute, value) => {
        setEditedAttributes({
            ...editedAttributes,
            manager: {
                ...editedAttributes.manager,
                [attribute]: value,
            },
        });
    };

    const handleSave = () => {
        const updatedBuilding = {
            _id: _id,
            address: editedAttributes.address,
            enter: editedAttributes.enter,
            numberOfApartment: editedAttributes.numberOfApartment,
            manager: manager,
        };
        BuildingDataService.updateBuilding(updatedBuilding)
            .then((response) => {
                alert('Building updated successfully');
                onSave(updatedBuilding);
                onClose();
                window.location.reload();
            })
            .catch((error) => {
                console.log('Error updating building:', error);
            });
    };

    const [users, setUsers] = useState([]);
    const currentUser = AuthService.getCurrentUser();

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then((response) => {
                setUsers(response.data.users);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrieveUsers();
    }, []);

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Building</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mt={2}>
                        <strong>Address:</strong>
                    </Text>
                    <Input value={editedAttributes.address} onChange={(e) => handleAttributeChange('address', e.target.value)} />

                    <Text mt={2}>
                        <strong>Enter:</strong>
                    </Text>
                    <Input value={editedAttributes.enter} onChange={(e) => handleAttributeChange('enter', e.target.value)} />

                    <Text mt={2}>
                        <strong>Number of Apartments:</strong>
                    </Text>
                    <Input
                        value={editedAttributes.numberOfApartment}
                        onChange={(e) => handleAttributeChange('numberOfApartment', e.target.value)}
                    />

                    <FormControl mt="4">
                        <FormLabel>Manager</FormLabel>
                        <Select
                            required
                            placeholder="Select manager"
                            value={editedAttributes.manager}
                            onChange={(e) => {
                                const selectedUserId = e.target.value;
                                const selectedUser = users.find(user => user._id === selectedUserId);

                                setManager(selectedUser);
                                console.log(manager)
                            }}
                        >
                            {users
                                .filter((user) => user.role === 'Manager' && user._id !== currentUser.id)
                                .map((user) => (
                                    <option key={user._id} value={user._id} >
                                        {user.fullName}
                                    </option>
                                ))}
                        </Select>
                    </FormControl>

                    <Button onClick={handleSave}>Save</Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default function BuildingCard({_id,address, enter, numberOfApartment, manager}) {
    const currentUser = AuthService.getCurrentUser();
    const building = {_id,address, enter, numberOfApartment, manager};
    console.log("building",building)
    const textColor = useColorModeValue("navy.700", "white");
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const handleDeleteBuilding = (buildingID) => {
        // Call your API or perform any necessary operations to delete the building
        BuildingDataService.deleteBuilding(buildingID)
            .then((response) => {
                console.log("Building deleted successfully:", response.data);
                // Remove the deleted building from the state
                setBuildings((prevBuildings) => prevBuildings.filter((building) => building._id !== buildingID));
            })
            .catch((error) => {
                console.error("Error deleting building:", error);
            });
    };
    // ... Rest of your component code

    const handleEditBuilding = (building) => {
        setSelectedBuilding(building);
        setShowEditPopup(true);
    };

    const handleSaveEditBuilding = (updatedBuilding) => {
        // Call the API or do any necessary processing to save the updated building details
        console.log("Updated building details:", updatedBuilding);
        // For now, we'll just update the buildings state with the updated details (no API call)
        setBuildings((prevBuildings) =>
            prevBuildings.map((building) => (building.building_id === selectedBuilding.building_id ? {...building, ...updatedBuilding} : building))
        );
    };

    return (
        <Card p="10px">
            {/* ... Other components ... */}

            {/* Building Address */}
            <Text color={textColor}>
                Address: {address} {/* Replace `address` with the actual variable holding the building's address */}
            </Text>

            {/* Building Enter Info */}
            <Text color={textColor}>
                Enter Info: {enter} {/* Replace `enter` with the actual variable holding the building's enter info */}
            </Text>

            {/* Number of Apartments */}
            <Text color={textColor}>
                Number of Apartments: {numberOfApartment} {/* Replace `numberOfApartment` with the actual variable holding the number of apartments */}
            </Text>

            {/* Building manager */}
            <Text color={textColor}>
                Manager: {manager.fullName} {/* Replace `manager` with the actual variable holding the manager's name */}
            </Text>

            <Flex direction="row">
                <Button
                    as="a"
                    variant="darkBrand"
                    color="white"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    me="4px"
                    onClick={() =>
                        handleEditBuilding({
                            address,
                            enter,
                            numberOfApartment,
                            manager,
                        })
                    }
                >
                    <Icon as={IoPencilOutline} me="2px"/>
                    Edit
                </Button>
                <Button
                    variant="darkBrand"
                    color="white"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="12px"
                    py="5px"
                    onClick={() => {
                        handleDeleteBuilding();
                    }}
                >
                    <Icon as={IoTrashOutline} me="2px"/>
                    Delete
                </Button>
            </Flex>
            {/* Popup for editing building details */}
            {showEditPopup && selectedBuilding && (
                <div className="edit-building-popup">
                    <EditBuilding
                        _id={_id}
                        address={selectedBuilding.address}
                        enter={selectedBuilding.enter}
                        numberOfApartment={selectedBuilding.numberOfApartment}
                        manager={selectedBuilding.manager}
                        onClose={() => setShowEditPopup(false)}
                        onSave={handleSaveEditBuilding}
                    />
                </div>
            )}
        </Card>
    );
}
