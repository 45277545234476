import {
    Avatar,
    Box,
    Button,
    Flex, HStack,
    Icon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue, useDisclosure,
} from '@chakra-ui/react';
import {
    MdNotificationsNone,
    MdPowerSettingsNew,
} from 'react-icons/md';
import { SearchBar } from 'UIX/components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'UIX/components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationService from 'views/services/notificationService';
import NotificationComponent from 'views/admin/profile/components/Notifications';
import AuthService from '../../../views/services/authService';
import routes from 'routesRedirection/routesUser.js';

export default function HeaderLinks(props) {
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const toggleNotification = () => {
        setIsNotificationOpen((prevState) => !prevState);
    };
    const logout = () => {
        window.localStorage.clear();
        window.location.href = '/';
    };

    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();

    const handleMenuItemClick = (path) => {
        history.replace(path);
    };

    const handleMenuItemClickNewsletter = () => {
        history.replace('/user/Network');
    };

    const { secondary } = props;

    const navbarIcon = useColorModeValue('gray.400', 'white');
    const menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
    const { isOpen, onToggle } = useDisclosure();
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await NotificationService.getNotificationsByUsername(
                    currentUser.username
                );
                setNotifications(response.data);

                const unreadNotifications = response.data.some(
                    (notification) => !notification.open
                );
                setHasUnreadNotifications(unreadNotifications);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setIsLoading(false);
            }
        };

        fetchNotifications();
    }, [currentUser.username]);
    return (
        <Flex direction="column" >
            <HStack spacing="4">

                <Menu>
                    <SidebarResponsive routes={routes} isOpen={isOpen} onClose={onToggle} />
                </Menu>
                <Menu>
                    <MenuButton p="0px" onClick={toggleNotification} className={hasUnreadNotifications ? 'notification-badge' : ''}>
                        {hasUnreadNotifications && (
                            <div
                                className="notification-badge"
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    marginLeft: '5px',
                                }}
                            />
                        )}
                        <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
                    </MenuButton>
                    <MenuList boxShadow={shadow} p="20px" borderRadius="20px" bg={menuBg} border="none" mt="22px" me={{ base: '30px', md: 'unset' }} minW={{ base: 'unset', md: '400px', xl: '450px' }} maxW={{ base: '360px', md: 'unset' }}>
                        <Box p="10px" maxH="200px" overflowY="auto" borderRadius="20px">
                            <Flex justify="space-between" w="100%" mb="10px">
                                <NotificationComponent />
                            </Flex>
                        </Box>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton p="0px">
                        <Avatar
                            _hover={{ cursor: 'pointer' }}
                            color="white"
                            avatar={currentUser.profilePicture}
                            name={currentUser.username}
                            job={currentUser.role}
                            bg="#11047A"
                            size="sm"
                            width="40px"
                            height="40px"
                        />
                    </MenuButton>
                    <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
                        <Flex w="100%" mb="0px">
                            <Text ps="20px" pt="16px" pb="10px" w="100%" borderBottom="1px solid" borderColor={borderColor} fontSize="sm" fontWeight="700" color={textColor}>
                                👋&nbsp; Hey, {currentUser.username}
                            </Text>
                        </Flex>
                        <SearchBar mb={{ base: '4', md: '0' }} me={{ base: '0', md: '4' }} borderRadius="30px" w={{ base: '100%', md: 'auto' }} />
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            borderRadius="8px"
                            px="14px"
                            onClick={logout}
                        >
                            <Flex align="center">
                                <MdPowerSettingsNew size={20} color={navbarIcon} me="2" />
                                <Text fontSize="sm" ml="2">Logout</Text>
                            </Flex>
                        </MenuItem>
                    </MenuList>
                </Menu>

            </HStack>


        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
