// theme.js

import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

// Define your custom background style
const backgroundStyles = {
    body: {
        backgroundImage: `url(${require("UIX/assets/img/backGround.jpg")})`, // Replace with the correct path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
    },
};

// Extend the Chakra UI theme
const customTheme = extendTheme(
    { breakpoints },
    {
        ...globalStyles,
        styles: {
            ...globalStyles.styles,
            global: backgroundStyles,
        },
    },
    badgeStyles,
    buttonStyles,
    linkStyles,
    progressStyles,
    sliderStyles,
    inputStyles,
    textareaStyles,
    switchStyles,
    CardComponent
);

export default customTheme;
