import React, {useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Flex,
    Text,
    InputGroup,
    useColorModeValue,
} from "@chakra-ui/react";
import AuthService from "../../services/authService";

function ResetPassword() {
    const [username, setUsername] = useState("");

    const [mail, setMail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        console.log("paaw")
        // Perform the logic for sending a password reset email here
        // You can use the email state variable to get the user's email address
        try {
            const userDoc = {
                mail,
                username,
            }
            const response = await AuthService.forgotPassword(userDoc)
            if (response.data.status === "success") {
                alert("success");
            } else {
                alert("Registration failed. Please check the fields.");
            }
            // Handle the response as needed
        } catch (error) {
            console.error("Forgot password error:", error);
            // Handle the error as needed
        }
    };

    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");


    return (
        <Flex
            maxW={{base: "100%", md: "max-content"}}
            w='100%'
            mx={{base: "auto", lg: "0px"}}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{base: "30px", md: "60px"}}
            px={{base: "25px", md: "0px"}}
            mt={{base: "40px", md: "14vh"}}
            flexDirection='column'>
        <Box
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx="auto"
            me="auto"
            h="100vh"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Box mb={8}>
                <Heading color={textColor} fontSize={{ base: "24px", md: "36px" }} mb="10px">
                    Reset Password
                </Heading>
            </Box>

            {resetEmailSent ? (
                <Text color={textColor} fontSize="sm" mb="24px">
                    Hey, check your email for instructions to reset your password.
                </Text>
            ) : (
                <FormControl
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mb={{ base: "20px", md: "auto" }}
                    mx="auto" // Center the form horizontally
                >
                    {/* Email Field */}
                    <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Email
                    </FormLabel>
                    <Box mb={{ base: "12px", md: "24px" }}> {/* Added responsive margin to the InputGroup */}
                        <InputGroup size="md">
                            <Input
                                isRequired={true}
                                fontSize="sm"
                                placeholder="mail@simple.com"
                                size="lg"
                                type="email"
                                variant="auth"
                                value={mail}
                                background="white"
                                onChange={(e) => setMail(e.target.value)}
                            />
                        </InputGroup>
                    </Box>

                    {/* Username Field */}
                    <FormLabel fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                        Username
                    </FormLabel>
                    <Box mb={{ base: "12px", md: "12px" }}> {/* Added responsive margin to the InputGroup */}
                        <InputGroup size="md">
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                placeholder="Jean"
                                type="text"
                            fontWeight="500"
                                size="lg"
                                value={username}
                                background="white"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </InputGroup>
                    </Box>

                    {/* Send Reset Email Button */}
                    <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        mb="24px"
                        onClick={handleResetPassword}
                    >
                        Send Reset Email
                    </Button>
                </FormControl>
            )}

            <Text color={textColorBrand} fontSize="sm" fontWeight="500" mt="4" textAlign="center">
                <a href="/sign-in">Back to Sign In</a>
            </Text>
        </Box>
        </Flex>
    );
}
export default ResetPassword;
