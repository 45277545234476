// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
import IconBox from "UIX/components/icons/IconBox";
import Menu from "UIX/components/menu/BuldingUserMenu";
import React, {useEffect, useState} from "react";
// Assets
import {MdOutlineAccountBalance} from "react-icons/md";
import AuthService from "../../../../services/authService";

export default function Banner(props) {
    const {apartments, address,enter, numberOfApartment, manager} = props;
    const currentUser = AuthService.getCurrentUser();
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (
        <Card mb={{base: "0px", lg: "20px"}} align='center'>
            <Flex w="100%" justify="left" py={4}>
                <Text fontSize="xl" fontWeight="bold">
                    Bulding
                </Text>
                <Menu ms="auto" />
            </Flex>
            <IconBox
                mx='auto'
                h='100px'
                w='100px'
                icon={
                    <Icon as={MdOutlineAccountBalance} color={brandColor} h='46px' w='46px'/>
                }
                bg={box}
            />
            <Text color={textColorSecondary} fontWeight='bold' fontSize='xl' mt='10px'>
                {apartments
                    ? `Apartment : ${
                        apartments.find(
                            (apartment) => apartment.email === currentUser.mail
                        )?.number || 'N/A'
                    }`
                    : 'N/A'}
            </Text>
            <Text color={textColorSecondary} fontSize='md' maxW={{base: "100%", xl: "80%", "3xl": "60%"}} mx='auto'>
                {address ? `${address} : ${enter} ` : 'Supervise your drive space in the easiest way'}
            </Text>
            <Box w='100%' mt='auto'>
                <Flex w='100%' justify='space-between' mb='10px'>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {numberOfApartment ? `Number of Apartments: ${numberOfApartment}` : 'N/A'}
                    </Text>
                    <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                        {manager ? `Manager: ${manager.fullName}` : 'N/A'}
                    </Text>
                </Flex>

            </Box>
        </Card>

    );


}
