import React, {useState, useEffect} from "react";
import ComplaintDataService from "../../../../../services/complaintService";
import AuthService from "../../../../../services/authService";
import ComplaintNFT from "UIX/components/card/ComplaintNFT";
import Banner from "../../../components/Banner";
import banner from "../../../../../../UIX/assets/img/auth/banner.png";
import Card from "UIX/components/card/Card.js";

import {MdOutlineInsertComment,} from "react-icons/md";
import {GoCommentDiscussion} from "react-icons/go";

import {
    Stack,
    TableCaption,
    Box,
    Button,
    Image,
    Flex,
    Grid,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Table, Thead, Tr, Th, Tbody, Td, Text, Input, Textarea, useColorModeValue,
} from "@chakra-ui/react";
import {useHistory, useParams} from "react-router-dom";

function SearchBar({searchTerm, idBuilding, onChange, onClick}) {
    console.log(idBuilding)
    const history = useHistory();
    const handleCreateCatagory = () => {
        console.log(1)
        // Push a new entry onto the history stack and redirect to the desired location
        history.push(`/userTask/newComplaints/${idBuilding}`);

    };
    const handleHistoryInvoice = () => {
        history.push(`/managerTask/historyPool/${idBuilding}`);
    };
    return (
        <Flex
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
            bg="gray.200"
            p="10px"
            borderRadius="8px"
        >
            <Input
                variant="filled"
                fontSize="sm"
                bg="white"
                color="gray.800"
                fontWeight="500"
                _placeholder={{color: "gray.400", fontSize: "14px"}}
                borderRadius="4px"
                placeholder="Search by Category, Date, or Month..."
                value={searchTerm}
                onChange={onChange}
                mr="2"
            />
            <Button colorScheme="teal" variant="solid" size="sm" onClick={onClick}>
                Search
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleCreateCatagory}>
                New
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleHistoryInvoice}>
                Hystory
            </Button>
        </Flex>
    );
}

function ComplaintPopup({title, description, profilePicture, client, currentDate, onClose}) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Image
                        src={profilePicture}
                        w={{base: "100%", "3xl": "100%"}}
                        h={{base: "100%", "3xl": "100%"}}
                        borderRadius="20px"
                    />
                    <p><strong>Client:</strong> {client}</p>
                    <p><strong>Date:</strong> {currentDate}</p>
                    <p>{description}</p>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}


function CommentComplaintPopup({selectedComplaint, onClose}) {
    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody>
                        {/* Main Fields */}
                        {selectedComplaint.comments && selectedComplaint.comments.length > 0 ? (
                            <Table variant="striped" colorScheme="teal">
                                <Thead>
                                    <Tr>
                                        <Th>Username</Th>
                                        <Th>Comment</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedComplaint.comments.map((comment, i) => (
                                        <Tr key={i}>
                                            <Td>{comment.username}</Td>
                                            <Td>{comment.text}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Box textAlign="center">
                                <Text>No comments for this complaint.</Text>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}


function PostComplaintPopup({complaintId, onClose}) {
    const user = AuthService.getCurrentUser()
    // State to store the form inputs
    const [username, setUsername] = useState(user.username);
    const [commentText, setCommentText] = useState('');
    const userDoc = {
        username,
        complaintId,
        commentText
    };
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the postComment function with the form inputs
        ComplaintDataService.postComment(userDoc);
        // Reset form inputs
        setUsername('');
        setCommentText('');
        // Close the popup
    };
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Post a Comment</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Username:
                            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required/>
                        </label>

                        <label>
                            Comment:
                            <Textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} required/>
                        </label>
                        <Button type="submit">Submit</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function ComplaintsOverview(props) {
    const idBuilding = props.idBuilding
    console.log(idBuilding)
    const [refreshKey, setRefreshKey] = useState(0);
    const [complaints, setComplaints] = useState([]);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [showPostComplaintPopup, setShowPostComplaintPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);

    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSearchClick = () => {
        const result = complaints.filter(
            (complaint) =>
                complaint.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                complaint.dateSend.toLowerCase().includes(searchTerm.toLowerCase()) ||
                complaint.datePayed.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setComplaints(result);
    };
    useEffect(() => {
        fetchComplaints();
    }, [refreshKey]);

    const fetchComplaints = async () => {
        try {
            const response = await ComplaintDataService.getAll();
            if (response.data && Array.isArray(response.data.complaints)) {

                const filteredComplaints = response.data.complaints.filter(
                    (complaint) => complaint.idBuilding === idBuilding
                );
                setComplaints(filteredComplaints);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };

    const handleClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowComplaintPopup(true); // Open the complaints popup
    };
    const handleCommentButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowCommentPopup(true); // Open the complaints popup
    };

    const handlePostComplaintButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showComplaintPopup) {
            setShowComplaintPopup(false); // Close the complaints popup if open
        }
        setShowPostComplaintPopup(true); // Open the post complaints popup
    };
    const textColor = useColorModeValue("navy.700", "white");


    const handleClosePopup = () => {
        setShowComplaintPopup(false);
        setShowPostComplaintPopup(false);
        setShowCommentPopup(false);

    };

    // Function to handle the button click and show the PostComplaintPopup


    return (
        <div>
            <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    justifyContent="space-between"
                    gap={{ base: "20px", xl: "20px" }}
                >
                    <Stack spacing={6}> {/* Increase spacing for better readability */}
                        {complaints.length > 0 ? (
                            complaints.map((complaint, i) => (
                                <Card p="10px" key={i}>
                                    <Flex direction="column" alignItems="center">
                                        <Text
                                            color={textColor}
                                            fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
                                            mb="5px"
                                            fontWeight="bold"
                                        >
                                            {complaint.title}
                                        </Text>
                                        <Text fontSize={{ base: "md", md: "sm", lg: "sm", xl: "sm", "2xl": "xs", "3xl": "sm" }}>
                                            {complaint.description}
                                        </Text>
                                        <Text fontSize={{ base: "sm", md: "xs", lg: "xs", xl: "xs", "2xl": "xs", "3xl": "xs" }} color="gray.600">
                                            {complaint.client}
                                        </Text>
                                        <Text fontSize={{ base: "sm", md: "xs", lg: "xs", xl: "xs", "2xl": "xs", "3xl": "xs" }} color="gray.600">
                                            {complaint.currentDate}
                                        </Text>
                                        <Stack direction="row" spacing={4} mt={4}>
                                            <Button
                                                size="sm"
                                                colorScheme="green"
                                                onClick={() => handlePostComplaintButtonClick(complaint)}
                                                leftIcon={<MdOutlineInsertComment />}
                                            >
                                                Comment
                                            </Button>
                                            <Button
                                                size="sm"
                                                colorScheme="blue"
                                                onClick={() => handleCommentButtonClick(complaint)}
                                                leftIcon={<GoCommentDiscussion />}
                                            >
                                                Discussion
                                            </Button>
                                        </Stack>
                                    </Flex>
                                </Card>
                            ))
                        ) : (
                            <Text fontSize="lg" color="gray.600">
                                No matching complaints found.
                            </Text>
                        )}
                    </Stack>

                    {/* Other content for the second column */}
                    <Flex
                        direction="column"
                        mt={{ base: "20px", lg: "0" }} // Adjust margin top for responsiveness
                    >
                        {/* Your content for the second column */}
                    </Flex>
                </Flex>
            </Box>

            {/* Your popups here */}
        </div>
    );

}

export default ComplaintsOverview;
