// chakra imports
import {Box, Flex, HStack, Text, Icon, Stack, useColorModeValue} from "@chakra-ui/react";
import React, {useEffect, useState} from 'react';
//   Custom components
import Brand from "UIX/components/sidebar/components/Brand";
import Links from "UIX/components/sidebar/components/Links";
import SidebarCard from "UIX/components/sidebar/components/SidebarCard";
import MiniCalendar from "../../calendar/MiniCalendar";
import Emergency from "views/emergency";
import Localisation from "views/localisation";
import {MdToday} from "react-icons/md";
import {MdAddAlert} from "react-icons/md";
import { SiGooglemaps } from "react-icons/si";
import {set} from "local-storage";
function SidebarContent(props) {
    let activeColor = useColorModeValue("gray.700", "white");
    let textColor = useColorModeValue("secondaryGray.500", "white");
    const [isCalendarVisible, setCalendarVisibility] = useState(false);
    const [isEmergencyVisible, setEmergencyVisibility] = useState(false);
    const [isLocalisationVisible, setLocalisationVisibility] = useState(false);

    let brandColor = useColorModeValue("brand.500", "brand.400");
    let inactiveColor = useColorModeValue(
        "secondaryGray.600",
        "secondaryGray.600"
    );


    const toggleCalendarVisibility = () => {
        setCalendarVisibility(!isCalendarVisible);
    };
    const toggleEmergencyVisibility = () => {
        setEmergencyVisibility(!isEmergencyVisible);
    };
    const toggleLocalisationVisibility = () => {
        setLocalisationVisibility(!isLocalisationVisible);
    };
  const { routes } = props;
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
        <Stack direction='column' mb='auto' mt='8px'>
            <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
                <Links routes={routes} />
                <HStack
                    spacing={isCalendarVisible ? '22px' : '26px'}
                    py='5px'
                    ps='10px'
                    onClick={toggleCalendarVisibility}
                    style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        marginRight: '10px',
                        background: isCalendarVisible ? brandColor : 'transparent',
                        color: isCalendarVisible ? activeColor : textColor,
                    }}
                >
                    <Flex w='100%' alignItems='center' justifyContent='center'>
                        <Box
                            color={isCalendarVisible ? 'white' : textColor}
                            me='18px'
                        >
                            <Icon as={MdToday} width='20px' height='20px' color='inherit' />
                        </Box>
                        <Text
                            me='auto'
                            fontWeight={isCalendarVisible ? 'bold' : 'normal'}
                            color = {inactiveColor}

                        >
                            Planning
                        </Text>
                    </Flex>


                </HStack>
                <HStack
                    spacing={isEmergencyVisible ? '22px' : '26px'}
                    py='5px'
                    ps='10px'
                    onClick={toggleEmergencyVisibility}
                    style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        marginRight: '10px',
                        background: isEmergencyVisible ? brandColor : 'transparent',
                        color: isEmergencyVisible ? activeColor : textColor,
                    }}
                >

                    <Flex w='100%' alignItems='center' justifyContent='center'>
                        <Box
                            color={isEmergencyVisible ? 'white' : textColor}
                            me='8px'
                        >
                            <Icon as={MdAddAlert} width='20px' height='20px' color='inherit' />
                        </Box>
                        <Text
                            me='auto'
                            fontWeight={isEmergencyVisible ? 'bold' : 'normal'}
                            color = {inactiveColor}

                        >
                            Emergency
                        </Text>
                    </Flex>

                </HStack>

                {/*}
                <HStack
                    spacing={isLocalisationVisible ? '22px' : '26px'}
                    py='5px'
                    ps='10px'
                    onClick={toggleLocalisationVisibility}
                    style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        marginRight: '10px',
                        background: isLocalisationVisible ? brandColor : 'transparent',
                        color: isLocalisationVisible ? activeColor : textColor,
                    }}
                >

                    <Flex w='100%' alignItems='center' justifyContent='center'>
                        <Box
                            color={isLocalisationVisible ? 'white' : textColor}
                            me='8px'
                        >
                            <Icon as={SiGooglemaps} width='20px' height='20px' color='inherit' />
                        </Box>
                        <Text
                            me='auto'
                            fontWeight={isLocalisationVisible ? 'bold' : 'normal'}
                            color = {inactiveColor}

                        >
                            Localisation
                        </Text>
                    </Flex>

                </HStack>
               */}
            </Box>

            {isCalendarVisible && (
                <MiniCalendar
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        marginTop: '10px', // Adjust the spacing as needed
                    }}
                    h='100%'
                    minW='100%'
                    selectRange={false}
                />
            )}
            {isEmergencyVisible && (

                    <Emergency
                    />
            )}
            {isLocalisationVisible && (
                <Localisation/>
            )}

        </Stack>

      <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
