import {
    // Existing imports...
    IoPencilOutline,
    IoTrashOutline,
    IoAddCircleSharp,
} from "react-icons/io5";
import {
    VStack,
    Divider,
    Box,
    HStack,
    Spacer,
    IconButton,
    Button,
    Text,
    Tr,Td,Table,Th,Thead,Tbody,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
} from "@chakra-ui/react";

// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import BuildingDataService from "../../../views/services/buildingService"; // Import the BuildingDataService with the correct path
import UserDataService from "../../../views/services/userService"; // Import the BuildingDataService with the correct path


const ManageResident = ({ _id, residents, onClose }) => {
    const [building, setBuilding] = useState(null);

    useEffect(() => {
        // Fetch the building data when the component mounts
        BuildingDataService.get(_id)
            .then((data) => {
                setBuilding(data.data);
            })
            .catch((error) => {
                console.error("Error fetching building data:", error);
            });
    }, [_id]); // Only fetch data when _id changes

    async function handleAccountCreation(user) {
        if (!building) {
            console.error("Building data is not available.");
            return;
        }

        const userDoc = {
            idNumber:"",
            fullName: user.fullName,
            mail: user.mail,
            username: user.username,
            password: user.password,
            profilePicture: "",
            role: user.role,
            building: building,
        };
        function cloneObject(obj) {
            return { ...obj };
        }

        const updatedBuilding = cloneObject(building);
        try {
            const response = await UserDataService.createUser(userDoc);
            if (response.data.status === "success") {
                console.log(user)

                const residentToUpdate = updatedBuilding.residents.find(
                    (resident) => resident.fullName === user.fullName
                );

                if (residentToUpdate) {
                    // Update the asAccount field to true
                    residentToUpdate.asAccount = true;
                }
                const response2 = await  BuildingDataService.updateBuilding(updatedBuilding)
                // Registration successful
                alert("Account created");
            } else {
                // Registration failed
                alert("Registration failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("An error occurred during registration. Please try again later.");
        }
    }

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Residents Overview</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Apartment</Th>
                                <Th>Contact</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {residents
                                .filter((resident) => resident.asAccount == "false" && resident.asAccount !== "true" && resident.asAccount !== undefined)
                                .map((resident, index) => {
                                    console.log("Resident.asAccount:", resident.asAccount); // Add the console.log statement here
                                    return (
                                        <Tr key={index}>
                                            <Td>{resident.fullName}</Td>
                                            <Td>{resident.apartment}</Td>
                                            <Td>{resident.mail}</Td>
                                            <Td>
                                                <Button
                                                    colorScheme="teal"
                                                    size="sm"
                                                    leftIcon={<IoAddCircleSharp />}
                                                    onClick={() => handleAccountCreation(resident)}
                                                >
                                                    Account
                                                </Button>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};


export default function BuldingManagerNFT({_id, address, enter, numberOfApartment, manager, residents}) {
    if (residents == null)
        residents = []
    const textColor = useColorModeValue("navy.700", "white");
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const handleDeleteBuilding = (buildingID) => {
        // Call your API or perform any necessary operations to delete the building
        BuildingDataService.deleteBuilding(buildingID)
            .then((response) => {
                console.log("Building deleted successfully:", response.data);
                // Remove the deleted building from the state
                setBuildings((prevBuildings) => prevBuildings.filter((building) => building._id !== buildingID));
            })
            .catch((error) => {
                console.error("Error deleting building:", error);
            });
    };
    // ... Rest of your component code

    const handleManageResidents = (building) => {
        setSelectedBuilding(building);
        setShowEditPopup(true);
    };

    return (
        <Box
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            shadow="lg"
            bg={useColorModeValue("white", "gray.800")}
        >
            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                Building Details
            </Text>
            <Text fontSize="lg" color={textColor} my={2}>
                Address: {address}
            </Text>
            <Text fontSize="lg" color={textColor} my={2}>
                Enter Info: {enter}
            </Text>
            <Text fontSize="lg" color={textColor} my={2}>
                Number of Apartments: {numberOfApartment}
            </Text>
            <Text fontSize="lg" color={textColor} my={2}>
                Manager: {manager.fullName}
            </Text>

            <HStack mt={4} spacing={4}>
                <Button
                    colorScheme="teal"
                    leftIcon={<IoPencilOutline/>}
                    onClick={handleManageResidents}
                >
                    Manage Residents
                </Button>
                <Spacer/>
                <IconButton
                    icon={<IoTrashOutline/>}
                    colorScheme="red"
                    onClick={() => handleDeleteBuilding(_id)}
                    aria-label="Delete Building"
                />
            </HStack>

            {showEditPopup && (
                <ManageResident
                    _id={_id}
                    address={address}
                    enter={enter}
                    numberOfApartment={numberOfApartment}
                    manager={manager}
                    residents={residents}
                    onClose={() => setShowEditPopup(false)}
                />
            )}
        </Box>
    );
}
