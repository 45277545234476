import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { IoHeart, IoHeartOutline, IoPencilOutline, IoTrashOutline } from "react-icons/io5";

import AuthService from "../../../views/services/authService";
import InvoiceService from "../../../views/services/invoiceService";
import Card from "UIX/components/card/Card.js";

export default function InvoiceNFT({ title, amount, name, dateSend }) {
    const currentUser = AuthService.getCurrentUser();
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");

    const handleDelete = async (dateSend) => {
        try {
            await InvoiceService.deleteInvoice(dateSend);
            window.location.reload();
            // You can also add code here to update the UI or perform any other necessary actions
        } catch (error) {
            console.error("Error deleting InvoiceNFT:", error);
        }
    };

    const getFormattedMonth = (dateString) => {
        const options = { month: "long" };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <Box
            p="10px"
            borderRadius="20px"
            boxShadow="md"
            position="relative"
            backgroundColor="white"
        >
            {/* Like Button */}
            <Button
                position="absolute"
                bg="white"
                _hover={{ bg: "whiteAlpha.900" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
                p="0px !important"
                top="8px" // Adjust the distance from the top
                right="8px" // Adjust the distance from the right
                borderRadius="50%"
                minW="36px"
                h="36px"
                onClick={() => {
                    setLike(!like);
                }}
            >
                <IoHeart size={20} color={like ? "brand.500" : "gray.300"} />
            </Button>

            <Flex direction="column" alignItems="center">
                {/* Name */}
                <Text
                    color="brand.500"
                    fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
                    mb="5px"
                    fontWeight="bold"
                >
                    {name}
                </Text>

                {/* Category */}
                <Text color="gray.500">{title}</Text>

                {/* Amount */}
                <Text color="brand.500" fontSize="xl" fontWeight="semibold" mt="2">
                    {amount}
                </Text>

                {/* Month */}
                <Text color="gray.500" mt="2" fontWeight="bold">
                    {getFormattedMonth(dateSend)}
                </Text>

                {/* Edit and Delete Buttons */}
                <Flex direction="row" mt="3">
                    <Button
                        variant="outline"
                        colorScheme="brand"
                        size="sm"
                        fontWeight="500"
                        borderRadius="full"
                        px="3"
                        me="2"
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outline"
                        colorScheme="brand"
                        size="sm"
                        fontWeight="500"
                        borderRadius="full"
                        px="2"
                        onClick={() => {
                            handleDelete(dateSend);
                        }}
                    >
                        Delete
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
}
