import AuthService from "../../services/authService";
import React, {useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
// Chakra imports
import {
    Center,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Typography from '@mui/material/Typography';

import DefaultAuth from "layouts/auth/Default";
import swal from 'sweetalert';
// Custom components
import {HSeparator} from "UIX/components/separator/Separator";
// Assets
import illustration from "UIX/assets/img/Logo1.png";
import {FcGoogle} from "react-icons/fc";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import {GoogleLogin} from "react-google-login";
import io from 'socket.io-client'; // Ensure socket.io-client is imported

function SignIn() {
    const initialUser = {
        user_id: null,
        username: "",
        average: "",
        mail: "",
        role: "",
    };
    const [isGoogleLoginClicked, setGoogleLoginClicked] = useState(false);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(initialUser);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleText = useColorModeValue("navy.700", "white");

    const googleHover = useColorModeValue(
        {bg: "gray.200"},
        {bg: "whiteAlpha.300"}
    );
    const googleActive = useColorModeValue(
        {bg: "secondaryGray.300"},
        {bg: "whiteAlpha.200"}
    );

    async function handleGoogleLogin(response) {
        try {
            // Send the Google authentication response to your server for validation
            const idToken = response.tokenId;
            // Here, call your backend API with the idToken and handle user login or registration.

            // For testing purposes, you can print the Google user information:
            console.log(response.profileObj);
        } catch (error) {
            console.error("Google Sign-In Error:", error);
        }
    }

    const handleButtonClick = () => {
        setGoogleLoginClicked(true);
    };

    const history = useHistory();
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const textColor2 = "navy.700"; // Adjust as needed

    const boxStyles2 = {
        backgroundImage: `url(${illustration})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white", // Set text color to contrast with the background
    };

    async function handleLogin(event) {
        event.preventDefault();
        let data = {username, password};
        const response = await AuthService.login(data);
        //const socket = io("http://localhost:5000")
        const socket = io("https://coprobid.onrender.com")
        const currentUser = AuthService.getCurrentUser();
        socket.emit("newUser", {username: currentUser.username})
        localStorage.setItem("deLinkUser", JSON.stringify(currentUser));
        if (response.data.status === "success") {
            swal("Enter").then(() => {
                setUser(response.data);

                if (response.data.role === "Admin") {
                    history.replace("/admin/dashboard");
                } else if (response.data.role === "Manager") {
                    history.replace("/manager/dashboard");
                } else if (response.data.role === "User") {
                    history.replace("/user/dashboard");
                } else {
                    // Handle other cases if needed
                }
            });
        } else {
            swal({
                title: "Error",
                text: "Please check the fields",
                icon: "error",
            });        }
    }

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{base: "100%", md: "max-content"}}
                w='100%'
                mx={{base: "auto", lg: "0px"}}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{base: "30px", md: "60px"}}
                px={{base: "25px", md: "0px"}}
                mt={{base: "40px", md: "14vh"}}
                flexDirection='column'>
                {/* Title */}

                {/* Login Form */}
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{base: "100%", md: "420px"}}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{base: "auto", lg: "unset"}}
                    me="auto"
                    mb={{base: "20px", md: "auto"}}
                >
                    <Center height="10vh" mb="20"> {/* Add margin-bottom to create space */}
                        <Box textAlign="center" bgColor="white" p="4" borderRadius="8px"
                             width="400px"> {/* Set background color and padding */}
                            <Box
                                fontFamily="Montserrat, sans-serif"
                                fontWeight="bold"
                                fontStyle="normal"
                                fontSize="36px"
                                display="flex"
                                alignItems="center"
                                mb="2"
                                color="navy"
                            >
                                CoproX
                                <Text color="gray.500" fontSize="16px" fontStyle="italic" ml="2">(app)</Text>
                            </Box>

                            <Heading color={textColor} fontSize="28px" mb="10px" fontStyle="italic">
                                Sign In
                            </Heading>

                        </Box>
                    </Center>
                    <Flex align="center" mb="25px">
                        <HSeparator  color="white"/>
                    </Flex>
                    <FormControl>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Email
                            <Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md" mb="24px">
                            <Input
                                isRequired={true}
                                fontSize="sm"
                                placeholder="mail@simple.com"
                                size="lg"
                                type="email"
                                variant="auth"
                                value={username}
                                background="white"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </InputGroup>
                        <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            display="flex"
                        >
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                            <Input
                                isRequired={true}
                                fontSize="sm"
                                placeholder="Min. 8 characters"
                                mb="24px"
                                size="lg"
                                type={show ? "text" : "password"}
                                variant="auth"
                                value={password}
                                background="white"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{cursor: "pointer"}}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Box height="5vh" display="flex" justifyContent="center" alignItems="center">
                            <Flex justifyContent="space-between" align="center" mb="24px">
                                <NavLink to="/forgotPassword">
                                    <Text
                                        color={textColorBrand}
                                        fontSize="sm"
                                        w="124px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        Forgot password?
                                    </Text>
                                </NavLink>
                            </Flex>
                        </Box>
                        <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            mb="24px"
                            onClick={handleLogin}
                        >
                            Sign In
                        </Button>
                    </FormControl>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    >
                        <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                            Not registered yet?
                            <NavLink to="/sign-up">
                                <Text
                                    color={textColorBrand}
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                    Create an Account
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
