// Chakra imports
import {Icon, Box, Flex, Spacer, Text, SimpleGrid, Grid} from "@chakra-ui/react";
import {MdBarChart, MdPerson, MdTableRows, MdHandyman, MdOutlineReceiptLong, MdCategory} from "react-icons/md";

// Admin Imports
import React from "react";
import {useState} from "react";
import {Route, useHistory} from "react-router-dom";

import AuthService from "../../services/authService";
import Banner from "../profile/components/Banner";
import banner from "../../../UIX/assets/img/auth/banner.png";
import avatar from "../../../UIX/assets/img/avatars/avatar4.jpeg";
import Interventions from "../profile/components/Interventions";
import routesAdmin from "routesRedirection/routesAdmin.js";

export default function UserReports() {
    const history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    const updatedRoutesAdmin = routesAdmin.filter((route) => route.layout !== "/adminTask");
    const [activeRoute, setActiveRoute] = useState(updatedRoutesAdmin[0]); // Set the first route as the activeRoute initially
    const navigateToRoute = (path) => {
        history.push(path);
    };


    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                <Banner
                    gridArea='1 / 1 / 2 / 2'
                    banner={banner}
                    avatar={avatar}
                    name={currentUser.username}
                    job={currentUser.role}
                    posts={currentUser.mail}
                    followers={currentUser.fullName}
                />
            </Grid>


            {/* Dashboard Boxes */}
            <Box mt="4">
                <Flex flexWrap="wrap" justifyContent="space-between">
                    {updatedRoutesAdmin.map((route, index) => (
                        <Box
                            key={index}
                            w={{base: "100%", md: "48%"}}
                            p="4"
                            bg={activeRoute === route ? "green.200" : "gray.100"} // Highlight the active route
                            onClick={() => navigateToRoute(route.layout + route.path)} // Pass the route to the onClick handler
                        >
                            {/* Box content goes here */}
                            {route.icon}

                        </Box>
                    ))}

                </Flex>
            </Box>

            {/* Render Routes */}
        </Box>

    );
}
