import React from "react";

import {Icon} from "@chakra-ui/react";
import {
    MdBarChart,
    MdPerson,
    MdTableRows,
    MdHandyman,
    MdManageAccounts,
    MdOutlineReceiptLong,
    MdOutlineHowToVote,
    MdCategory,
    MdSpaceDashboard, MdMessage,
} from "react-icons/md";

// Admin Imports
import AdminDashboard from "views/admin/default";
import ComplaintsOverview from "views/admin/complaint/complaintsOverview"
import addComplaints from "views/admin/complaint/addComplaints"
import addInvoice from "views/admin/invoice/addInvoice";
import addPoll from "views/admin/poll/addPolls";
import pollsOverview from "views/admin/poll/pollsOverview";
import invoicesOverview from "views/admin/invoice/invoicesOverview";
import dashboardInvoice from "views/admin/invoice/dashboardInvoice";
import Profile from "views/admin/profile";
import Finance from "views/admin/finances";
import pollsResult from "views/admin/poll/pollsResult";
import ManagmentDashboard from "views/admin/managment/managmentDashboard";
import addCategory from "views/admin/managment/category/addCategory";
import CategoriesOverview from "views/admin/managment/category/categoriesOverview"
import UsersOverview from "views/admin/users/usersOverview"
import addUser from "views/admin/users/addUser"
import BuldingsOverview from "views/admin/buldings/bulding/buldingsOverview"
import addBulding from "views/admin/buldings/bulding/addBulding"
import historyInvoice from "views/admin/invoice/historyInvoice";
import Messenger from "views/admin/messenger/conversationOverview";
import ResidentsOverview from "../views/admin/buldings/bulding/residentsOverview";


const routesAdmin = [
    {
        name: "Home",
        layout: "/admin",
        path: "/default",
        icon: <Icon as={MdTableRows} width='20px' height='20px' color='inherit'/>,
        component: AdminDashboard,
    },
    {
        name: "Account",
        layout: "/admin",
        path: "/dashboard",
        icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit'/>,
        component: Profile,
    },
    {
        name: "Financials",
        layout: "/admin",
        icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit'/>,
        path: "/data-tables",
        component: Finance,
    },
    {
        name: "addPool",
        layout: "/adminTask",
        path: "/addPoll",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
        component: addPoll,
    },
    {
        name: "pollsResult",
        layout: "/adminTask",
        path: "/pollsResult",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
        component: pollsResult,
    },
    {
        name: "Pool",
        layout: "/adminTask",
        path: "/poolDashBoard",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
        component: pollsOverview,
    },
    {
        name: "Repair - Intervention",
        layout: "/admin",
        path: "/complaintOverview",
        icon: <Icon as={MdHandyman} width='20px' height='20px' color='inherit'/>,
        component: ComplaintsOverview,
    },
    {
        name: "Managment",
        layout: "/admin",
        path: "/managmentDashboard",
        icon: <Icon as={MdSpaceDashboard} width='20px' height='20px' color='inherit'/>,
        component: ManagmentDashboard,
    },
    {
        name: "Messenger",
        layout: "/admin",
        path: "/Messenger",
        icon: <Icon as={MdMessage} width='20px' height='20px' color='inherit'/>,
        component: Messenger,
    },
    {
        name: "New Complaint",
        layout: "/adminTask",
        path: "/addComplaint",
        component: addComplaints,
    },
    {
        name: "addInvoice",
        layout: "/adminTask",
        path: "/addInvoice",
        component: addInvoice,
    },
    {
        name: "historyInvoice",
        layout: "/adminTask",
        path: "/historyInvoice",
        component: historyInvoice,
    },
    {
        name: "Category",
        layout: "/adminTask",
        path: "/categoriesOverview",
        icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit'/>,
        component: CategoriesOverview,
    },
    {
        name: "addCategory",
        layout: "/adminTask",
        path: "/addCategory",
        component: addCategory,
    },
    {
        name: "User",
        layout: "/adminTask",
        path: "/usersOverview",
        icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
        component: UsersOverview,
    },
    {
        name: "Buldings",
        layout: "/adminTask",
        path: "/buldingsOverview",
        icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
        component: BuldingsOverview,
    },
    {
        name: "addUser",
        layout: "/adminTask",
        path: "/addUser",
        component: addUser,
    },

    {
        name: "addBulding",
        layout: "/adminTask",
        path: "/addBulding",
        component: addBulding,
    },
    {
        name: "Invoice",
        layout: "/adminTask",
        path: "/invoicesOverview",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: invoicesOverview,
    },
    {
        name: "dashboardInvoice",
        layout: "/adminTask",
        path: "/dashboardInvoice",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: dashboardInvoice,
    },
    {
        name: "Residents",
        layout: "/adminTask",
        path: "/residentsOverview",
        icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
        component: ResidentsOverview,
    },

];

export default routesAdmin;
