import React, {useEffect, useState, useRef} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Box,
    Text,
    Flex,
    Grid,
    SimpleGrid,
} from "@chakra-ui/react";

import ConversationList from '../components/ConversationList';
import Banner from "views/user/dashboardUser/messenger/conversationOverview/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import AuthService from "../../../../services/authService";
import UserDataService from "../../../../services/userService";
import MessageDataService from "../../../../services/messageService";

function ConversationPopup({userConversation, Conversation, onClose, profilPicture}) {
    console.log(userConversation, Conversation)
    const [initialNewMessage, setInitialNewMessage] = useState('');
    const currentUser = AuthService.getCurrentUser();
    const [conversationsUserChoice, setConversationsUserChoice] = useState([]);
    const [currentConversation, setcurrentConversation] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [profilePictures, setProfilePictures] = useState({});
    const chatContainerRef = useRef(null);
    useEffect(() => {
        const conversationsUser = [];
        Conversation.forEach(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
            let userConversationId = userConversation.id || userConversation._id;
            console.log(fromUserId,toUserId,userConversationId)
            if (
                (fromUserId === currentUser._id && toUserId === userConversationId) ||
                (fromUserId === userConversationId && toUserId === currentUser._id)
            ) {
                setcurrentConversation(message)
                const otherUserId = fromUserId === currentUser._id ? toUserId : fromUserId;
                console.log(message)
                const existingConversationIndex = conversationsUser.findIndex(conv =>
                    conv.users.includes(currentUser._id) && conv.users.includes(otherUserId)
                );

                if (existingConversationIndex !== -1) {
                    conversationsUser[existingConversationIndex].messages.push(message);
                } else {
                    conversationsUser.push({
                        users: [currentUser._id, otherUserId],
                        messages: [message]
                    });
                }
            }
        });

        if (conversationsUser.length > 0) {
            console.log(conversationsUser[0].messages[0].conversation)
            setConversationsUserChoice(conversationsUser[0].messages[0].conversation);
        }

        setLoading(false); // Set loading to false when data is fetched
    }, [userConversation, Conversation, currentUser]);

    const handleNewMessageChange = (event) => {
        setInitialNewMessage(event.target.value);
    };
// Function to fetch user data including dashboard picture
    const fetchUserData = async (userName) => {
        try {
            const userData = await UserDataService.getUserByName(userName);
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    };

// Function to fetch dashboard picture URL for a user
    const fetchProfilePictureUrl = async (userName) => {
        try {
            const userData = await fetchUserData(userName);
            if (userData && userData.data.profilePicture) {
                return userData.data.profilePicture;
            } else {
                console.log('User data or dashboard picture missing for:', userName);
                return null;
            }
        } catch (error) {
            console.error('Error fetching dashboard picture:', error);
            return null;
        }
    };

// Inside your component
    useEffect(() => {
        const fetchProfilePictures = async () => {
            const pictureData = {};
            for (const message of conversationsUserChoice) {
                try {
                    const profilePictureUrl = await fetchProfilePictureUrl(message.User);
                    pictureData[message.User] = profilePictureUrl;
                } catch (error) {
                    console.error('Error fetching dashboard picture:', error);
                    pictureData[message.User] = null;
                }
            }
            setProfilePictures(pictureData);
        };

        fetchProfilePictures();
    }, [conversationsUserChoice]);

    const handleSendMessage = async () => {
        setLoading(true);

        const newMessageData = {
            User: currentUser.username,
            text: initialNewMessage, // Update this based on your data structure
            date: new Date().toISOString(), // You can format the date as needed
            // Other necessary properties
        };
        conversationsUserChoice.push(newMessageData);
        const MessageDoc = {
            fromUser: currentConversation.fromUser,
            toUser: currentConversation.toUser,
        }
        try {
            await MessageDataService.addMessageWithNotifcation(MessageDoc, currentConversation._id, initialNewMessage);
            //   window.location.reload();
            setInitialNewMessage(''); // Clear the input field
        } catch (error) {
            console.error('Error sending message:', error);
        }

        setLoading(false);
    };
    const formatTime = (dateTimeString) => {
        const options = {hour: '2-digit', minute: '2-digit'};
        return new Date(dateTimeString).toLocaleTimeString(undefined, options);
    };

    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="chat-container" ref={chatContainerRef}>
                            {loading ? (
                                <div className="loading-text">Loading...</div>
                            ) : (
                                <div className="message-list">
                                    {conversationsUserChoice.map((message, i) => (
                                        <div
                                            key={i}
                                            className={`message-row ${
                                                message.User === currentUser.username ? 'current-user' : 'other-user'
                                            }`}
                                            style={{
                                                justifyContent: message.User === currentUser.username ? 'flex-end' : 'flex-start'
                                            }}
                                        >
                                            {message.User !== currentUser.username && profilePictures[message.User] && (
                                                <div className="profile-picture-container before-message">
                                                    <img
                                                        className="conversation-photo"
                                                        src={profilePictures[message.User]}
                                                        alt="conversation"
                                                    />
                                                    <Flex direction="column">
                                                        <Text
                                                            color="secondaryGray.600"
                                                            fontSize={{
                                                                base: "sm",
                                                            }}
                                                            fontWeight="400"
                                                            mt="5px"
                                                        >
                                                            {formatTime(message.date)}
                                                        </Text>
                                                    </Flex>
                                                </div>
                                            )}
                                            <div className="message-bubble">
                                                <div className="message-content">{message.text}</div>
                                            </div>
                                            {message.User === currentUser.username && profilePictures[message.User] && (
                                                <div className="profile-picture-container after-message">
                                                    <img
                                                        className="conversation-photo"
                                                        src={profilePictures[message.User]}
                                                        alt="conversation"
                                                    />
                                                    <Flex direction="column">
                                                        <Text
                                                            color="secondaryGray.600"
                                                            fontSize={{
                                                                base: "sm",
                                                            }}
                                                            fontWeight="400"
                                                            mt="5px"
                                                        >
                                                            {formatTime(message.date)}
                                                        </Text>
                                                    </Flex>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="message-input">
                                <input
                                    type="text"
                                    value={initialNewMessage}
                                    onChange={handleNewMessageChange}
                                    placeholder="Type your message..."
                                    className="message-input-field"
                                />
                                <button className="send-button" onClick={handleSendMessage}>
                                    Send
                                </button>
                            </div>
                        </div>
                        <style>
                            {`
                                /* Add your custom styles here */
                                .message-row {
                                    display: flex;
                                    margin-bottom: 10px;
                                    align-items: flex-end;
                                }

                                .message-bubble {
                                    background-color: #e1e1e1;
                                    padding: 10px;
                                    border-radius: 10px;
                                    max-width: 70%;
                                }

                                .current-user .message-bubble {
                                    background-color: #0084ff;
                                    color: #ffffff;
                                }

                                .message-input {
                                    display: flex;
                                    margin-top: 20px;
                                    align-items: center;
                                }

                                .message-input-field {
                                    flex: 1;
                                    padding: 10px;
                                    border: 1px solid #ccc;
                                    border-radius: 5px;
                                    font-size: 16px;
                                    margin-right: 10px;
                                }

                                .send-button {
                                    cursor: pointer;
                                    background-color: #0084ff;
                                    color: #ffffff;
                                    border: none;
                                    padding: 10px 20px;
                                    border-radius: 5px;
                                    font-size: 16px;
                                }
                            `}
                        </style>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};


export default function  Messenger(props) {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const filterMessagesWithCurrentUser = (conversations, currentUser) => {
        return conversations.filter(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
             console.log(message,fromUserId,toUserId)
            return fromUserId === currentUser._id || toUserId === currentUser._id;
        });
    };
    const getUsersInvolvedInConversations = (messagesWithCurrentUser, currentUser) => {
        const usersInvolvedInConversations = new Set();
        messagesWithCurrentUser.forEach(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;

            if (fromUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.fromUser);
            }

            if (toUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.toUser);
            }
        });

        return usersInvolvedInConversations;
    };
    const fetchData = async () => {
        try {
            const [userData, messageData] = await Promise.all([
                UserDataService.getAll(),
                MessageDataService.getAllMessages()
            ]);
            console.log(userData, messageData)
            const users = userData.data?.users || [];
            const conversations = messageData.data?.messages || [];
            const currentUser = AuthService.getCurrentUser(); // Replace with the actual code to get the current user
            console.log(currentUser._id)
            const messagesWithCurrentUser = filterMessagesWithCurrentUser(conversations, currentUser);
            const usersInvolvedInConversations = getUsersInvolvedInConversations(messagesWithCurrentUser, currentUser);
            console.log(messagesWithCurrentUser, usersInvolvedInConversations)
            const usersInvolvedArray = Array.from(usersInvolvedInConversations);
            setFilteredUsers(usersInvolvedArray);
            setFilteredMessages(messagesWithCurrentUser);
            console.log(filteredUsers, filteredMessages)
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleConversationClick = (user) => {
        setSelectedConversation(user);
        console.log(user)
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px">
                            {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{base: 1, md: 5}} gap="20px">
                            {filteredUsers.map((user) => (
                                <div
                                    className="conversation-list-item"
                                    key={user._id}
                                    onClick={() => handleConversationClick(user)}
                                >
                                    <img
                                        className="conversation-photo"
                                        src={user.profilePicture}
                                        alt="conversation"
                                    />
                                    <div className="conversation-info">
                                        <h1 className="conversation-title">
                                            {user.username}
                                        </h1>
                                    </div>
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3"}}
                    >
                        {/* Add any other components or content related to PollsOverview */}
                    </Flex>
                </Grid>
            </Box>

            {selectedConversation && (
                <div className="conversation-popup">
                    {/* Render ConversationPopup component with selectedConversation */}
                    <ConversationPopup
                        userConversation={selectedConversation}
                        Conversation={filteredMessages}
                        onClose={() => setSelectedConversation(null)}
                        profilPicture={selectedConversation.profilePicture}
                    />
                </div>
            )}
        </div>
    );


}