import React from "react";

// Chakra imports
import { Button, Flex, Link, Text } from "@chakra-ui/react";

// Assets
import banner from "UIX/assets/img/auth/banner.png";
import {useHistory} from "react-router-dom";

export default function Banner() {
    const history = useHistory();
    const handleHistoryInvoice= () => {
        history.push('/managerTask/historyInvoice');
    };
    // Chakra Color Mode
    return (
        <Flex
            direction='column'
            bgImage={banner}
            bgSize='cover'
            py={{ base: '30px', md: '56px' }}
            px={{ base: '30px', md: '64px' }}
            borderRadius='30px'
        >
            {/* Banner content */}
            <Text
                fontSize={{ base: '24px', md: '34px' }}
                color='white'
                mb='14px'
                maxW={{
                    base: '100%',
                    md: '64%',
                    lg: '46%',
                    xl: '70%',
                    '2xl': '50%',
                    '3xl': '42%',
                }}
                fontWeight='700'
                lineHeight={{ base: '32px', md: '42px' }}
            >
                Invoice Dashboard
            </Text>
            <Text
                fontSize='md'
                color='#E3DAFF'
                maxW={{
                    base: '100%',
                    md: '64%',
                    lg: '40%',
                    xl: '56%',
                    '2xl': '46%',
                    '3xl': '34%',
                }}
                fontWeight='500'
                mb='40px'
                lineHeight='28px'
            >
                Management of CRUD action for invoices
            </Text>

            {/* Card component content */}
            <Flex
                direction='column'
                mb={{ base: '0px', lg: '20px' }}
                align='center'
            >
                <Flex w='100%'>
                    {/* Menu component */}
                    {/* ... */}
                </Flex>
                {/* IconBox component */}
                {/* ... */}
                {/* Apartment Number
                <Text color={textColorSecondary} fontWeight='bold' fontSize='xl' mt='10px'>
                    {apartments
                        ? `Apartment : ${
                            apartments.find((apartment) => apartment.email === currentUser.mail)?.number || 'N/A'
                        }`
                        : 'N/A'}
                </Text>
                {/* Address *
                <Text color={textColorSecondary} fontSize='md' maxW={{ base: '100%', xl: '80%', '3xl': '60%' }} mx='auto'>
                    {address ? `${address} : ${enter} ` : 'Supervise your drive space in the easiest way'}
                </Text>

                <Box w='100%' mt='auto'>
                    <Flex w='100%' justify='space-between' mb='10px'>
                        <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                            {numberOfApartment ? `Number of Apartments: ${numberOfApartment}` : 'N/A'}
                        </Text>
                        <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                            {manager ? `Manager: ${manager.fullName}` : 'N/A'}
                        </Text>
                    </Flex>
                </Box>
                */}
            </Flex>

            {/* History button */}
            <Flex align='center'>
                <Button
                    bg='white'
                    color='black'
                    _hover={{ bg: 'whiteAlpha.900' }}
                    _active={{ bg: 'white' }}
                    _focus={{ bg: 'white' }}
                    fontWeight='500'
                    fontSize='14px'
                    py='20px'
                    px='27'
                    me='38px'
                    onClick={handleHistoryInvoice}
                >
                    History
                </Button>
            </Flex>
        </Flex>
    );
}
