// Index.js (Component)
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {FormControl, FormLabel, Heading, Input, Button, Flex, Text, Box} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import CategoryDataService from "../../../../services/categoryService"; // Update the import path based on your project structure
import {useToast} from "@chakra-ui/toast";
import Banner from "../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";

function Configuration() {
    const history = useHistory();
    const initialCategory = {
        name: "",
        frequency: "",
        profilePicture: "",
    };
    const [name, setName] = useState("");
    const [frequency, setFrequency] = useState("");
    const [profilePicture, setPic] = useState();
    const [picLoading, setPicLoading] = useState(false);
    const [category, setCategory] = useState(initialCategory);
    const toast = useToast();
    const postDetails = (pics) => {
        setPicLoading(true);
        if (pics === undefined) {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
        }
        if (pics.type === "image/jpeg" || pics.type === "image/png") {
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "piyushproj");
            fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
                method: "post",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    setPic(data.url.toString());
                    setPicLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPicLoading(false);
                });
        } else {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
            return;
        }
    };
    const handleCategoryAdd = async (event) => {
        event.preventDefault();
        console.log(category.name, category.frequency, category.profilePicture);
        const userDoc = {
            name,
            frequency,
            profilePicture
        };
        console.log(userDoc)
        try {
            const response = await CategoryDataService.postCategory(userDoc);
            if (response.data.status === "success") {
                // category addition successful

                history.goBack();
            } else {
                alert('ERROR')
            }
        } catch (error) {
            console.error("category addition error:", error);
        }
    };


    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                <Box mb="20px"> {/* Add a margin to create space between Banner and Form */}
                    {/* You may pass the "banner" prop based on your requirements */}
                    <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                </Box>
                <Flex justify="center" align="center" height="100vh">
                    <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                        <form onSubmit={handleCategoryAdd}>
                            <Heading as="h2" size="lg" mb="4">
                                Add Category
                            </Heading>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Frequency</FormLabel>
                                <Input
                                    type="text"
                                    value={frequency}
                                    onChange={(e) => setFrequency(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Upload your Picture</FormLabel>
                                <Input
                                    type="file"
                                    p={1.5}
                                    accept="image/*"
                                    onChange={(e) => postDetails(e.target.files[0])}
                                />
                            </FormControl>
                            <Button type="submit" mt="4" colorScheme="blue">
                                Add Category
                            </Button>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </div>
    );

}

export default Configuration;
