// Chakra imports
import {Box, Grid, Modal, Icon, ModalBody, ModalContent, ModalOverlay, Text, Button} from "@chakra-ui/react";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {getLocation} from 'current-location-geo';

// Custom components
import Banner from "views/user/dashboardUser/dashboard/components/Banner";
import BuldingUser from "views/user/dashboardUser/dashboard/components/BuldingUser";
import MessengerUser from "views/user/dashboardUser/dashboard/components/MessengerUser";
import Card from "UIX/components/card/Card.js";
// Assets
import avatar from "UIX/assets/img/avatars/avatar4.jpeg";
import React, {useEffect, useState} from "react";
import AuthService from "../../../services/authService";
import BuildingDataService from "../../../services/buildingService";
import UserDataService from "../../../services/userService";
import MessageDataService from "../../../services/messageService";
import {MdAccountBalance, MdMessage, MdClose, MdAccountBox} from "react-icons/md";
import BuldingManyUser from "../../../user/dashboardUser/dashboard/components/BuldingManyUser";
import Profil from "../../../user/dashboardUser/account/account";

export default function Overview() {
    const defaultCenter = {lat: -34.397, lng: 150.644}; // Default center if location is not available

    const [buildings, setBuildings] = useState([]);
    const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
    const [isBuldingUserModalOpen, setIsBuldingUserModalOpen] = useState(false);
    const [isMessengerUserModalOpen, setIsMessengerUserModalOpen] = useState(false);

    const currentUser = AuthService.getCurrentUser();

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedConversation, setSelectedConversation] = useState(null);

    const filterMessagesWithCurrentUser = (conversations, currentUser) => {
        return conversations.filter((message) => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
            return fromUserId === currentUser._id || toUserId === currentUser._id;
        });
    };
    useEffect(() => {
        getLocation(function (err, position) {
            if (err) {
                console.error('Error:', err);
            } else {
                console.log('Latitude:', position.latitude);
                console.log('Longitude:', position.longitude);
                console.log('Address:', position.address);
            }
        });
    }, []);

    const [currentLocation, setCurrentLocation] = useState(defaultCenter);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                () => {
                    // Handle error or set default location
                }
            );
        }
    }, []);

    const containerStyle = {
        width: '400px',
        height: '400px'
    };
    const getUsersInvolvedInConversations = (messagesWithCurrentUser, currentUser) => {
        const usersInvolvedInConversations = new Set();
        messagesWithCurrentUser.forEach((message) => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;

            if (fromUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.fromUser);
            }

            if (toUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.toUser);
            }
        });

        return usersInvolvedInConversations;
    };

    const fetchData = async () => {
        try {
            const [userData, messageData] = await Promise.all([UserDataService.getAll(), MessageDataService.getAllMessages()]);

            const users = userData.data?.users || [];
            const conversations = messageData.data?.messages || [];
            const currentUser = AuthService.getCurrentUser();
            const messagesWithCurrentUser = filterMessagesWithCurrentUser(conversations, currentUser);
            const usersInvolvedInConversations = getUsersInvolvedInConversations(messagesWithCurrentUser, currentUser);
            const usersInvolvedArray = Array.from(usersInvolvedInConversations);
            setFilteredUsers(usersInvolvedArray);
            setFilteredMessages(messagesWithCurrentUser);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const response = await BuildingDataService.getAll();
            if (response.data && Array.isArray(response.data.buildingsList)) {
                const filteredBuildings = response.data.buildingsList.filter((building) => {
                    const hasCurrentUserEmail = building.apartments.some((apartment) => apartment.email === currentUser.mail);
                    return hasCurrentUserEmail;
                });
                setBuildings(filteredBuildings);
            }
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    const renderCard = (title, icon, onClick, isOpen) => (
        <Card
            minH="200px"
            p="4"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={onClick}
            cursor="pointer"
            border="2px solid #4A5568"
            bg="white"
        >
            {React.cloneElement(icon, {color: "blue", boxSize: 8})}
            <Text mt="2" fontWeight="bold">
                {title}
            </Text>
            <Modal isOpen={isOpen} onClose={() => onClick(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalBody>
                        <Icon
                            as={MdClose}
                            onClick={() => onClick(false)}
                            position="absolute"
                            top="0"
                            right="0"
                            boxSize={6}
                            cursor="pointer"
                        />
                        {title === "Building" && buildings.length === 1 && (
                            <BuldingUser
                                apartments={buildings[0].apartments}
                                address={buildings[0].address}
                                enter={buildings[0].enter}
                                numberOfApartment={buildings[0].numberOfApartment}
                                manager={buildings[0].manager}
                            />
                        )}
                        {title === "Building" && buildings.length > 1 && (
                            <BuldingManyUser buildings={buildings}/>
                        )}
                        {title === "Profile" && (
                            <Profil
                                idUser={currentUser._id}
                                idNumber={currentUser.idNumber}
                                avatar={currentUser.profilePicture}
                                username={currentUser.username}
                                fullName={currentUser.fullName}
                                role={currentUser.role}
                                mail={currentUser.mail}
                            />
                        )}
                        {title === "Messenger" && (
                            <MessengerUser
                                gridArea={{base: "3 / 1 / 4 / 2", lg: "1 / 3 / 2 / 4"}}
                            />
                        )}

                    </ModalBody>
                </ModalContent>
            </Modal>
        </Card>
    );

    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                    lg: "1.34fr 1fr 1.62fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                {renderCard("Building", <MdAccountBalance
                    size={40}/>, setIsBuldingUserModalOpen, isBuldingUserModalOpen)}
                {renderCard("Profile", <MdAccountBox size={40}/>, setIsBannerModalOpen, isBannerModalOpen)}
                {renderCard("Messenger", <MdMessage size={40}/>, setIsMessengerUserModalOpen, isMessengerUserModalOpen)}
                {/*
                <LoadScript googleMapsApiKey='AIzaSyDfrnILnl2nGhvNGM68-ACmYVxb4cWDypo'>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={currentLocation}
                        zoom={15}
                    >

                        <Marker position={currentLocation}/>
                    </GoogleMap>
                </LoadScript>
        */}
            </Grid>

            <Grid mb="20px" templateColumns={{base: "1fr", lg: "repeat(2, 1fr)", "2xl": "1.34fr 1.62fr 1fr"}}
                  templateRows={{base: "1fr", lg: "repeat(2, 1fr)", "2xl": "1fr"}}
                  gap={{base: "20px", xl: "20px"}}></Grid>

        </Box>
    );
}
