import React from "react";
import CoProX from "UIX/assets/img/Logo1.png";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "UIX/components/icons/Icons";
import { HSeparator } from "UIX/components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
    return (
        <Flex align='center' direction='column'>
            <img src={CoProX} style={{ width: '350px', height: '200px', borderRadius: '10px' }} />
            <HSeparator mb='20px' />
        </Flex>

    );
}

export default SidebarBrand;
