import React from "react";
import { Button, Flex, Text, IconButton, Box } from "@chakra-ui/react";
import { MdCreate, MdUpdate, MdDelete,MdSpaceDashboard } from "react-icons/md";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import { useHistory } from "react-router-dom";

export default function Banner() {
    const history = useHistory();

    // CRUD Operations
    const handleCreate = () => {
        // Your implementation for Create operation
        console.log("Create operation");
    };

    const handleRead = () => {
        // Your implementation for Read operation
        console.log("Read operation");
    };

    const handleUpdate = () => {
        // Your implementation for Update operation
        console.log("Update operation");
    };

    const handleDelete = () => {
        // Your implementation for Delete operation
        console.log("Delete operation");
    };

    return  (
        <Flex
            direction="column"
            bgImage={banner}
            bgSize="cover"
            py={{ base: "30px", md: "56px" }}
            px={{ base: "30px", md: "64px" }}
            borderRadius="30px"
        >
            <Text
                fontSize={{ base: "24px", md: "34px" }}
                color="white"
                mb="14px"
                maxW={{
                    base: "100%",
                    md: "64%",
                    lg: "46%",
                    xl: "70%",
                    "2xl": "50%",
                    "3xl": "42%",
                }}
                fontWeight="700"
                lineHeight={{ base: "32px", md: "42px" }}
            >
                Management Dashboard
            </Text>
            <Text
                fontSize="md"
                color="#E3DAFF"
                maxW={{
                    base: "100%",
                    md: "64%",
                    lg: "40%",
                    xl: "56%",
                    "2xl": "46%",
                    "3xl": "34%",
                }}
                fontWeight="500"
                mb="40px"
                lineHeight="28px"
            >
                Management of CRUD actions for the system
            </Text>
            <Flex align="center">
                {/* CRUD Buttons */}
                <IconButton
                    aria-label="Create"
                    icon={<MdCreate />}
                    onClick={handleCreate}
                    bg="white"
                    color="black"
                    _hover={{ bg: "whiteAlpha.900" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="20px"
                    px="27"
                    me="38px"
                />
                <IconButton
                    aria-label="Read"

                    onClick={handleRead}
                    bg="white"
                    color="black"
                    _hover={{ bg: "whiteAlpha.900" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="20px"
                    px="27"
                    me="38px"
                />
                <IconButton
                    aria-label="Update"
                    icon={<MdUpdate />}
                    onClick={handleUpdate}
                    bg="white"
                    color="black"
                    _hover={{ bg: "whiteAlpha.900" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="20px"
                    px="27"
                    me="38px"
                />
                <IconButton
                    aria-label="Delete"
                    icon={<MdDelete />}
                    onClick={handleDelete}
                    bg="white"
                    color="black"
                    _hover={{ bg: "whiteAlpha.900" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="20px"
                    px="27"
                    me="38px"
                />
            </Flex>
        </Flex>
    );
}
