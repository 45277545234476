import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Image, Grid, SimpleGrid } from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import PollService from "../../../services/pollService"; // Update the import path based on your project structure
import PollsNFT from "../../../../UIX/components/card/PollsNFT";
import Banner from "../components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';

function PollPopup({ question, options, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{question}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant="striped" colorScheme="teal">
                        <Tbody>
                            {options.map((option, i) => (
                                <Tr key={i}>
                                    <Td>{option.option}</Td>
                                    <Td isNumeric>{option.count}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function PollsOverview() {
    const history = useHistory();
    const [polls, setPolls] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState(null);

    useEffect(() => {
        // Fetch polls when the component mounts
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await PollService.getAll();
            console.log(response.data.polls);
            if (response.data && Array.isArray(response.data.polls)) {
                setPolls(response.data.polls);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };

    const handlePollClick = (poll) => {
        setSelectedPoll(poll);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleCreatePollClick = () => {
        console.log("handleCreatePollClick");
        history.push("/adminTask/addPoll"); // Redirect to the "Add Poll" page
    };

    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{ base: "20px", xl: "20px" }}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                    >
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 5 }} gap="20px">
                            {polls.map((poll, i) => (
                                <div key={i} onClick={() => handlePollClick(poll)}>
                                    <PollsNFT
                                        subject={poll.Subject}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex
                        flexDirection="column"
                        gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
                    >
                        {/* Add any other components or content related to PollsOverview */}
                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedPoll && (
                <div className="poll-popup">
                    {/* Your Poll Popup Component */}
                    <PollPopup
                        question={selectedPoll.question}
                        options={selectedPoll.options}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default PollsOverview;
