// Chakra imports
import {Box, Flex, Text, Select, useColorModeValue} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
import PieChart from "UIX/components/charts/PieChart";

import {getPieChartData, getPieChartOptions} from "variables/chartsInvoice";
import {VSeparator} from "UIX/components/separator/Separator";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import InvoiceDataService from "../../../../services/invoiceService";

export default function Conversion(props) {
    const idBuilding = props.idBuilding
    const history = useHistory();
    const [invoices, setInvoices] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [searchTerm, setSearchTerm] = useState(""); // New state for the search term
    const [filteredInvoices, setFilteredInvoices] = useState([]); // New state for filtered invoices
    const [labelNames, setLabelNames] = useState([]);
    const [sumOfAmounts, setLabelSums] = useState([]);
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        // Fetch invoices when the component mounts
        fetchInvoices();
        getLabelCategoriesAndAmountSums(invoices);

    }, []);


    const getLabelCategoriesAndAmountSums = (invoices) => {
        const categoryData = {};

        invoices.forEach((invoice) => {
            const {category, amount} = invoice;

            // Convert amount to an integer if it's a string
            const parsedAmount = parseInt(amount, 10);

            if (!isNaN(parsedAmount)) {
                if (categoryData.hasOwnProperty(category)) {
                    categoryData[category] += parsedAmount;
                } else {
                    categoryData[category] = parsedAmount;
                }
            }
        });
        const labelCategories = Object.keys(categoryData);
        const sumOfAmountsPerCategory = Object.values(categoryData);
        setLabelNames(labelCategories);
        setLabelSums(sumOfAmountsPerCategory);
        setPieData(categoryData)
        console.log(categoryData)
        // Return the label names and sums as an object
        return {categoryData};
    };
    // Initialize the labelNames and sumOfAmounts states

    // Call the function when invoices state changes
    useEffect(() => {
        getLabelCategoriesAndAmountSums(invoices);
    }, [invoices]);

    //console.log(labelNames,sumOfAmounts,pieData)
    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {
                const filteredInvoices = response.data.invoices.filter(
                    (invoice) => invoice.building === idBuilding
                );
                setInvoices(filteredInvoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const {...rest} = props;

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardColor = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    return (
        <Card p='20px' align='center' direction='column' w='100%' {...rest}>
            <Flex px={{ base: "0px", "2xl": "10px" }} justifyContent='space-between' alignItems='center' w='100%' mb='8px'>
                <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
                    Your Pie Chart
                </Text>
                <Select fontSize='sm' variant='subtle' defaultValue='monthly' width='unset' fontWeight='700'>
                    <option value='daily'>Daily</option>
                    <option value='monthly'>Monthly</option>
                    <option value='yearly'>Yearly</option>
                </Select>
            </Flex>

            {sumOfAmounts.length > 0 ? (
                <PieChart h='100%' w='100%' chartData={sumOfAmounts} chartOptions={getPieChartOptions(labelNames)} />
            ) : (
                <div>Loading...</div>
            )}
            <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
            <Flex p='20px' align='center' direction='column' w='100%' >
                <Flex align='center'>
                    {Object.keys(pieData).length > 0 ? (
                        Object.keys(pieData).map((key) => {
                            const entry = pieData[key];
                            return (
                                <Flex direction='column' py='5px' me='10px' key={key}>
                                    <Flex align='center'>
                                        <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
                                        <Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
                                            {key}
                                        </Text>
                                    </Flex>
                                    {/* Display the PieChart component with the data and options */}
                                    <Text fontSize='lg' color={textColor} fontWeight='700'>
                                        {entry} ₪
                                    </Text>
                                    <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
                                </Flex>
                            );
                        })
                    ) : (
                        <div>Loading...</div>
                    )}
                </Flex>
            </Flex>
        </Card>
    );

}
