import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    Grid,
    SimpleGrid,
} from "@chakra-ui/react";
import BuildingDataService from "../../../../services/buildingService"; // Import the BuildingDataService with the correct path
import BuildingCard from "../../../../../UIX/components/card/BuldingNFT"; // Update the import path for BuildingCard
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import Banner from "../../../users/components/Banner";


const BuildingPopup = ({ _id,address, enter, numberOfApartment, manager, onClose }) => {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{address}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        <strong>Enter:</strong> {enter}
                    </Text>
                    <Text>
                        <strong>Number of Apartments:</strong> {numberOfApartment}
                    </Text>
                    <Text>
                        <strong>Manager:</strong> {manager ? manager.fullName : "No manager"} {/* Display manager's full name or a fallback message */}
                    </Text>
                    {/* Add any other building details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

function BuildingsOverview() {
    const [buildings, setBuildings] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    useEffect(() => {
        // Fetch buildings when the component mounts
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const response = await BuildingDataService.getAll();
            console.log(response);
            if (response.data && Array.isArray(response.data.buildingsList)) {
                setBuildings(response.data.buildingsList);
            }
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    const handleBuildingClick = (building) => {
        setSelectedBuilding(building);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{ base: "20px", xl: "20px" }}
                >
                    {/* Main Fields */}
                    <Flex flexDirection="column" gridArea={{ xl: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
                        <Box mb="20px">
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner} />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 5 }} gap="20px">
                            {buildings.map((building) => (
                                <div key={building._id} onClick={() => handleBuildingClick(building)}>
                                    <BuildingCard
                                        _id = {building._id}
                                        address={building.address}
                                        enter={building.enter}
                                        numberOfApartment={building.numberOfApartment}
                                        manager={building.manager}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex flexDirection="column" gridArea={{ xl: "1 / 2 / 2 / 3", "2xl": "1 / 2 / 2 / 3" }}>
                        {/* You can add any additional content for the second column here */}
                    </Flex>
                </Grid>

                {/* Popup for building details */}
                {showPopup && selectedBuilding && (
                    <div className="building-popup">
                        <BuildingPopup
                            _id={selectedBuilding._id}
                            address={selectedBuilding.address}
                            enter={selectedBuilding.enter}
                            numberOfApartment={selectedBuilding.numberOfApartment}
                            manager={selectedBuilding.manager}
                            onClose={handleClosePopup}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
}

export default BuildingsOverview;
