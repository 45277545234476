import React, {useState, useEffect} from "react";
import {
    Text,
    Box,
    Button,
    Flex,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Grid,
    Td,
    TableCaption,
} from "@chakra-ui/react";
import Banner from "./components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import InvoiceDataService from "../../../../services/invoiceService";
import {useHistory, useParams} from 'react-router-dom';

// Extracted SearchBar component
function SearchBar({searchTerm, onChange, idBuilding, onClick}) {
    const history = useHistory();
    const handleCreateCatagory = () => {
        // Push a new entry onto the history stack and redirect to the desired location
        history.push(`/managerTask/addInvoiceNew/${idBuilding}`);
    };

    const handleHistoryInvoice = () => {
        history.push('/managerTask/historyInvoice');
    };
    return (
        <Flex
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
            bg="gray.200"
            p="10px"
            borderRadius="8px"
        >
            <Input
                variant="filled"
                fontSize="sm"
                bg="white"
                color="gray.800"
                fontWeight="500"
                _placeholder={{color: "gray.400", fontSize: "14px"}}
                borderRadius="4px"
                placeholder="Search by Category, Date, or Month..."
                value={searchTerm}
                onChange={onChange}
                mr="2"
            />
            <Button colorScheme="teal" variant="solid" size="sm" onClick={onClick}>
                Search
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleCreateCatagory}>
                New
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleHistoryInvoice}>
                Hystory
            </Button>
        </Flex>
    );
}

function InvoiceOverview(props) {
    const {idBuilding} = useParams();
    const [invoices, setInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const history = useHistory();

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {
                const filteredInvoices = response.data.invoices.filter(
                    (invoice) => invoice.building === idBuilding
                );
                setInvoices(filteredInvoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };


    const handleSearchClick = () => {
        const result = filteredInvoices.filter(
            (invoice) =>
                invoice.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.dateSend.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.datePayed.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredInvoices(result);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const formatDate = (dateString) => {
        const options = {
            month: 'long', // Specify 'short' for abbreviated month names
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };
    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding}/>
                        </Box>
                        <SearchBar searchTerm={searchTerm} onChange={handleSearchChange} onClick={handleSearchClick}
                                   idBuilding={idBuilding}/>
                        {invoices.length > 0 ? (
                            <Table variant="simple" colorScheme="blue" size="sm">
                                <TableCaption fontWeight="bold">Invoice Details</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Category</Th>
                                        <Th>Amount</Th>
                                        <Th>Date Sent</Th>
                                        <Th>Date Paid</Th>
                                        {/* Add more headers as needed */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {invoices.map((invoice, i) => (
                                        <Tr key={i} _hover={{bg: "blue.100"}}>
                                            <Td>
                                                <Text fontWeight="bold" color="teal.500">{invoice.category}</Text>
                                            </Td>
                                            <Td>
                                                <Text fontWeight="semibold" color="blue.700">{invoice.amount}</Text>
                                            </Td>
                                            <Td>
                                                <Text color="gray.600">{formatDate(invoice.dateSend)}</Text>
                                            </Td>
                                            <Td>
                                                <Text color="gray.600">{formatDate(invoice.datePayed)}</Text>
                                            </Td>
                                            {/* Add more cells as needed */}
                                        </Tr>
                                    ))}
                                </Tbody>


                            </Table>
                        ) : (
                            <Text fontSize="lg" color="gray.600">
                                No matching invoices found.
                            </Text>
                        )}
                    </Flex>

                </Grid>
            </Box>
        </div>
    );

}

export default InvoiceOverview;
