import {
    IoPencilOutline,
} from "react-icons/io5";
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
    FormControl,
    FormLabel
} from "@chakra-ui/react";

// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import BuildingDataService from "../../../views/services/buildingService"; // Import the BuildingDataService with the correct path
import AuthService from "../../../views/services/authService";
import UserDataService from "../../../views/services/userService";
import Menu from "../menu/BuldingManagerMenu";

const EditApartmentModal = ({apartments, setApartments, editedApartment, onClose, onSave}) => {
    console.log(22222)
    const [isOpen, setIsOpen] = useState(true);
    const [editedEmail, setEditedEmail] = useState(editedApartment.email);
    const [editedAccount, setEditedAccount] = useState("");
    const handleSaveChanges = () => {
        // Update the email in the apartments array
        const updatedApartments = apartments.map((item) =>
            item.number === editedApartment.number ? {...item, email: editedEmail} : item
        );

        // Update the apartments array in the parent component
        setApartments(updatedApartments);

        onSave(updatedApartments); // Notify parent component about the changes
        setIsOpen(false); // Close the modal
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Edit Apartment {editedApartment.number}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex direction="column">
                        <FormControl mb="4">
                            <FormLabel>Email</FormLabel>
                            <Input
                                value={editedEmail}
                                onChange={(e) => setEditedEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl mb="4">
                            {editedAccount ? (
                                <>
                                    <FormLabel fontSize="lg" mb="2">Account</FormLabel>
                                    <Input
                                        variant="filled"
                                        value={editedAccount}
                                        onChange={(e) => setEditedAccount(e.target.value)}
                                        placeholder="Enter account"
                                        size="md"
                                    />
                                </>
                            ) : (
                                <Text color="gray.500" fontStyle="italic">Request sent, user not joined yet</Text>
                            )}
                        </FormControl>

                        <Button colorScheme="teal" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
const ResidentEditingModal = ({user, setUser, handleAddResident}) => {
    return (
        <Card p="10px" flex="1" mt="4">
            <FormControl mt="4">
                <FormLabel>Email</FormLabel>
                <Input
                    type="email"
                    value={user.mail}
                    onChange={(e) => setUser({...user, mail: e.target.value})}
                />
            </FormControl>
            <FormControl mt="4">
                <FormLabel>Username</FormLabel>
                <Input
                    value={user.username}
                    onChange={(e) => setUser({...user, username: e.target.value})}
                />
            </FormControl>
            <FormControl mt="4">
                <FormLabel>Password</FormLabel>
                <Input
                    type="password"
                    value={user.password}
                    onChange={(e) => setUser({...user, password: e.target.value})}
                />
            </FormControl>
            <FormControl mt="4">
                <FormLabel>Apartment</FormLabel>
                <Input
                    type="number"
                    value={user.apartment}
                    onChange={(e) => setUser({...user, apartment: e.target.value})}
                />
            </FormControl>
            <Button mt="4" onClick={handleAddResident} colorScheme="blue">
                Add residents
            </Button>
        </Card>
    )
        ;
};

const ManageResident = ({_id, address, enter, numberOfApartment, manager, apartments, onClose, onSave}) => {
    const [editedAddress, setEditedAddress] = useState(address);
    const [editedEnter, setEditedEnter] = useState(enter);
    const [editedNumberOfApartment, setEditedNumberOfApartment] = useState(numberOfApartment);
    const [editedManager, setEditedManager] = useState(manager);
    const [editedApartments, setEditedApartments] = useState(apartments);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [users, setUsers] = useState([]);
    const [updateUser, setUpdateUser] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const [selectedResidents, setSelectedResidents] = useState([]);

    const initialUser = {
        fullName: '',
        mail: '',
        username: '',
        password: '',
        apartment: '',
        asAccount: false,
    };

    const [user, setUser] = useState(initialUser);

    const handleEditClick = (apartment) => {
        setSelectedApartment(apartment)
        setShowEditPopup(true);
    };

    const handleSaveEditBuilding = (updatedBuilding) => {
        setShowEditPopup(false);
    };

    const handleAddResident = () => {
        setEditedApartments([...editedApartments, user]);
        setUser(initialUser); // Reset user state for the next form
    };
    const handleManagerSelectChange = (event) => {
        const selectedUserId = event.target.value;
        const selectedUser = users.find((user) => user._id === selectedUserId);
        setEditedManager(selectedUser);
    };
    const handleSave = () => {
        const updatedBuilding = {
            _id: _id,
            address: editedAddress,
            enter: editedEnter,
            numberOfApartment: editedNumberOfApartment,
            manager: editedManager,
            apartments: editedApartments// Save the selected user object as the manager
        };
        BuildingDataService.updateBuilding(updatedBuilding)
            .then((response) => {
                alert('Building updated successfully');
                onSave(updatedBuilding);
                onClose();
            })
            .catch((error) => {
                console.log('Error updating building:', error);
            });
    };

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then((response) => {
                const fetchedUsers = response.data.users;
                setUsers(fetchedUsers);
                // Filter and map select options
                const selectOptions = fetchedUsers
                    .filter(
                        (user) =>
                            user.role === "User" &&
                            user._id !== currentUser.id &&
                            !editedApartments.some((resident) => resident._id === user._id)
                    )
                    .map((user) => (user));
                setUpdateUser(selectOptions);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrieveUsers();
    }, []);

    useEffect(() => {
        if (updateUser.length > 0) {
            console.log("Updated selectOptions:", updateUser);
        }
    }, [updateUser]);

    useEffect(() => {
        console.log("Updated selectedResidents:", selectedResidents);
    }, [selectedResidents]);

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Edit Building</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex>
                        {/* First Column - Form Inputs */}
                        <Card p="10px" flex="1" pl="4">
                            <Flex direction="row" justifyContent="space-between" alignItems="center">
                                <FormControl flex="1" mr="2">
                                    <FormLabel>Address</FormLabel>
                                    <Input value={editedAddress} name="address" readOnly/>
                                </FormControl>
                                <FormControl flex="1" mr="2">
                                    <FormLabel>Enter</FormLabel>
                                    <Input value={editedEnter} name="enter" readOnly/>
                                </FormControl>
                                <FormControl flex="1" mr="2">
                                    <FormLabel>Apartments</FormLabel>
                                    <Input value={editedNumberOfApartment} name="numberOfApartment" readOnly/>
                                </FormControl>
                                <FormControl flex="1">
                                    <FormLabel>Manager</FormLabel>
                                    <Input
                                        required
                                        placeholder="Select manager"
                                        name="editedManager"
                                        value={editedManager ? editedManager.fullName : ''}
                                        onChange={handleManagerSelectChange}
                                        readOnly
                                    />
                                </FormControl>
                            </Flex>
                        </Card>
                    </Flex>
                    <Flex>
                        {/* Second Column - Resident List */}
                        <Card p="10px" flex="1" pl="4">
                            <FormControl>
                                <FormLabel>Residents</FormLabel>
                                {editedApartments.map((apartment, index) => (
                                    <div key={index} mt="2">
                                        {apartment.number && (
                                            <Button
                                                size="sm"
                                                onClick={() => handleEditClick(apartment)}
                                                colorScheme="blue"
                                                mt="1"
                                            >
                                                Apartment {apartment.number}
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </FormControl>

                            {/* Popup for editing building details */}
                            {showEditPopup && selectedApartment && (
                                <div className="edit-building-popup">
                                    <EditApartmentModal
                                        setApartments={setEditedApartments}
                                        apartments={editedApartments}
                                        editedApartment={selectedApartment}
                                        onClose={() => setShowEditPopup(false)}
                                        onSave={handleSaveEditBuilding}
                                    />
                                </div>
                            )}
                        </Card>
                        {/* Third Column - Resident Editing Modal */}
                        <Card p="10px" flex="1" mt="4">
                            <FormControl mt="4">
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={user.mail}
                                    onChange={(e) => setUser({...user, mail: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Username</FormLabel>
                                <Input
                                    value={user.username}
                                    onChange={(e) => setUser({...user, username: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    value={user.password}
                                    onChange={(e) => setUser({...user, password: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Apartment</FormLabel>
                                <Input
                                    type="number"
                                    value={user.apartment}
                                    onChange={(e) => setUser({...user, apartment: e.target.value})}
                                />
                            </FormControl>
                            <Button mt="4" onClick={handleAddResident} colorScheme="blue">
                                Add residents
                            </Button>
                        </Card>
                    </Flex>
                    <Button mt="4" onClick={handleSave} colorScheme="teal">
                        Save
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const BuldingManagerNFT = ({
                               _id,
                               address,
                               enter,
                               numberOfApartment,
                               manager,
                               apartments,
                           }) => {
    if (!apartments) apartments = [];
    const currentUser = AuthService.getCurrentUser();

    const textColor = useColorModeValue("navy.700", "white");
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);

    const handleManageResidents = (building) => {
        setSelectedBuilding(building);
        setShowEditPopup(true);
    };
    const textColorSecondary = "gray.400";


    const handleSaveEditBuilding = (updatedBuilding) => {
        // Assuming you have a state variable for buildings
        // and a setter function setBuildings
        // Update the buildings state with the updated building information
        setBuildings((prevBuildings) =>
            prevBuildings.map((building) =>
                building.building_id === selectedBuilding.building_id
                    ? { ...building, ...updatedBuilding }
                    : building
            )
        );
    };
    const FicheItem = ({ label, value }) => (
        <Flex direction="row" py={2}>
            <Box flexBasis="40%" pr={2} fontWeight="bold">
                {label}:
            </Box>
            <Box flexBasis="60%" color={textColorSecondary} fontSize="md">
                {value}
            </Box>
        </Flex>
    );
    return (
        <Card p="10px">
            <Flex w="100%" justify="left" py={4} align="center">
                <Box flex="1">
                    <Text fontSize="xl" fontWeight="bold">
                        Building
                    </Text>
                </Box>
                {/* Assuming there is a Menu component */}
                <Box>
                    <Menu ms="auto" building={_id} />
                </Box>
            </Flex>

            {/* Building Address */}
            <FicheItem label="Address" value={address} />

            {/* Building Enter Info */}
            <FicheItem label="Enter Info" value={enter} />

            {/* Number of Apartments */}
            <FicheItem label="Number of Apartments" value={numberOfApartment} />

            {/* Building manager */}
            <FicheItem label="Manager" value={manager.fullName} />


            <Flex justify="flex-end">
                <Button
                    variant="outline"
                    color={textColorSecondary} // Set the text color
                    borderColor={textColorSecondary} // Set the border color
                    size="sm"
                    onClick={() => handleManageResidents({
                        _id,
                        address,
                        enter,
                        numberOfApartment,
                        manager,
                        apartments,
                    })}
                    leftIcon={<Icon as={IoPencilOutline} />}
                    _hover={{
                        transform: "scale(1.05)",
                        borderColor: "brand.500", // Adjust the border color on hover
                    }}
                    _active={{
                        transform: "scale(0.95)",
                        borderColor: "brand.600", // Adjust the border color when active
                    }}
                >
                    Manage Residents
                </Button>
            </Flex>
            {/* Popup for editing building details */}
            {showEditPopup && selectedBuilding && (
                <div className="edit-building-popup">
                    <ManageResident
                        _id={_id}
                        address={selectedBuilding.address}
                        enter={selectedBuilding.enter}
                        numberOfApartment={selectedBuilding.numberOfApartment}
                        manager={selectedBuilding.manager}
                        apartments={selectedBuilding.apartments}
                        onClose={() => setShowEditPopup(false)}
                        onSave={handleSaveEditBuilding}
                    />
                </div>
            )}
        </Card>
    );

// FicheItem component for displaying each detail in a fiche style
};

export default BuldingManagerNFT;