import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel, Grid,
    Heading,
    Input, SimpleGrid,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import AuthService from "../../../../services/authService";
import ComplaintDataService from "../../../../services/complaintService";
import Banner from "../../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import ComplaintNFT from "../../../../../UIX/components/card/ComplaintNFT";
import {MdOutlineInsertComment} from "react-icons/md";
import {GoCommentDiscussion} from "react-icons/go";

function NewComplaints(props) {
    const { idBuilding } = useParams();
    console.log(idBuilding);
    const currentUser = AuthService.getCurrentUser();
    const initialComplaint = {
        title: "",
        description: "",
        profilePicture: "",
        username: currentUser.username,
        currentDate: "",
    };

    const [profilePicture, setPic] = useState();
    const [picLoading, setPicLoading] = useState(false);
    const [complaint, setComplaint] = useState(initialComplaint);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [username, setUsername] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const history = useHistory();
    const textColor = useColorModeValue("navy.700", "white");

    const redirect = () => {
        history.replace("/user/overviewComplaint");
    };

    const postDetails = (pics) => {
        setPicLoading(true);
        if (pics === undefined) {
            return;
        }
        if (pics.type === "image/jpeg" || pics.type === "image/png") {
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "piyushproj");
            fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
                method: "post",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    setPic(data.url.toString());
                    setPicLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPicLoading(false);
                });
        } else {
            setPicLoading(false);
            return;
        }
    };

    const handleComplaintPost = async (event) => {
        event.preventDefault();
        try {
            const complaintDoc = {
                idBuilding,
                title,
                description,
                profilePicture,
                client: currentUser.username,
                currentDate,
            };

            const response = await ComplaintDataService.createComplaint(complaintDoc);

            if (response.data.status === "success") {
                // Complaint submission successful
                alert("Complaint Post.");
                setComplaint(response.data);
                history.push(`/managerTask/overviewComplaint/${idBuilding}`);
            } else {
                // Complaint submission failed
                alert("Complaint submission failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Complaint submission error:", error);
            alert("An error occurred during complaint submission. Please try again later.");
        }
    };

    return (


        <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                <Flex
                    flexDirection="column"
                    gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                >


                    <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding}/>
                    <Box p={{base: "4", md: "12"}}>
                        <Heading size="xl" fontWeight="extrabold" textAlign="center" mb="8">
                            New Complaint
                        </Heading>

                        <FormControl>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Title
                                <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                    *
                                </Text>
                            </FormLabel>
                            <Input
                                required
                                type="text"
                                placeholder="Enter the complaint title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Description
                                <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                    *
                                </Text>
                            </FormLabel>
                            <Input
                                required
                                type="text"
                                placeholder="Enter the complaint description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="pic" mt={4}>
                            <FormLabel>Upload your Picture</FormLabel>
                            <Input
                                type="file"
                                p={1.5}
                                accept="image/*"
                                onChange={(e) => postDetails(e.target.files[0])}
                            />
                        </FormControl>

                        <FormControl mt={8}>
                            <Button
                                variant="brand"
                                w="100%"
                                size="lg"
                                onClick={handleComplaintPost}
                            >
                                Submit Complaint
                            </Button>
                        </FormControl>
                    </Box>
                </Flex>
                <Flex
                    flexDirection="column"
                    gridArea={{xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3"}}
                >
                </Flex>
            </Grid>
        </Box>

    );
}

export default NewComplaints;
