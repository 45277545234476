import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid,
    SimpleGrid,
    Button,
} from "@chakra-ui/react";
import BuildingDataService from "../../../../services/buildingService";
import BuildingCard from "../../../../../UIX/components/card/BuldingAdminNFT_Add";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import Banner from "../../../users/components/Banner";

function BuildingsOverview() {
    const [buildings, setBuildings] = useState([]);
    const [showResidentsPopup, setShowResidentsPopup] = useState(false);
    const [selectedResidents, setSelectedResidents] = useState([]);

    useEffect(() => {
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const response = await BuildingDataService.getAll();
            if (response.data && Array.isArray(response.data.buildingsList)) {
                setBuildings(response.data.buildingsList);
            }
            console.log(buildings)
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    const handleResidentsClick = (residents) => {
        setSelectedResidents(residents);
        setShowResidentsPopup(true);
    };

    const handleCloseResidentsPopup = () => {
        setShowResidentsPopup(false);
    };

    const collectAllResidents = () => {
        // Collect all residents from all buildings
        const allResidents = buildings.reduce((accumulator, building) => {
            return [...accumulator, ...building.residents];
        }, []);
        console.log(allResidents)
        setSelectedResidents(allResidents);
        setShowResidentsPopup(true);
    };

    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{ base: "20px", xl: "20px" }}
                >
                    <Flex flexDirection="column" gridArea={{ xl: "1 / 1 / 2 / 2" }}>
                        <Box mb="20px">
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner} />
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 5 }} gap="20px">
                            {buildings.map((building) => (
                                <div key={building._id}>
                                    <BuildingCard
                                        _id = {building._id}
                                        address={building.address}
                                        enter={building.enter}
                                        numberOfApartment={building.numberOfApartment}
                                        manager={building.manager}
                                        residents={building.residents}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                </Grid>
            </Box>
        </div>
    );
}

export default BuildingsOverview;
