import axios from 'axios';
import authHeader from './auth-header';



/*
let instance = axios.create({
  baseURL: "api/v1/coprox",
  headers: authHeader()
})
*/
let instance = axios.create({
  baseURL: "https://coprobid.onrender.com/api/v1/coprox",
  headers: authHeader()
});


instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/coprox" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
              return instance(originalConfig);

      }
    }
    return Promise.reject(err);
  }
);
export default instance;