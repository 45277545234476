import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {IoCheckmarkCircle} from "react-icons/io5";
import {MdBarChart, MdOutlineCalendarToday} from "react-icons/md";
import {RiArrowUpSFill} from "react-icons/ri";
import {useHistory} from "react-router-dom";
import InvoiceDataService from "../../../../services/invoiceService";
import Card from "UIX/components/card/Card.js";
import LineChart from "UIX/components/charts/LineChart"; // Import LineChart component
import {generateLineChartOptions, getLineChartData} from "../../../../../variables/chartsInvoice";

export default function TotalSpent(props) {
    const idBuilding = props.idBuilding
    const history = useHistory();
    const [invoices, setInvoices] = useState([]);
    const [totalSpend, setTotalSpend] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [lineChartData, setLineChartData] = useState(null);
    const [sumTotal, setSumTotal] = useState(null);

    const getLabelCategoriesAndAmountSums = (invoices) => {
        if (!Array.isArray(invoices)) {
            console.error('invoices is not an array');
            return {categoryData: {}, totalPrice: 0};
        }

        const categoryData = {};
        let totalPrice = 0;

        for (const invoice of invoices) {
            if (typeof invoice !== 'object' || !invoice.hasOwnProperty('category') || !invoice.hasOwnProperty('amount')) {
                console.error('Invalid invoice object:', invoice);
                continue;
            }

            const {category, amount} = invoice;
            const parsedAmount = parseInt(amount, 10);

            if (!isNaN(parsedAmount)) {
                categoryData[category] = (categoryData[category] || 0) + parsedAmount;
                totalPrice += parsedAmount;
            }
        }

        setTotalAmount(totalPrice);
        console.log(categoryData,totalPrice);
        return {categoryData, totalPrice};
    };

    useEffect(() => {
        const {categoryData, totalPrice} = getLabelCategoriesAndAmountSums(invoices);
        // Do something with 'categoryData' or 'totalPrice' if needed
    }, [invoices]);

    // Function to fetch invoices from the server
    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {
                const filteredInvoices = response.data.invoices.filter(
                    (invoice) => invoice.building === idBuilding
                );
                setInvoices(filteredInvoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    // Fetch invoices when the component mounts
    useEffect(() => {
        fetchInvoices();

    }, []);

    // Calculate monthly sums for each category
    const frenchMonthAbbreviations = [
        "janv",
        "févr",
        "mars",
        "avril",
        "mai",
        "juin",
        "juil",
        "août",
        "sept",
        "oct",
        "nov",
        "déc",
    ];

    const getMonthlySumByCategory = () => {
        const categoryMonthlySum = {};

        invoices.forEach((invoice) => {
            const { category, dateSend, amount } = invoice;
            const sendDate = new Date(dateSend);
            const monthIndex = sendDate.getMonth(); // Get the numeric month index

            if (!categoryMonthlySum[category]) {
                categoryMonthlySum[category] = Array(frenchMonthAbbreviations.length).fill(0);
            }

            const numericAmount = parseFloat(amount);
            categoryMonthlySum[category][monthIndex] += numericAmount;
        });

        console.log(categoryMonthlySum);
        setTotalSpend(categoryMonthlySum);
        return categoryMonthlySum;
    };

    // Fetch data when the component mounts
    useEffect(() => {
        getMonthlySumByCategory();
    }, [invoices]);

    useEffect(() => {
        // Check if totalSpend has data
        if (Object.keys(totalSpend).length > 0) {
            // Generate LineChart data and options
            const chartData = getLineChartData(totalSpend);
            const chartOptions = generateLineChartOptions(frenchMonthAbbreviations);
            // Update the LineChart data state
            setLineChartData({chartData, chartOptions});
        }
    }, [totalSpend]);
    // Calculate total amount and set the state


    useEffect(() => {
        const fetchData = async () => {
            const data = await getLabelCategoriesAndAmountSums();
            // You can do something with the 'data' here if needed.
        };

        fetchData();
    }, []);


    // Destructure props to get the rest of the props
    const {...rest} = props;

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const iconColor = useColorModeValue("brand.500", "white");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        {bg: "secondaryGray.400"},
        {bg: "whiteAlpha.50"}
    );

    const bgFocus = useColorModeValue(
        {bg: "secondaryGray.300"},
        {bg: "whiteAlpha.100"}
    );

    return (
        <Card
            justifyContent='center'
            align='center'
            direction='column'
            w='100%'
            mb='0px'
            {...props}
        >
            <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
                <Flex align='center' w='100%'>
                    <Button
                        bg={boxBg}
                        fontSize='sm'
                        fontWeight='500'
                        color={textColorSecondary}
                        borderRadius='7px'
                    >
                        <Icon
                            as={MdOutlineCalendarToday}
                            color={textColorSecondary}
                            me='4px'
                        />
                        This month
                    </Button>
                    <Button
                        ms='auto'
                        align='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'
                        {...rest}
                    >
                        <Icon as={MdBarChart} color={iconColor} w='24px' h='24px'/>
                    </Button>
                </Flex>
            </Flex>

            <Flex w='100%' flexDirection={{base: 'column', lg: 'row'}}>
                <Flex flexDirection='column' me='20px' mt='28px'>
                    <Text
                        color={textColor}
                        fontSize='34px'
                        textAlign='start'
                        fontWeight='700'
                        lineHeight='100%'
                    >
                        {totalAmount} ₪
                    </Text>
                    <Flex align='center' mb='20px'>
                        <Text
                            color='secondaryGray.600'
                            fontSize='sm'
                            fontWeight='500'
                            mt='4px'
                            me='12px'
                        >
                            Total Spent
                        </Text>
                        <Flex align='center'>
                            <Icon as={RiArrowUpSFill} color='green.500' me='2px' mt='2px'/>
                            <Text color='green.500' fontSize='sm' fontWeight='700'>
                                +2.45%
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex align='center'>
                        <Icon as={IoCheckmarkCircle} color='green.500' me='4px'/>
                        <Text color='green.500' fontSize='md' fontWeight='700'>
                            On track
                        </Text>
                    </Flex>
                </Flex>
                <Box minH='260px' minW='75%' mt='auto'>
                    {/* Render the LineChart with the updated data and options */}
                    {lineChartData && (
                        <LineChart chartData={lineChartData.chartData} chartOptions={lineChartData.chartOptions}/>
                    )}
                </Box>
            </Flex>
        </Card>
    );
}

