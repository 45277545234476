import React, { useState } from 'react';
import { Box, SimpleGrid, Text, Button } from '@chakra-ui/react';
import AuthService from '../../../services/authService';
import InvoicesOverview from '../../buldings/invoice/invoicesOverview/Light';
import ComplaintsLight from '../../buldings/complaints/overviewComplaint/Light';
import Pool from '../../buldings/poll/pollsOverview';
import Finance from '../../buldings/finances';
import { useParams } from "react-router-dom";
import Banner from "../../buldings/components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";

const UserReports = (props) => {
    const { idBuilding } = useParams();
    const currentUser = AuthService.getCurrentUser();
    const [activeComponent, setActiveComponent] = useState(null);

    // Ajout d'un état pour chaque composant pour gérer l'affichage réduit ou non
    const [componentStates, setComponentStates] = useState({
        InvoicesOverview: true,
        Complaints: true,
        Pool: true,
        Finance: true,
    });

    const handleBoxClick = (section) => {
        if (activeComponent === section) {
            setActiveComponent(null);
        } else {
            setActiveComponent(section);
        }
    };

    // Fonction pour changer l'état d'affichage du composant
    const toggleComponentDisplay = (component) => {
        setComponentStates((prevStates) => ({
            ...prevStates,
            [component]: !prevStates[component],
        }));
    };

    const renderBox = (title, component) => (
        <Box
            key={title}
            p="2" // Ajouter du padding pour l'espace à l'intérieur de la boîte
            bg={activeComponent === title ? 'midnightblue' : 'blue.900'}
            cursor="pointer"
            transition="all 0.3s"
            borderRadius="lg"
            textAlign="center"
            minW="150px" // Définir une largeur minimale
            minH="150px" // Définir une hauteur minimale
            flex={{ base: "1", md: "0.5", xl: "0.5" }} // Définir la flexibilité en fonction de la taille de l'écran

        >
            <Text as="h1" fontSize="2xl" fontWeight="bold" color={activeComponent === title ? 'teal.300' : 'white'}>
                {title}
            </Text>
            <div>
                {componentStates[title] && component}
                {/* Ajouter le bouton "-" pour réduire l'affichage du composant */}
                <Button onClick={() => toggleComponentDisplay(title)}>{componentStates[title] ? "-" : "+"}</Button>
            </div>
        </Box>
    );

    const components = {
        InvoicesOverview: <InvoicesOverview idBuilding={idBuilding} />,
        Complaints: <ComplaintsLight idBuilding={idBuilding} />,
        Pool: <Pool idBuilding={idBuilding} />,
        Finance: <Finance idBuilding={idBuilding} />,
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding} />
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={{ base: "20px", xl: "20px" }}>
                {Object.keys(components).map((title) => renderBox(title, components[title]))}
                {/* Remplacez 'Banner' par votre implémentation réelle */}
            </SimpleGrid>
        </Box>
    );
};

export default UserReports;
