// Chakra imports
import { Flex, Text, Box, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalBody, Icon, useDisclosure } from "@chakra-ui/react";
import {useHistory} from "react-router-dom";

// Custom components
import Card from "UIX/components/card/Card.js";
import IconBox from "UIX/components/icons/IconBox";

import React, {useEffect, useState} from "react";
import { MdOutlineAccountBalance, MdClose } from "react-icons/md";


export default function Banner({ buildings }) {
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const boxColor = "gray.100";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const history = useHistory();
    const handleBuildingClick = (building) => {
        history.push(`/user/default/${building._id}`);
    };
    const handleCloseModal = () => {
        // Close the modal
        onClose();

        // Redirect to the desired route
        history.push('/user/default');
    };


    return (
        <Card mb={{ base: "0px", lg: "20px" }} align="center">
            {buildings.map((building, index) => (
                <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    marginBottom="10px"
                    cursor="pointer"
                    onClick={() => handleBuildingClick(building)}
                    _hover={{
                        transform: "scale(1.1)",
                        transition: "transform 0.3s", // Smooth transition
                    }}
                >
                    <Box>
                        {/* Assuming there is an IconBox component */}
                        <IconBox
                            mx="auto"
                            h="30px"
                            w="30px"
                            icon={<MdOutlineAccountBalance color="blue" boxSize={8} />}
                            bg="white"
                        />
                    </Box>
                    <Box ml="5px">
                        <Text color={textColorSecondary} fontSize="md">
                            {building.address ? `${building.address} : ${building.enter} ` : "N/A"}
                        </Text>
                    </Box>
                </Box>
            ))}
            {/* Modal for BuldingManagerNFT */}
        </Card>
    );
}