import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid,
    SimpleGrid, Image,
} from "@chakra-ui/react";
import InvoiceDataService from "../../../../../services/invoiceService";
import InvoicePaymentLightNFT from "../../../../../../UIX/components/card/InvoicePaymentLightNFT";
import AuthService from "../../../../../services/authService";



function InvoicePopup({name, category, amount, dateSend, datePayed, onClose}) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{name}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Text>
                        <strong>Category:</strong> {category}
                    </Text>
                    <Text>
                        <strong>Amount:</strong> {amount}
                    </Text>
                    <Text>
                        <strong>Send :</strong> {dateSend}
                    </Text>
                    <Text>
                        <strong>Payed:</strong> {datePayed}
                    </Text>
                    {/* Add any other invoice details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

let dictQuantity = [];

function InvoicesOverview() {
    const history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    const [content, setContent] = useState("");
    const [complaints, setComplaints] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const ByClient = (complaints) => {
        let count = 0;
        if (dictQuantity.length > 0) {
            dictQuantity = [];
        }
        complaints.map((complaint, i) => {
            if (complaint.name === currentUser.fullName && complaint.datePayed === '') {
                count = count + 1;
                dictQuantity[count] = complaint;
            }
        });
        console.log(dictQuantity);
        setComplaints(dictQuantity);
        return dictQuantity;
    };

    useEffect(() => {
        // Fetch invoices when the component mounts
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {

                setInvoices(response.data.invoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };
    const handleInvoiceClick = (invoice) => {
        setSelectedInvoice(invoice);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleAddInvoiceClick = () => {
        console.log("handleAddInvoiceClick");
        history.push("/adminTask/addInvoice"); // Redirect to the "AddInvoice" page
    };

    return (
        <div>
            {/* Main Fields */}
            {invoices.map((invoice, i) => (
                <div key={i} onClick={() => handleInvoiceClick(invoice)}>
                    <Box
                        bgColor="gray.100"
                        borderRadius="xl"
                        p="4"
                        mb="4" // Add margin-bottom for space between each invoice
                        cursor="pointer" // Add a pointer cursor to indicate clickable
                        _hover={{
                            bgColor: "gray.200", // Change background color on hover
                        }}
                    >
                        <InvoicePaymentLightNFT
                            id={invoice._id}
                            name={invoice.name}
                            category={invoice.category}
                            amount={invoice.amount}
                        />
                    </Box>
                </div>
            ))}

            {showPopup && selectedInvoice && (
                <div className="invoice-popup">
                    {/* Your Invoice Popup Component */}
                    <Box
                        bgColor="white"
                        borderRadius="xl"
                        p="4"
                        boxShadow="lg" // Add a shadow to the popup
                    >
                        <InvoicePopup
                            name={selectedInvoice.name}
                            category={selectedInvoice.category}
                            amount={selectedInvoice.amount}
                            dateSend={selectedInvoice.dateSend}
                            datePayed={selectedInvoice.datePayed}
                            onClose={handleClosePopup}
                        />
                    </Box>
                </div>
            )}
        </div>
    );


}

export default InvoicesOverview;
