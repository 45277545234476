import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Flex,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import AuthService from "../../../../services/authService";
import UserDataService from "../../../../services/userService";
import CategoryDataService from "../../../../services/categoryService";
import InvoiceDataService from "../../../../services/invoiceService";
import PollService from "../../../../services/pollService";
import Banner from "../../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";

function AddPoll(props) {
    const { idBuilding } = useParams();
    console.log(idBuilding);
    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [poll, setPoll] = useState({
        Subject: "",
        options: ["", "", "", ""],
    });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const toast = useToast();

    useEffect(() => {
        retrieveUsers();
        retrieveCategories();
    }, []);

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then((response) => {
                setUsers(response.data.users);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveCategories = () => {
        CategoryDataService.getAll()
            .then((response) => {
                setCategories(response.data.categories);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handlePollAdd = async (event) => {
        event.preventDefault();
        try {
            const pollDoc = {
                idBuilding: idBuilding,
                Subject: poll.Subject,
                category: selectedCategory ? selectedCategory.name : "",
                options: poll.options.filter((option) => option.trim() !== ""),
            };

            const response = await PollService.create(pollDoc);

            if (response.data.status === "success") {
                toast({
                    title: "Poll Added",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

            } else {
                toast({
                    title: "Error",
                    description: "Failed to add poll. Please try again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }

            setPoll({ Subject: "", options: ["", "", "", ""] });
            setSelectedCategory(null);
        } catch (error) {
            console.error("Poll addition error:", error);
            toast({
                title: "Error",
                description: "An unexpected error occurred. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleUserCategory = (category) => {
        setSelectedCategory(category);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...poll.options];
        updatedOptions[index] = value;
        setPoll({ ...poll, options: updatedOptions });
    };

    return (
        <Box p="4" mt="8" mx="auto" maxW="600px" borderWidth="1px" borderRadius="lg" boxShadow="lg">
            <Banner gridArea="1 / 1 / 2 / 2" banner={banner} idBuilding={idBuilding} />
            <Heading as="h2" size="lg" mb="4" textAlign="center">
                Add Poll
            </Heading>
            <form onSubmit={handlePollAdd}>
                <FormControl mb="4">
                    <FormLabel>Question:</FormLabel>
                    <Input
                        type="text"
                        value={poll.Subject}
                        onChange={(e) => setPoll({ ...poll, Subject: e.target.value })}
                    />
                </FormControl>

                <FormControl mb="4">
                    <FormLabel>Options:</FormLabel>
                    {poll.options.map((option, index) => (
                        <Input
                            key={index}
                            type="text"
                            value={option}
                            onChange={(e) => handleOptionChange(index, e.target.value)}
                            mb="2"
                        />
                    ))}
                </FormControl>

                <FormControl mb="4">
                    <FormLabel>Select Category:</FormLabel>
                    <Select onChange={(e) => handleUserCategory(categories.find((category) => category._id === e.target.value))}>
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                                {category.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                <Button type="submit" mt="4" colorScheme="blue" width="100%">
                    Add Poll
                </Button>
            </form>
        </Box>
    );
}

export default AddPoll;
