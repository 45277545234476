// Chakra imports
import {
    Grid,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    // Existing imports...
    IoPencilOutline,
    IoTrashOutline,
} from "react-icons/io5";
// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import { MdOutlinePayments } from "react-icons/md";
import { io } from "socket.io-client";

import AuthService from "../../../views/services/authService";
import InvoiceDataService from "../../../views/services/invoiceService";

export default function InvoicePaymentlightNFT({id,category, amount, name,dateSend}) {
    const currentUser = AuthService.getCurrentUser();
    const [invoices, setInvoices] = useState("");

    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        find(id, "_id")
        // Fetch invoices when the component mounts
    }, []);

    const find = (query, by) => {
        InvoiceDataService.find(query, by) // Replace 'id' with 'query'
            .then((response) => {
                setInvoices(response.data.invoices);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    /**
    const handleUpdate = () => {
        find(id, "_id")
        const x = new Date().toLocaleString() + ""
        const updatedInvoice= {
            _id: id,
            category: invoices[0].category,
            amount: invoices[0].amount,
            name: invoices[0].name,
            dateSend: invoices[0].dateSend,
            datePayed : x
        };
        InvoiceService.updateInvoice(updatedInvoice)
            .then((response) => {
                alert("Finish");
                console.log("User updated successfully:", response);
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error updating user:", error);
            });
    };**/
    useEffect(() => {
        setSocket(io("http://localhost:5000"));
    }, []);



    const handleUpdate = (type) => {
        socket.emit("sendNotification", {
            senderName: currentUser,
            receiverName: currentUser,
            type,
        });
    };

    return (
        <Card p="10px" borderRadius="20px" boxShadow="md">
            <Grid templateColumns="1fr 1fr 1fr" gap="8px">
                <Flex direction="column" alignItems="center">
                    <Text color={textColor}>{category}</Text>
                </Flex>

                <Flex direction="column" alignItems="center">
                    <Text color={textColorBid} fontSize="xl">
                        {amount}
                    </Text>

                    {/* Edit and Delete Buttons */}
                </Flex>

                <Flex direction="column" mt="10px">
                    <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="xs" // Adjust the font size to make it smaller
                        fontWeight="500"
                        borderRadius="50px" // Adjusted to be slightly smaller
                        px="16px" // Adjusted padding on the X-axis
                        py="3px" // Adjusted padding on the Y-axis
                        me="4px"
                        onClick={handleUpdate}
                    >
                        <Icon as={MdOutlinePayments} me="2px" />
                    </Button>
                </Flex>
            </Grid>
        </Card>

    );

}
