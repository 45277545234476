import instance from "./http-common";
import TokenService from "./tokenService"

class AuthService {

    signUp(data) {
        return instance.post('/signup', data)
    }

    async login(data) {
        console.log(data)
        const response = await instance.post('/login', data);
        TokenService.setUser(response.data);
        return response;
    }
    async forgotPassword(data) {
        console.log(data)
        return instance.post('/forgotPassword', data)
    }
    logout() {
        TokenService.removeUser();
    }

    getCurrentUser() {
        return TokenService.getUser()
    }
}

export default new AuthService();