// Chakra imports
import {Box, Flex, Text, Select, useColorModeValue} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
import PieChart from "UIX/components/charts/PieChart";

import {getPieChartData, getPieChartOptions} from "variables/chartsInvoice";
import {VSeparator} from "UIX/components/separator/Separator";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import InvoiceDataService from "../../../../services/invoiceService";

export default function Conversion(props) {
    const idBuilding = props.idBuilding
    console.log(idBuilding)
    const history = useHistory();
    const [invoices, setInvoices] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [searchTerm, setSearchTerm] = useState(""); // New state for the search term
    const [filteredInvoices, setFilteredInvoices] = useState([]); // New state for filtered invoices
    const [labelNames, setLabelNames] = useState([]);
    const [sumOfAmounts, setLabelSums] = useState([]);
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        // Fetch invoices when the component mounts
        fetchInvoices();
        getLabelCategoriesAndAmountSums(invoices);

    }, []);


    const getLabelCategoriesAndAmountSums = (invoices) => {
        const categoryData = {};

        invoices.forEach((invoice) => {
            const {category, amount} = invoice;

            // Convert amount to an integer if it's a string
            const parsedAmount = parseInt(amount, 10);

            if (!isNaN(parsedAmount)) {
                if (categoryData.hasOwnProperty(category)) {
                    categoryData[category] += parsedAmount;
                } else {
                    categoryData[category] = parsedAmount;
                }
            }
        });
        const labelCategories = Object.keys(categoryData);
        const sumOfAmountsPerCategory = Object.values(categoryData);
        setLabelNames(labelCategories);
        setLabelSums(sumOfAmountsPerCategory);
        setPieData(categoryData)
        console.log(categoryData)
        // Return the label names and sums as an object
        return {categoryData};
    };
    // Initialize the labelNames and sumOfAmounts states

    // Call the function when invoices state changes
    useEffect(() => {
        getLabelCategoriesAndAmountSums(invoices);
    }, [invoices]);

    //console.log(labelNames,sumOfAmounts,pieData)

    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            //  console.log(response);
            if (response.data && Array.isArray(response.data.invoices)) {
                const filteredInvoices = response.data.invoices.filter(
                    (invoice) => invoice.building === idBuilding
                );

                setInvoices(filteredInvoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };
    const {...rest} = props;

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardColor = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    return (
        <Card p='12px' align='center' direction='column' w='100%' {...rest}>
            <Flex direction={{ base: 'column', '2xl': 'row' }} justifyContent='space-between' alignItems='center' w='100%' mb='8px'>
                <Text color={textColor} fontSize='sm' fontWeight='600' mt={{ base: '2px', '2xl': '0' }}>
                    Your Pie Chart
                </Text>
                <Select fontSize='xs' variant='subtle' defaultValue='monthly' width='unset' fontWeight='600' mt={{ base: '2', '2xl': '0' }}>
                    <option value='daily'>Daily</option>
                    <option value='monthly'>Monthly</option>
                    <option value='yearly'>Yearly</option>
                </Select>
            </Flex>

            {sumOfAmounts.length > 0 ? (
                <PieChart h='100%' w='100%' chartData={sumOfAmounts} chartOptions={getPieChartOptions(labelNames)} />
            ) : (
                <div>Loading...</div>
            )}

            <VSeparator mx={{ base: '0px', xl: '40px', '2xl': '40px' }} my={{ base: '10px', '2xl': '0' }} />

            <Flex p='12px' align='center' direction={{ base: 'column', '2xl': 'row' }} w='100%'>
                {Object.keys(pieData).length > 0 ? (
                    Object.keys(pieData).map((key) => {
                        const entry = pieData[key];
                        return (
                            <Flex direction={{ base: 'column', '2xl': 'row' }} py='3px' me={{ base: '0', '2xl': '8px' }} key={key}>
                                <Flex align='center'>
                                    <Box h='6px' w='6px' bg='#6AD2FF' borderRadius='50%' me={{ base: '2px', '2xl': '0' }} />
                                    <Text fontSize='xs' color='secondaryGray.600' fontWeight='600' mb={{ base: '3px', '2xl': '0' }}>
                                        {key}
                                    </Text>
                                </Flex>
                                {/* Display the PieChart component with the data and options */}
                                <Text fontSize='sm' color={textColor} fontWeight='600' ml={{ base: '0', '2xl': '2px' }}>
                                    {entry} ₪
                                </Text>
                                <VSeparator mx={{ base: '0px', xl: '40px', '2xl': '40px' }} my={{ base: '10px', '2xl': '0' }} />
                            </Flex>
                        );
                    })
                ) : (
                    <div>Loading...</div>
                )}
            </Flex>
        </Card>

    );

}
