import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Grid,
    SimpleGrid,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from '@chakra-ui/react';

import CategoryDataService from "../../../../services/categoryService";
import Banner from "views/user/buldings/buldings/components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import CategoryClientNFT from "../../../../../UIX/components/card/CategoryClientNFT";
import InvoiceDataService from "../../../../services/invoiceService";
import AuthService from "../../../../services/authService";
import InvoiceService from "../../../../services/invoiceService";
import { MdOutlinePayments } from "react-icons/md";

let dictQuantity = [];
function ListInvoicePopup ({nameCategory, onClose}){
    const history = useHistory();
    const [complaints, setComplaints] = useState([]);
    const [invoices, setInvoices] = useState([]);
    useEffect(() => {
        // Fetch categories when the component mounts

        fetchInvoices();
    }, []);

    const find = (query, by) => {
        InvoiceDataService.find(query, by) // Replace 'id' with 'query'
            .then((response) => {
                setInvoices(response.data.invoices);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    function getMonthFromDate(dateString) {
        const dateParts = dateString.split(/[ ,/]/); // Split the string by spaces, commas, and slashes
        const monthIndex = parseInt(dateParts[1]) - 1; // Subtract 1 from the month value as it's 0-based in JavaScript Date
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (monthIndex >= 0 && monthIndex < 12) {
            return monthNames[monthIndex];
        } else {
            return "Invalid date format";
        }
    }

    const ByClient = (complaints) => {
        const currentUser = AuthService.getCurrentUser();
        let count = 0;
        if (dictQuantity.length > 0) {
            dictQuantity = [];
        }
        complaints.map((complaint, i) => {
                if (complaint.name === currentUser.fullName && complaint.category === nameCategory && complaint.datePayed === '') {
                console.log(true)
                count = count + 1;
                console.log(count)
                dictQuantity[count] = complaint;
                console.log(dictQuantity[count])
                    console.log(complaint)
            }
        });
        console.log(dictQuantity);
        setComplaints(dictQuantity);
        return dictQuantity;
    };
    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {
                setInvoices(ByClient(response.data.invoices));console.log(invoices)
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const handleAddInvoiceClick = () => {
        console.log("handleAddInvoiceClick");
        history.push("/adminTask/addInvoice"); // Redirect to the "AddInvoice" page
    };

    const handleUpdate = () => {

        const x = new Date().toLocaleString() + ""
        const updatedInvoice= {

            category: invoices[0].category,
            amount: invoices[0].amount,
            name: invoices[0].name,
            dateSend: invoices[0].dateSend,
            datePayed : x
        };
        InvoiceService.updateInvoice(updatedInvoice)
            .then((response) => {
                alert("Finish");
                console.log("User updated successfully:", response);
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error updating user:", error);
            });
    };

    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Main Fields */}
                        <Table variant="striped" colorScheme="teal">
                            <Tbody>
                                {invoices.map((category, i) => (
                                    <Tr key={i}>
                                        <Td>{getMonthFromDate(category.dateSend)}</Td>
                                        <Td isNumeric>{category.amount}</Td>
                                        <Td>
                                            {category.datePayed ? (
                                                'PAID'
                                            ) : (
                                                <Button
                                                    variant="darkBrand"
                                                    color="white"
                                                    fontSize="sm"
                                                    fontWeight="500"
                                                    borderRadius="70px"
                                                    px="24px"
                                                    py="5px"
                                                    me="4px"

                                                >
                                                    <Icon as={MdOutlinePayments} me="2px" />
                                                    Payment
                                                </Button>
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
function DashboardInvoice() {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        // Fetch categories when the component mounts
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await CategoryDataService.getAll();
            console.log(response);
            if (response.data && Array.isArray(response.data.categories)) {
                setCategories(response.data.categories);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleConfigurationClick = () => {
        console.log("handleConfigurationClick");
        history.push("/adminTask/addCategory"); // Redirect to the "Configuration" page
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{base: 1, md: 3}} gap="20px">
                            {categories.map((category, i) => (
                                <div key={i} onClick={() => handleCategoryClick(category)}>
                                    <CategoryClientNFT
                                        name={category.name}
                                        frequency={category.frequency}
                                        profilePicture={category.profilePicture}

                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>

                </Grid>
            </Box>

            {showPopup && selectedCategory && (
                <div className="category-popup">
                    {/* Your Category Popup Component */}
                    <ListInvoicePopup
                        nameCategory={selectedCategory.name}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default DashboardInvoice;
