import instance from "./http-common";
import authHeader from './auth-header';

class ComplaintDataService {
    getAll(page = 0) {
        return instance.get(`complaints?page=${page}`);
    }

    get(id) {
        return instance.get(`complaints?id=${id}`);
    }

    find(query, by, page = 0) {
        return instance.get(`complaints?${by}=${query}&page=${page}`);
    }
    postComment(data) {
        console.log(data)
        return instance.post('/complaints/postComment',data);
    }

    createComplaint(data) {
        console.log(data)

        return instance.post("/complaints", data);
    }

    deleteComplaint(complaintId) {
        return instance.delete(`/complaints?id=${complaintId}`);
    }
}

export default new ComplaintDataService();
