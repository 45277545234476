import React from "react";
import { Icon } from "@chakra-ui/react";

import {
    MdBarChart,
    MdPerson,
    MdTableRows,
    MdHandyman,
    MdMessage,
    MdOutlineReceiptLong,
    MdOutlineHowToVote,
    MdManageAccounts,
    MdSpaceDashboard,
    MdAddAlert,
} from "react-icons/md";

// Client Imports
import UserDashboard from "views/user/dashboardUser/default";
import newComplaints from "views/user/buldings/complaints/newComplaints";
import ComplaintsOverview from "views/user/buldings/complaints/overviewComplaint";
import Finance from "views/user/buldings/finances";
import Dashboard from "views/user/dashboardUser/dashboard";
import InvoicesOverview from "views/user/buldings/invoice/invoicesOverview";
import HistoryInvoice from "views/user/buldings/invoice/historyInvoice";
import DashboardInvoice from "../views/user/buldings/invoice/dashboardInvoice";
import PollsOverview from "views/user/buldings/poll/pollsOverview";
import NewMessageForm from "views/user/dashboardUser/messenger/newDiscution";
import Messenger from "views/user/dashboardUser/messenger/conversationOverview";
import Archive from "views/user/dashboardUser/messenger/archvieOverview";
import AuthService from "../views/services/authService";


const currentUser = AuthService.getCurrentUser();
console.log(currentUser);

const routesUser = [
    {
        name: "Home",
        layout: "/user",
        path: "/default/:idBuilding",
        icon: <Icon as={MdTableRows} width="20px" height="20px" color="inherit" />,
        component: UserDashboard,
    },

    {
        name: "DashBoard",
        layout: "/user",
        path: "/dashboard",
        icon: <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />,
        component: Dashboard,
    },
    {
        name: "Financials",
        layout: "/userTask",
        path: "/data-tables",
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
        component: Finance,
    },
    {
        name: "newComplaint",
        layout: "/userTask",
        path: "/newComplaints/:idBuilding",
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
        component: newComplaints,
    },
    {
        name: "Complaint",
        layout: "/userTask",
        path: "/overviewComplaint/:idBuilding",
        icon: <Icon as={MdHandyman} width="20px" height="20px" color="inherit" />,
        component: ComplaintsOverview,
    },
    {
        name: "historyInvoice",
        layout: "/userTask",
        path: "/historyInvoice",
        icon: <Icon as={MdHandyman} width="20px" height="20px" color="inherit" />,
        component: HistoryInvoice,
    },
    {
        name: "Pool",
        layout: "/userTask",
        path: "/poolDashBoard",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit" />,
        component: PollsOverview,
    },
    {
        name: "Invoice",
        layout: "/userTask",
        path: "/invoiceOverview",
        icon: <Icon as={MdOutlineReceiptLong} width="20px" height="20px" color="inherit" />,
        component: InvoicesOverview,
    },
    {
        name: "Invoice",
        layout: "/userTask",
        path: "/dashboardInvoice",
        icon: <Icon as={MdOutlineReceiptLong} width="20px" height="20px" color="inherit" />,
        component: DashboardInvoice,
    },
    {
        name: "Messenger",
        layout: "/userTask",
        path: "/Messenger",
        icon: <Icon as={MdMessage} width="20px" height="20px" color="inherit" />,
        component: Messenger,
    },
    {
        name: "Messenger",
        layout: "/userTask",
        path: "/newDiscution",
        component: NewMessageForm,
    },
    {
        name: "Archive",
        layout: "/userTask",
        path: "/archiveMessenger",
        component: Archive,
    },
    /* {
      name: "Buildings",
      layout: "/user",
      path: "/buildingsOverview",
      icon: <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />,
      component: BuildingsOverview,
    },
    // Uncomment the following section based on your requirements
    // currentUser && currentUser.isManager === "true" &&
    {
      name: "manager Invoice",
      layout: "/user",
      path: "/managerDashboardInvoice",
      icon: <Icon as={MdOutlineReceiptLong} width="20px" height="20px" color="inherit" />,
      component: ManagerDashboardInvoice,
    },
    */
];

export default routesUser;
