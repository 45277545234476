// Chakra imports
import React, {useEffect, useState} from "react";
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    Avatar,
    useColorModeValue,
} from "@chakra-ui/react";
import {MdOutlineAccountBalance} from "react-icons/md";
import IconBox from "UIX/components/icons/IconBox";
import UserDataService from "../../../services/userService";

import Menu from "UIX/components/menu/ProfileManagerMenu";
import Card from "UIX/components/card/Card.js";

export default function Banner(props) {
    const [user, setUser] = useState({});
    const getCurrentUser = () => {
        UserDataService.getUserById(props.idUser)
            .then((response) => {
                if (response) {
                    setUser(response.data)
                } else {
                    alert("User not found");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getCurrentUser();
    },);

    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    const borderColor = useColorModeValue(
        "white !important",
        "#111C44 !important"
    );
    return (
        <Card mb={{base: "0px", lg: "20px"}} align="center">
            <Flex w="100%" justify="left" py={4}>
                <Text fontSize="xl" fontWeight="bold">
                    Your Profile
                </Text>
                <Menu ms='auto'/>
            </Flex>
            <Avatar
                mx='auto'
                src={user.profilePicture}
                h='87px'
                w='87px'
                mt='-43px'
                border='4px solid'
                borderColor={borderColor}
            />
            <Text color={textColorSecondary} fontWeight="bold" fontSize="xl" mt="10px">
                {user.fullName || "User"}
            </Text>
            <Text color={textColorSecondary} fontSize="md" maxW={{base: "100%", xl: "80%", "3xl": "60%"}} mx="auto">
                {user.username ? `Username: ${user.username}` : 'Username: N/A'}
            </Text>
            <Text color={textColorSecondary} fontSize="md" maxW={{base: "100%", xl: "80%", "3xl": "60%"}} mx="auto">
                {user.mail ? `Email: ${user.mail}` : 'Email: N/A'}
            </Text>
            <Box w="100%" mt="auto">
                <Flex w="100%" justify="space-between" mb="10px">
                    <Text color={textColorSecondary} fontSize="sm" maxW="40%">
                        {user.idNumber ? `ID: ${user.idNumber}` : 'ID: N/A'}
                    </Text>
                    <Text color={textColorSecondary} fontSize="sm" maxW="40%">
                        {user.role ? `Role: ${user.role}` : 'Role: N/A'}
                    </Text>
                </Flex>
            </Box>
        </Card>
    );
}