import instance from "./http-common";
import AuthService from "../../views/services/authService";

class MessageDataService {
    getAllMessages(page = 0) {
        return instance.get(`message?page=${page}`);
    }

    getMessageByConversationID(conversationID) {
        return instance.get(`message/${conversationID}`);
    }

    findMessages(query, by, page = 0) {
        return instance.get(`message?${by}=${query}&page=${page}`);
    }

    addMessage(currentConversation,initialNewMessage) {
        const currentUser = AuthService.getCurrentUser()
        const data = {currentConversation:currentConversation,currentUser,initialNewMessage:initialNewMessage}
        return instance.post('/addMessage',data);
    }
    addMessageWithNotifcation(MessageDoc,currentConversation,initialNewMessage) {
      //  console.log(MessageDoc,currentConversation,initialNewMessage)
        const currentUser = AuthService.getCurrentUser()
        const data = {MessageDoc,currentConversation:currentConversation,currentUser,initialNewMessage:initialNewMessage}
        return instance.post('/addMessageWithNotifcation',data);
    }
    addConversation(data) {
        console.log(data)
        return instance.post('/addConversation',data);
    }
    updateMessage(data) {
        return instance.put('/message/update', data);
    }

    deleteMessage(conversationID) {
        return instance.delete(`/message/${conversationID}`);
    }

    getMessagesByConversationID(conversationID, page = 0) {
        return instance.get(`message/${conversationID}?page=${page}`);
    }
}

export default new MessageDataService();
