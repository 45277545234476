// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    Avatar, Divider, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
import Menu from "UIX/components/menu/MessengerUserMenu";
import React, {useEffect, useRef, useState} from "react";
// Assets
import moment from "moment";
import {MdMessage} from "react-icons/md";
import AuthService from "../../../../services/authService";
import UserDataService from "../../../../services/userService";
import MessageDataService from "../../../../services/messageService";
function ConversationPopup({userConversation, Conversation, onClose, profilPicture}) {
    const [initialNewMessage, setInitialNewMessage] = useState('');
    const currentUser = AuthService.getCurrentUser();
    console.log(currentUser)
    const [conversationsUserChoice, setConversationsUserChoice] = useState([]);
    const [currentConversation, setcurrentConversation] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [profilePictures, setProfilePictures] = useState({});
    const chatContainerRef = useRef(null);
    useEffect(() => {
        const conversationsUser = [];
        Conversation.forEach(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
            let userConversationId = userConversation.id || userConversation._id;
            if (
                (fromUserId === currentUser._id && toUserId === userConversationId) ||
                (fromUserId === userConversationId && toUserId === currentUser._id)
            ) {
                setcurrentConversation(message)
                const otherUserId = fromUserId === currentUser._id ? toUserId : fromUserId;

                const existingConversationIndex = conversationsUser.findIndex(conv =>
                    conv.users.includes(currentUser._id) && conv.users.includes(otherUserId)
                );

                if (existingConversationIndex !== -1) {
                    conversationsUser[existingConversationIndex].messages.push(message);
                } else {
                    conversationsUser.push({
                        users: [currentUser._id, otherUserId],
                        messages: [message]
                    });
                }
            }
        });

        if (conversationsUser.length > 0) {
            console.log(conversationsUser[0].messages[0].conversation)
            setConversationsUserChoice(conversationsUser[0].messages[0].conversation);
        }

        setLoading(false); // Set loading to false when data is fetched
    }, [userConversation, Conversation, currentUser]);

    const handleNewMessageChange = (event) => {
        setInitialNewMessage(event.target.value);
    };
// Function to fetch user data including dashboard picture
    const fetchUserData = async (userName) => {
        try {
            const userData = await UserDataService.getUserByName(userName);
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    };

// Function to fetch dashboard picture URL for a user
    const fetchProfilePictureUrl = async (userName) => {
        try {
            const userData = await fetchUserData(userName);
            if (userData && userData.data.profilePicture) {
                return userData.data.profilePicture;
            } else {
                console.log('User data or dashboard picture missing for:', userName);
                return null;
            }
        } catch (error) {
            console.error('Error fetching dashboard picture:', error);
            return null;
        }
    };

// Inside your component
    useEffect(() => {
        const fetchProfilePictures = async () => {
            const pictureData = {};
            for (const message of conversationsUserChoice) {
                try {
                    const profilePictureUrl = await fetchProfilePictureUrl(message.User);
                    pictureData[message.User] = profilePictureUrl;
                } catch (error) {
                    console.error('Error fetching dashboard picture:', error);
                    pictureData[message.User] = null;
                }
            }
            setProfilePictures(pictureData);
        };

        fetchProfilePictures();
    }, [conversationsUserChoice]);

    const handleSendMessage = async () => {
        setLoading(true);

        const newMessageData = {
            User: currentUser.username,
            text: initialNewMessage, // Update this based on your data structure
            date: new Date().toISOString(), // You can format the date as needed
            // Other necessary properties
        };
        conversationsUserChoice.push(newMessageData);
        const MessageDoc = {
            fromUser: currentConversation.fromUser,
            toUser: currentConversation.toUser,
        }
        try {
            await MessageDataService.addMessageWithNotifcation(MessageDoc, currentConversation._id, initialNewMessage);
            //   window.location.reload();
            setInitialNewMessage(''); // Clear the input field
        } catch (error) {
            console.error('Error sending message:', error);
        }

        setLoading(false);
    };
    const formatTime = (dateTimeString) => {
        const options = {hour: '2-digit', minute: '2-digit'};
        return new Date(dateTimeString).toLocaleTimeString(undefined, options);
    };


    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody>
                        <div className="chat-container" ref={chatContainerRef}>
                            {/* Loading state */}
                            {loading ? (
                                <div className="loading-text">Loading...</div>
                            ) : (
                                <div className="message-list">
                                    {conversationsUserChoice.map((message, i) => (
                                        <div
                                            key={i}
                                            className={`message-row ${
                                                message.User === currentUser.username ? 'current-user' : 'other-user'
                                            }`}
                                        >
                                            {
                                                (message.User !== currentUser.username && profilePictures[message.User]) ? (
                                                    <div>
                                                        <div className="profile-picture-container before-message">
                                                            <img className="conversation-photo"
                                                                 src={profilePictures[message.User]} alt="conversation"/>

                                                        </div>
                                                        <Flex direction="column">
                                                            <Text
                                                                color="secondaryGray.600"
                                                                fontSize={{
                                                                    base: "sm",
                                                                }}
                                                                fontWeight="400"
                                                                mt="5px"
                                                            >
                                                                {formatTime(message.date)}
                                                            </Text>

                                                        </Flex>
                                                    </div>

                                                ) : (
                                                    (message.User !== currentUser.username && !profilePictures[message.User]) ? (
                                                        <div className="profile-picture-placeholder">Loading profile
                                                            picture...</div>
                                                    ) : null
                                                )
                                            }
                                            <div className="message-bubble">
                                                <div className="message-content">
                                                    {message.text}
                                                </div>
                                            </div>
                                            {message.User === currentUser.username && (
                                                <div>
                                                    <div className="profile-picture-container after-message">
                                                        {profilePictures[message.User] !== null ? (
                                                            <img className="conversation-photo"
                                                                 src={profilePictures[message.User]} alt="conversation"/>
                                                        ) : (
                                                            <div className="profile-picture-placeholder">Loading profile
                                                                picture...</div>
                                                        )}
                                                    </div>

                                                    <Flex direction="column">
                                                        <Text
                                                            color="secondaryGray.600"
                                                            fontSize={{
                                                                base: "sm",
                                                            }}
                                                            fontWeight="400"
                                                            mt="5px"
                                                        >
                                                            {formatTime(message.date)}
                                                        </Text>

                                                    </Flex>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                            )}

                            <div className="message-input">
                                <input
                                    type="text"
                                    value={initialNewMessage}
                                    onChange={handleNewMessageChange}
                                    placeholder="Type your message..."
                                />
                                <button className="send-button" onClick={handleSendMessage}>
                                    Send
                                </button>
                            </div>
                        </div>
                        <style>
                            {`
                            .message-row {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-end;
                                margin-bottom: 10px;
                            }

                            .message-row.current-user {
                                justify-content: flex-end;
                            }

                            .message-timestamp {
                                position: absolute;
                                top: -25px;
                                left: 50%;
                                transform: translateX(-50%);
                                background-color: rgba(255, 255, 255, 0.8);
                                padding: 2px 8px;
                                border-radius: 10px;
                                font-size: 12px;
                                white-space: nowrap;
                            }

                            .small-italic-text {
                                font-style: italic;
                                font-size: 12px;
                            }

                            .message-bubble {
                                display: inline-block;
                                padding: 8px 12px;
                                border-radius: 15px;
                                margin: 5px;
                                max-width: 70%;
                            }

                            .current-user .message-bubble {
                                background-color: #007bff;
                                color: #fff;
                                align-self: flex-end;
                            }

                            .other-user .message-bubble {
                                background-color: #f0f0f0;
                                color: #333;
                                align-self: flex-start;
                            }

                            .message-input {
                                display: flex;
                                align-items: center;
                                padding: 10px;
                                border-top: 1px solid #ccc;
                            }

                            .message-input input {
                                flex: 1;
                                padding: 8px;
                                border: none;
                                border-radius: 20px;
                                margin-right: 10px;
                                font-size: 14px;
                            }

                            .message-input button.send-button {
                                background-color: #007bff;
                                color: #fff;
                                border: none;
                                border-radius: 20px;
                                padding: 6px 15px;
                                cursor: pointer;
                                font-size: 14px;
                            }
                            `}
                        </style>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};


export default function Banner(props) {

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const filterMessagesWithCurrentUser = (conversations, currentUser) => {
        return conversations.filter(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
            console.log(message, fromUserId, toUserId)
            return fromUserId === currentUser._id || toUserId === currentUser._id;
        });
    };
    const getUsersInvolvedInConversations = (messagesWithCurrentUser, currentUser) => {
        const usersInvolvedInConversations = new Set();
        messagesWithCurrentUser.forEach(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;

            if (fromUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.fromUser);
            }

            if (toUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.toUser);
            }
        });

        return usersInvolvedInConversations;
    };
    const fetchData = async () => {
        try {
            const [userData, messageData] = await Promise.all([
                UserDataService.getAll(),
                MessageDataService.getAllMessages()
            ]);
            console.log(userData, messageData)
            const users = userData.data?.users || [];
            const conversations = messageData.data?.messages || [];
            const currentUser = AuthService.getCurrentUser(); // Replace with the actual code to get the current user
            console.log(currentUser._id)
            const messagesWithCurrentUser = filterMessagesWithCurrentUser(conversations, currentUser);
            const usersInvolvedInConversations = getUsersInvolvedInConversations(messagesWithCurrentUser, currentUser);
            console.log(messagesWithCurrentUser, usersInvolvedInConversations)
            const usersInvolvedArray = Array.from(usersInvolvedInConversations);
            setFilteredUsers(usersInvolvedArray);
            setFilteredMessages(messagesWithCurrentUser);
            console.log(filteredUsers, filteredMessages)
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleConversationClick = (user) => {
        setSelectedConversation(user);
    };

    return (
        <Card mb={{base: "0px", lg: "20px"}} align="center">
            <Flex w="100%" justify="left" py={4}>
                <Text fontSize="xl" fontWeight="bold">
                    Messenger
                </Text>
                {/* Add your Menu component */}
            </Flex>

                {filteredUsers.map((user, index) => (
                    <div
                        className="conversation-list-item"
                        key={user.id} // Assuming user.id is the unique identifier for each user
                        onClick={() => handleConversationClick(user)}
                    >
                        <Flex align="center" mb={3}>
                            <Avatar size="sm" src={user.profilePicture} name={user.username} mr={4} />
                            <Box flex="1">
                                <Flex align="center">
                                    <Text
                                        fontWeight="bold"
                                        fontSize="md" // Adjust the font size as needed
                                        fontFamily="body" // Use a font family that suits your design
                                        color="teal.500" // Adjust the color as needed
                                    >
                                        {user.username}
                                    </Text>
                                    {user.latestMessage && (
                                        <Text fontSize="sm" color="gray.500" ml={2}>
                                            {user.latestMessage.text.length > 30
                                                ? `${user.latestMessage.text.substring(0, 30)}...`
                                                : user.latestMessage.text}
                                        </Text>
                                    )}
                                </Flex>
                                {user.latestMessage && (
                                    <Text fontSize="sm" color="gray.500">
                                        {/* Add your date formatting logic */}
                                    </Text>
                                )}
                            </Box>
                        </Flex>
                        {index < filteredUsers.length - 1 && <Divider />}
                    </div>
                ))}

            {selectedConversation && (
                <div className="conversation-popup">
                    {/* Render ConversationPopup component with selectedConversation */}
                    <ConversationPopup
                        userConversation={selectedConversation}
                        Conversation={filteredMessages}
                        onClose={() => setSelectedConversation(null)}
                        profilPicture={selectedConversation.profilePicture}
                    />
                </div>
            )}

        </Card>
    );
}
