// Chakra imports

// Custom components
import Profil from "views/manager/dashboardManager/account/account";
import BuldingManyManager from "views/manager/dashboardManager/dashboard/components/BuldingManyManager";
import BuildingManager from "views/manager/dashboardManager/dashboard/components/BuldingManager";
import MessengerManager from "views/manager/dashboardManager/dashboard/components/MessengerManager";

// Assets
import avatar from "UIX/assets/img/avatars/avatar4.jpeg";
import React, {useEffect, useState} from "react";
import AuthService from "../../../services/authService";
import BuildingDataService from "../../../services/buildingService";
import UserDataService from "../../../services/userService";
import MessageDataService from "../../../services/messageService";
import Card from "UIX/components/card/Card.js";
import {Box, Grid, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text} from "@chakra-ui/react";
import {MdMenu, MdAccountBalance, MdEmail, MdMessage, MdClose, MdAccountBox} from "react-icons/md";
export default function Overview() {
    const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
    const [isBuildingManagerModalOpen, setIsBuildingManagerModalOpen] = useState(false);
    const [isBuldingManyManagerModalOpen, setIsBuldingManyManagerModalOpen] = useState(false);
    const [isMessengerUserModalOpen, setIsMessengerUserModalOpen] = useState(false);

    const [buildings, setBuildings] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const filterMessagesWithCurrentUser = (conversations, currentUser) => {
        return conversations.filter(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;
            //  console.log(message,fromUserId,toUserId)
            return fromUserId === currentUser._id || toUserId === currentUser._id;
        });
    };
    const getUsersInvolvedInConversations = (messagesWithCurrentUser, currentUser) => {
        const usersInvolvedInConversations = new Set();
        messagesWithCurrentUser.forEach(message => {
            let fromUserId = message.fromUser.id || message.fromUser._id;
            let toUserId = message.toUser.id || message.toUser._id;

            if (fromUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.fromUser);
            }

            if (toUserId !== currentUser._id) {
                usersInvolvedInConversations.add(message.toUser);
            }
        });

        return usersInvolvedInConversations;
    };
    const fetchData = async () => {
        try {
            const [userData, messageData] = await Promise.all([
                UserDataService.getAll(),
                MessageDataService.getAllMessages()
            ]);

            const users = userData.data?.users || [];
            const conversations = messageData.data?.messages || [];
            const currentUser = AuthService.getCurrentUser(); // Replace with the actual code to get the current user
            console.log(currentUser._id)
            const messagesWithCurrentUser = filterMessagesWithCurrentUser(conversations, currentUser);
            const usersInvolvedInConversations = getUsersInvolvedInConversations(messagesWithCurrentUser, currentUser);
            console.log(messagesWithCurrentUser, usersInvolvedInConversations)
            const usersInvolvedArray = Array.from(usersInvolvedInConversations);
            setFilteredUsers(usersInvolvedArray);
            setFilteredMessages(messagesWithCurrentUser);
            console.log(filteredUsers, filteredMessages)
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        // Fetch buildings when the component mounts
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        console.log(currentUser);

        try {
            const response = await BuildingDataService.getAll();
            if (response.data && Array.isArray(response.data.buildingsList)) {
                console.log(currentUser);
                const filteredBuildings = response.data.buildingsList.filter(building => {
                    // Assuming building.apartments is an array of apartment objects
                    const hasCurrentUserEmail = building => building.manager.mail === currentUser.mail;
                    return hasCurrentUserEmail;
                });
                setBuildings(filteredBuildings);
            }
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };


    const renderCard = (title, icon, onClick, isOpen) => (
        <Card
            minH="200px"
            p="4"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={onClick}
            cursor="pointer"
            border="2px solid #4A5568"
            bg="white"

        >
            {React.cloneElement(icon, { color: "blue", boxSize: 8 })} {/* Set the color to blue */}
            <Text mt="2" fontWeight="bold">
                {title}
            </Text>
            <Modal isOpen={isOpen} onClose={() => onClick(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Icon
                            as={MdClose}
                            onClick={() => {
                                console.log("Close icon clicked");
                                onClick(false);
                            }}
                            position="absolute"
                            top="0"
                            right="0"
                            boxSize={6}
                            cursor="pointer"
                        />
                        {title === "Profile" && (
                            <Profil
                                idUser={currentUser._id}
                                idNumber={currentUser.idNumber}
                                avatar={ currentUser.profilePicture}
                                username={currentUser.username}
                                fullName={currentUser.fullName}
                                role={currentUser.role}
                                mail={currentUser.mail}
                            />
                        )}
                        {title === "Building" && buildings.length === 1 && (
                            <BuildingManager
                                apartments={buildings[0].apartments}
                                address={buildings[0].address}
                                enter={buildings[0].enter}
                                numberOfApartment={buildings[0].numberOfApartment}
                                manager={buildings[0].manager}
                            />
                        )}
                        {title === "Building" && buildings.length > 1 && (
                            <BuldingManyManager buildings={buildings} />
                        )}
                        {title === "Messenger" && (
                            <MessengerManager
                                gridArea={{
                                    base: "3 / 1 / 4 / 2",
                                    lg: "1 / 3 / 2 / 4",
                                }}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Card>
    );


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                    lg: "1.34fr 1fr 1.62fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
            >
                {renderCard("Profile", <MdAccountBox size={40} />, setIsBannerModalOpen, isBannerModalOpen)}
                {renderCard("Building", <MdAccountBalance size={40} />, setIsBuildingManagerModalOpen, isBuildingManagerModalOpen)}
                {renderCard("Messenger", <MdMessage size={40} />, setIsMessengerUserModalOpen, isMessengerUserModalOpen)}
            </Grid>

            <Grid mb="20px" templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)", "2xl": "1.34fr 1.62fr 1fr" }} templateRows={{ base: "1fr", lg: "repeat(2, 1fr)", "2xl": "1fr" }} gap={{ base: "20px", xl: "20px" }}></Grid>
        </Box>
    );
}
