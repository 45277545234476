

import React from "react";

// Chakra imports
import {
  Box,
  Grid,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/finances/components/Banner";


// Assets

import AuthService from "../../services/authService";
import banner from "../../../UIX/assets/img/auth/banner.png";
import avatar from "../../../UIX/assets/img/avatars/avatar4.jpeg";
import TotalSpent from "../default/components/TotalSpent";
import PieCard from "../default/components/PieCard";



import Interventions from "../profile/components/Interventions";

export default function Finance() {

  // Chakra Color Mode
    const currentUser = AuthService.getCurrentUser();
    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                <Banner
                    gridArea='1 / 1 / 2 / 2'
                    banner={banner}
                    avatar={avatar}
                    name={currentUser.username}
                    job={currentUser.role}
                    posts='17'
                    followers='9.7k'
                    following='274'
                />
            </Grid>

            <Box mt={3} mb={3}>

                <SimpleGrid columns={{md: 2, xl: 2}} mt={3} mb={3} columnGap={4}>
                    <TotalSpent/>
                    <PieCard/>
                </SimpleGrid>

            </Box>
        </Box>

    );
}
