import http from "./http-common";
import instance from "./http-common";

class CategoryDataService {
    getAll(page = 0) {
        return http.get(`categories?page=${page}`);
    }

    get(id) {
        return http.get(`categories?id=${id}`);
    }

    getNames(id) {
        return instance.get(`/categoryNames`);
    }

    find(query, by = "name") {
        return http.get(`categories?${by}=${query}`);
    }

    postCategory(data) {
        return http.post("/categories", data);
    }

    deleteCategory(id) {
        return http.delete(`/categories?id=${id}`);
    }

    deleteAllCategories() {
        return http.delete('/deleteAllCategories');
    }

    getCategoriesByClientId(ClientId) {
        return http.get(`/getCoursesDetails?ClientId=${ClientId}`);
    }
}

export default new CategoryDataService();
