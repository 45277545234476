import AuthService from "../../../../services/authService";
import UserDataService from "../../../../services/userService";
import React, { useEffect, useState } from "react";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    Radio,
    RadioGroup,
    Checkbox,
    useColorModeValue,
    Select,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import DefaultAuth from "layouts/auth/Default";
import Banner from "../../components/Banner";
import BuildingDataService from "../../../../services/buildingService";

function EditAccount(props) {
    const {idUser} = useParams();
    console.log(idUser)
    const toast = useToast();
    const [user, setUser] = useState({});
    const [profilePicture, setProfilePicture] = useState(null);
    const [picLoading, setPicLoading] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [isManager, setIsManager] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPictureUploaded, setIsPictureUploaded] = useState(false);
    const [selectBuilding, setSelectBuilding] = useState(null);
    const history = useHistory();
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const retrieveBuilding = async () => {
        try {
            const response = await BuildingDataService.getAll();
            if (
                response.data &&
                Array.isArray(response.data.buildingsList)
            ) {
                setBuildings(response.data.buildingsList);
            }
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    useEffect(() => {
        retrieveBuilding();
        setUser({
            user_id: props.idNumber || "",
            fullName: props.fullName || "",
            mail: props.mail || "",
            username: props.username || "",
            isAdmin: props.role === "admin",
            isManager: props.role === "manager",
            building: props.building || "",
            // Add other fields as needed
        });
    }, [props]); // Make sure to re-fetch when props change

    const postDetails = (pics) => {
        setPicLoading(true);

        if (!pics) {
            // Handle case when no image is selected
            // ...

            setPicLoading(false);
            return;
        }

        // Cloudinary upload logic
        // ...

        // Assuming you set the profile picture URL in setProfilePicture
        // and handle the loading state and error cases
    };

    const handleUpdate = async () => {
        try {
            // Assuming you have an update method in UserDataService
            const response = await UserDataService.updateUser({
                user_id: user.user_id,
                fullName: user.fullName,
                mail: user.mail,
                username: user.username,
                profilePicture: profilePicture,
                isAdmin: isAdmin,
                building: selectBuilding,
                // Add other fields as needed
            });

            if (response.data.status === "success") {
                toast({
                    title: "Account updated successfully!",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom",
                });
            } else {
                alert("Update failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Update error:", error);
            alert(
                "An error occurred during the update. Please try again later."
            );
        }
    };

    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Box mb="70px">
                    <Banner gridArea="1 / 1 / 2 / 2"  idUser={idUser} />
                </Box>
                <Flex justify="center" align="center" height="100vh">
                    <Box
                        width="400px"
                        p="4"
                        borderWidth="1px"
                        borderRadius="lg"
                        boxShadow="lg"
                    >
                        <Heading as="h2" size="lg" mb="4">
                            Edit Account
                        </Heading>
                        <form>
                            <FormControl mt="4">
                                <FormLabel>Full Name</FormLabel>
                                <Input
                                    value={user.fullName}
                                    onChange={(e) =>
                                        setUser({ ...user, fullName: e.target.value })
                                    }
                                />
                            </FormControl>
                            {/* Add other form controls for each field you want to edit */}
                            <FormControl mt="4">
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    placeholder="Enter email"
                                    value={user.mail}
                                    onChange={(e) => setUser({ ...user, mail: e.target.value })}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Username</FormLabel>
                                <Input
                                    placeholder="Enter username"
                                    value={user.username}
                                    onChange={(e) =>
                                        setUser({ ...user, username: e.target.value })
                                    }
                                />
                            </FormControl>
                            {/* Add other form controls for each field you want to edit */}
                            <FormControl mt="4">
                                <FormLabel>Profile Picture</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    display="none"
                                    id="file-input"
                                    onChange={(e) => postDetails(e.target.files[0])}
                                />
                                <label htmlFor="file-input">
                                    <Button
                                        as="span"
                                        bg="blue.500"
                                        color="white"
                                        borderRadius="md"
                                        _hover={{ bg: "blue.600" }}
                                        cursor="pointer"
                                    >
                                        Select File
                                    </Button>
                                </label>
                                {isPictureUploaded && (
                                    <Checkbox mt="2" isChecked={true} isDisabled>
                                        Picture uploaded
                                    </Checkbox>
                                )}
                            </FormControl>
                            {/* ... (other form controls) */}
                            <FormControl mt="4">
                                <Button
                                    variant="brand"
                                    w="100%"
                                    size="lg"
                                    type="button"
                                    onClick={handleUpdate}
                                >
                                    Update
                                </Button>
                            </FormControl>
                            {/* ... (rest of the code remains the same) */}
                        </form>
                    </Box>
                </Flex>
            </Box>
        </div>
    );
}

export default EditAccount;
