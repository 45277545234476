import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    FormControl,
    FormLabel,
    Heading,
    Input,
    Button,
    Flex,
    Select,
    Box,
    Text,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { useToast } from "@chakra-ui/toast";
import AuthService from "../../../services/authService";
import UserDataService from "../../../services/userService";
import InvoiceDataService from "../../../services/invoiceService";
import CategoryDataService from "../../../services/categoryService";
import Banner from "../components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";

function AddInvoice() {
    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentUserManager, setCurrentUserManager] = useState(null);

    const initialInvoice = {
        category: "",
        amount: "",
        user: "",
        dateSend: "",
        datePayed: "",
        building: currentUserManager ? currentUserManager.data.building._id : "",
    };
    const [invoice, setInvoice] = useState(initialInvoice);
    const toast = useToast();

    useEffect(() => {
        retrieveUsers();
        retrieveCategories();
    }, []);

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(async (response) => {
                const fetchedUsers = response.data.users;
                const currentUserManager = await UserDataService.getUserByName(
                    currentUser.username
                );
                const updatedInvoice = {
                    ...invoice,
                    building: currentUserManager.data.building,
                };
                setInvoice(updatedInvoice);
                const usersWithSameBuilding = fetchedUsers.filter((user) => {
                    return (
                        user.building !== null &&
                        user.building._id == currentUserManager.data.building._id
                    );
                });
                setUsers(usersWithSameBuilding);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveCategories = () => {
        CategoryDataService.getAll()
            .then((response) => {
                setCategories(response.data.categories);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setInvoice({ ...invoice, category: category.name });
    };

    const handleInvoiceAdd = async (event) => {
        event.preventDefault();
        if (!invoice.category || !invoice.amount || !invoice.user) {
            toast({
                title: "Please fill in all fields!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
        }

        try {
            const userDoc = {
                ...invoice,
            };
            const response = await InvoiceDataService.postInvoice(userDoc);
            if (response.data.status === "success") {
                history.goBack();
            } else {
                alert("ERROR");
            }
        } catch (error) {
            console.error("Invoice addition error:", error);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setInvoice({ ...invoice, user });
    };

    return (
        <Box mt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Banner gridArea="1 / 1 / 2 / 2" banner={banner} />
            <Flex justify="center" align="center" height="100vh">
                <Box
                    width="400px"
                    p="4"
                    borderWidth="1px"
                    borderRadius="lg"
                    boxShadow="lg"
                    mx="auto"
                >
                    <Heading as="h2" size="lg" mb="4" textAlign="center">
                        Add Invoice
                    </Heading>
                    <form onSubmit={handleInvoiceAdd}>
                        <FormControl mt="4">
                            <FormLabel>Select Category:</FormLabel>
                            <Select
                                id="categorySelect"
                                value={selectedCategory ? selectedCategory._id : ""}
                                onChange={(e) =>
                                    handleCategorySelect(
                                        categories.find((category) => category._id === e.target.value)
                                    )
                                }
                            >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Select>
                            {selectedCategory && (
                                <Text mt="2" color="gray.500">
                                    Selected Category: {selectedCategory.name}
                                </Text>
                            )}
                        </FormControl>

                        <FormControl mt="4">
                            <FormLabel>Amount:</FormLabel>
                            <Input
                                type="number"
                                id="amountInput"
                                value={invoice.amount}
                                onChange={(e) =>
                                    setInvoice({ ...invoice, amount: e.target.value })
                                }
                            />
                        </FormControl>

                        <FormControl mt="4">
                            <FormLabel>Select User:</FormLabel>
                            <Select
                                id="userSelect"
                                value={selectedUser ? selectedUser._id : ""}
                                onChange={(e) =>
                                    handleUserSelect(
                                        users.find((user) => user._id === e.target.value)
                                    )
                                }
                            >
                                <option value="">Select User</option>
                                {users.map((user) => (
                                    <option key={user._id} value={user._id}>
                                        {user.username}
                                    </option>
                                ))}
                            </Select>
                            {selectedUser && (
                                <Text mt="2" color="gray.500">
                                    Selected User: {selectedUser.username}
                                </Text>
                            )}
                        </FormControl>

                        <Button type="submit" mt="4" colorScheme="blue">
                            Add Invoice
                        </Button>
                    </form>
                </Box>
            </Flex>
        </Box>
    );
}

export default AddInvoice;
