
import React, {useState, useEffect} from "react";
import ComplaintDataService from "../../../../services/complaintService";
import AuthService from "../../../../services/authService";
import ComplaintNFT from "UIX/components/card/ComplaintNFT";
import Banner from "../../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import { MdOutlineInsertComment,} from "react-icons/md";
import { GoCommentDiscussion } from "react-icons/go";

import {
    Stack,
    TableCaption,
    Box,
    Button,
    Image,
    Flex,
    Grid,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Table, Thead, Tr, Th, Tbody, Td, Text, Input, Textarea,
} from "@chakra-ui/react";
import {useHistory, useParams} from "react-router-dom";
function SearchBar({searchTerm, idBuilding,onChange, onClick}) {
    console.log(idBuilding)
    const history = useHistory();
    const handleCreateCatagory = () => {
        console.log(1)
        // Push a new entry onto the history stack and redirect to the desired location
        history.push(`/managerTask/newComplaints/${idBuilding}`);

    };
    const handleHistoryInvoice = () => {
        history.push(`/managerTask/historyPool/${idBuilding}`);
    };
    return (
        <Flex
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
            bg="gray.200"
            p="10px"
            borderRadius="8px"
        >
            <Input
                variant="filled"
                fontSize="sm"
                bg="white"
                color="gray.800"
                fontWeight="500"
                _placeholder={{color: "gray.400", fontSize: "14px"}}
                borderRadius="4px"
                placeholder="Search by Category, Date, or Month..."
                value={searchTerm}
                onChange={onChange}
                mr="2"
            />
            <Button colorScheme="teal" variant="solid" size="sm" onClick={onClick}>
                Search
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleCreateCatagory}>
                New
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleHistoryInvoice}>
                Hystory
            </Button>
        </Flex>
    );
}

function ComplaintPopup({ title, description, profilePicture, client, currentDate, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Image
                        src={profilePicture}
                        w={{base: "100%", "3xl": "100%"}}
                        h={{base: "100%", "3xl": "100%"}}
                        borderRadius="20px"
                    />
                    <p><strong>Client:</strong> {client}</p>
                    <p><strong>Date:</strong> {currentDate}</p>
                    <p>{description}</p>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}


function CommentComplaintPopup({ selectedComplaint, onClose }) {
    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Main Fields */}
                        {selectedComplaint.comments && selectedComplaint.comments.length > 0 ? (
                            <Table variant="striped" colorScheme="teal">
                                <Thead>
                                    <Tr>
                                        <Th>Username</Th>
                                        <Th>Comment</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedComplaint.comments.map((comment, i) => (
                                        <Tr key={i}>
                                            <Td>{comment.username}</Td>
                                            <Td>{comment.text}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Box textAlign="center">
                                <Text>No comments for this complaint.</Text>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}


function PostComplaintPopup({complaintId, onClose} ) {
    const user = AuthService.getCurrentUser()
    // State to store the form inputs
    const [username, setUsername] = useState(user.username);
    const [commentText, setCommentText] = useState('');
    const userDoc = {
        username,
        complaintId,
        commentText
    };
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the postComment function with the form inputs
        ComplaintDataService.postComment(userDoc);
        // Reset form inputs
        setUsername('');
        setCommentText('');
        // Close the popup
    };
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Post a Comment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Username:
                            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        </label>

                        <label>
                            Comment:
                            <Textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} required />
                        </label>
                        <Button type="submit">Submit</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function ComplaintsOverview(props) {
    const {idBuilding} = useParams();
    console.log(idBuilding)
    const [refreshKey, setRefreshKey] = useState(0);
    const [complaints, setComplaints] = useState([]);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [showPostComplaintPopup, setShowPostComplaintPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);

    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSearchClick = () => {
        const result = complaints.filter(
            (complaint) =>
                complaint.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                complaint.dateSend.toLowerCase().includes(searchTerm.toLowerCase()) ||
                complaint.datePayed.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setComplaints(result);
    };
    useEffect(() => {
        fetchComplaints();
    }, [refreshKey]);

    const fetchComplaints = async () => {
        try {
            const response = await ComplaintDataService.getAll();
            if (response.data && Array.isArray(response.data.complaints)) {

                const filteredComplaints = response.data.complaints.filter(
                    (complaint) => complaint.idBuilding === idBuilding
                );
                setComplaints(filteredComplaints);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };

     const handleClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowComplaintPopup(true); // Open the complaints popup
    };
    const handleCommentButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowCommentPopup(true); // Open the complaints popup
    };

    const handlePostComplaintButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showComplaintPopup) {
            setShowComplaintPopup(false); // Close the complaints popup if open
        }
        setShowPostComplaintPopup(true); // Open the post complaints popup
    };


    const handleClosePopup = () => {
        setShowComplaintPopup(false);
        setShowPostComplaintPopup(false);
        setShowCommentPopup(false);

    };

    // Function to handle the button click and show the PostComplaintPopup




    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
            >
                <Flex
                    flexDirection="column"
                    gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                >


                    <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding}/>
                    <SearchBar searchTerm={searchTerm}  idBuilding={idBuilding} onChange={handleSearchChange} onClick={handleSearchClick}
                    />
                    {complaints.length > 0 ? (
                        <Table variant="simple" colorScheme="green" size="sm">
                            <TableCaption fontWeight="bold">Complaint Details</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Title</Th>
                                    <Th>Description</Th>
                                    <Th>Client</Th>
                                    <Th>Current Date</Th>
                                    {/* Add more headers as needed */}
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {complaints.map((complaint, i) => (
                                    <Tr key={i} _hover={{ bg: "green.100" }}>
                                        <Td>{complaint.title}</Td>
                                        <Td>{complaint.description}</Td>
                                        <Td>{complaint.client}</Td>
                                        <Td>{complaint.currentDate}</Td>
                                        {/* Add more cells as needed */}
                                        <Td>
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                    size="sm"
                                                    colorScheme="green"
                                                    onClick={() => handlePostComplaintButtonClick(complaint)}
                                                    leftIcon={<MdOutlineInsertComment />}
                                                >
                                                    Comment
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    colorScheme="blue"
                                                    onClick={() => handleCommentButtonClick(complaint)}
                                                    leftIcon={<GoCommentDiscussion />}
                                                >
                                                    Discussion
                                                </Button>
                                            </Stack>
                                        </Td>

                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    ) : (
                        <Text fontSize="lg" color="gray.600">
                            No matching complaints found.
                        </Text>
                    )}

                    {/*
                    <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                        {complaints.map((complaint, i) => (
                            <div key={i} onClick={() => handleClick(complaint)}>
                                <ComplaintNFT
                                    title={complaint.title}
                                    description={complaint.description}
                                    client={complaint.client}
                                    currentDate={complaint.currentDate}
                                />
                                <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        colorScheme="green"
                                        onClick={() => handlePostComplaintButtonClick(complaint)}
                                        leftIcon={<MdOutlineInsertComment />}
                                    >
                                        Post Comment
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        onClick={() => handleCommentButtonClick(complaint)}
                                        leftIcon={<GoCommentDiscussion />}
                                    >
                                        Discussion
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </SimpleGrid>
                    */}
                </Flex>
                <Flex
                    flexDirection="column"
                    gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
                >
                    {/* Other content for the second column */}
                </Flex>
            </Grid>
        </Box>




            {showComplaintPopup && selectedComplaint && (
                <div className="complaint-popup">
                    {/* Your Complaint Popup Component */}
                    <ComplaintPopup
                        title={selectedComplaint.title}
                        description={selectedComplaint.description}
                        profilePicture={selectedComplaint.profilePicture}
                        client={selectedComplaint.client}
                        currentDate={selectedComplaint.currentDate}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
            {/* PostComplaintPopup Component */}
            {showPostComplaintPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <PostComplaintPopup
                        complaintId={selectedComplaint._id }
                        onClose={handleClosePopup}
                    />
                </div>
            )}

            {showCommentPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <CommentComplaintPopup
                        selectedComplaint={selectedComplaint}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}
export default ComplaintsOverview;
