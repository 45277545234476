import React, { useState } from "react";
import {
    Flex,
    Box,
    Image,
    Text,
    Button,
    Icon,
    useColorModeValue,
} from "@chakra-ui/react";
import { IoHeart, IoHeartOutline, IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import AuthService from "../../../views/services/authService";
import Card from "UIX/components/card/Card.js";

export default function CategoryNFT({ name, frequency, profilePicture }) {
    const currentUser = AuthService.getCurrentUser();
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");

    return (
        <Card p="10px">
            {/* Like Button */}
            <Button
                position="absolute"
                bg="white"
                _hover={{ bg: "whiteAlpha.900" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
                p="0px !important"
                top="8px" // Adjust the distance from the top
                right="8px" // Adjust the distance from the right
                borderRadius="50%"
                minW="36px"
                h="36px"
                onClick={() => {
                    setLike(!like);
                }}
            >
                <Icon
                    transition="0.2s linear"
                    w="20px"
                    h="20px"
                    as={like ? IoHeart : IoHeartOutline}
                    color="brand.500"
                />
            </Button>
            <Flex direction="column" alignItems="center">
                {/* account Picture */}
                <Box mb="20px" position="relative">
                    <Image
                        src={profilePicture}
                        w="150px" // Adjust the width and height as needed
                        h="150px"
                        borderRadius="20px"
                        objectFit="cover"
                    />

                </Box>

                {/* Name */}
                <Text
                    color={textColor}
                    fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
                    mb="5px"
                    fontWeight="bold"
                >
                    {name}
                </Text>

                {/* Frequency */}
                <Text color={textColor}>{frequency}</Text>

                {/* Edit and Delete Buttons */}

                {currentUser && (
                    <Flex direction="row">
                        <Box  me="5px"> {/* Set a maximum width for the container and reduce the margin */}
                            <Button
                                as="a"
                                href={`/edit/${name}`}
                                variant="darkBrand"
                                color="white"
                                fontSize="sm"
                                fontWeight="500"
                                borderRadius="70px"
                                px="24px"
                                py="5px"
                                me="2px"
                            >
                                <Icon as={IoPencilOutline} me="2px" />
                                Edit
                            </Button>
                        </Box>
                        <Box  me="5px"> {/* Set a maximum width for the container and reduce the margin */}
                            <Button
                                variant="darkBrand"
                                color="white"
                                fontSize="sm"
                                fontWeight="500"
                                borderRadius="70px"
                                px="12px"
                                py="5px"
                                onClick={() => {
                                    // Here, you can implement the delete functionality
                                    // by calling an API to delete the CategoryNFT with the 'name' as an identifier.
                                    // After successful deletion, you can handle the UI updates or redirection.
                                }}
                            >
                                <Icon as={IoTrashOutline} me="1px" />
                                Delete
                            </Button>
                        </Box>
                    </Flex>
                )}

            </Flex>
        </Card>
    );
}
