import instance from "./http-common";

class NotificationService {
    getAllNotifications() {
        return instance.get("/notifications");
    }

    getNotificationById(id) {
        return instance.get(`/notifications?id=${id}`);
    }

    getUsernames() {
        return instance.get("/notifications/usernames");
    }

    getNotificationsByUsername(username) {
        console.log(username)
        return instance.get(`/notifications/byusername/${username}`);
    }

    postNotification(data) {
        return instance.post("/notifications", data);
    }

    updateNotification(data) {
        return instance.put("/notifications", data);
    }

    deleteNotification(id) {
        return instance.delete(`/notifications?id=${id}`);
    }

    deleteAllNotifications() {
        return instance.delete('/notifications/all');
    }
}

export default new NotificationService();
