import React, { useState } from "react";
import {
    Flex,
    Box,
    Image,
    Text,
    Button,
    Icon,
    useColorModeValue,
} from "@chakra-ui/react";
import { IoHeart, IoHeartOutline, IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import AuthService from "../../../views/services/authService";
import Card from "UIX/components/card/Card.js";

export default function PollNFT(  {subject}) {
    const textColor = useColorModeValue("navy.700", "white");

    return (
        <Card p="10px">
            <Flex direction="column" alignItems="center">
                <Text
                    color={textColor}
                    fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
                    mb="5px"
                    fontWeight="bold"
                >
                    {subject}
                </Text>

                {/* Options */}

                {/* Edit and Delete Buttons */}

            </Flex>
        </Card>
    );
}
