import React, {useState, useEffect} from "react";
import ComplaintDataService from "../../../services/complaintService";
import AuthService from "../../../services/authService";
import ComplaintNFT from "UIX/components/card/ComplaintNFT";
import Banner from "views/admin/complaint/complaintsOverview/components/Banner";
import Card from "UIX/components/card/Card.js";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import { Table, Tbody, Tr, Td ,Thead,Th} from '@chakra-ui/react';

import {
    Text,
    Input,
    Textarea,
    Box,
    Button,
    Image,
    Flex,
    Grid,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";


function ComplaintPopup({ title, description, profilePicture, client, currentDate, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Image
                        src={profilePicture}
                        w={{base: "100%", "3xl": "100%"}}
                        h={{base: "100%", "3xl": "100%"}}
                        borderRadius="20px"
                    />
                    <p><strong>Client:</strong> {client}</p>
                    <p><strong>Date:</strong> {currentDate}</p>
                    <p>{description}</p>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}


function CommentComplaintPopup({ selectedComplaint, onClose }) {
    return (
        <div>
            <Modal isOpen={true} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Main Fields */}
                        {selectedComplaint.comments && selectedComplaint.comments.length > 0 ? (
                            <Table variant="striped" colorScheme="teal">
                                <Thead>
                                    <Tr>
                                        <Th>Username</Th>
                                        <Th>Comment</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedComplaint.comments.map((comment, i) => (
                                        <Tr key={i}>
                                            <Td>{comment.username}</Td>
                                            <Td>{comment.text}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Box textAlign="center">
                                <Text>No comments for this complaint.</Text>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}


function PostComplaintPopup({complaintId, onClose} ) {
    const user = AuthService.getCurrentUser()
    // State to store the form inputs
    const [username, setUsername] = useState(user.username);
    const [commentText, setCommentText] = useState('');
    const userDoc = {
        username,
        complaintId,
        commentText
    };
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the postComment function with the form inputs
        ComplaintDataService.postComment(userDoc);
        // Reset form inputs
        setUsername('');
        setCommentText('');
        // Close the popup
    };
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Post a Comment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Username:
                            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        </label>

                        <label>
                            Comment:
                            <Textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} required />
                        </label>
                        <Button type="submit">Submit</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function ComplaintsOverview() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [arrayT, setArrayT] = useState([]);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [showPostComplaintPopup, setShowPostComplaintPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);

    const [selectedComplaint, setSelectedComplaint] = useState(null);

    useEffect(() => {
        retrieveComplaints();
    }, [refreshKey]);

    const retrieveComplaints = () => {
        ComplaintDataService.getAll()
            .then((response) => {
                setArrayT(response.data.complaints);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const deleteComplaint = (complaintID) => {
        ComplaintDataService.deleteComplaint(complaintID)
            .then((response) => {
                setRefreshKey((oldKey) => oldKey + 1);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowComplaintPopup(true); // Open the complaints popup
    };
    const handleCommentButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showPostComplaintPopup) {
            setShowPostComplaintPopup(false); // Close the post complaints popup if open
        }
        setShowCommentPopup(true); // Open the complaints popup
    };

    const handlePostComplaintButtonClick = () => {
        setSelectedComplaint('');
        if (showComplaintPopup) {
            setShowComplaintPopup(false); // Close the complaints popup if open
        }
        setShowPostComplaintPopup(true); // Open the post complaints popup
    };


    const handleClosePopup = () => {
        setShowComplaintPopup(false);
        setShowPostComplaintPopup(false);
        setShowCommentPopup(false);

    };

    // Function to handle the button click and show the PostComplaintPopup




    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection='column'
                        gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
                        <Banner
                            gridArea='1 / 1 / 2 / 2'
                            banner={banner}
                        />
                        <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                            {arrayT.map((complaint, i) => (
                                <div key={i} onClick={() => handleClick(complaint)}>
                                    <ComplaintNFT
                                        title={complaint.title}
                                        description={complaint.description}
                                        client={complaint.client}
                                        currentDate={complaint.currentDate}
                                    />
                                    <Button onClick={handlePostComplaintButtonClick}>
                                        Post a Complaint
                                    </Button>
                                    <Button onClick={handleCommentButtonClick}>
                                        Comment
                                    </Button>
                                </div>

                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex
                        flexDirection='column'
                        gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
                    >

                    </Flex>
                </Grid>
            </Box>
            {showComplaintPopup && selectedComplaint && (
                <div className="complaint-popup">
                    {/* Your Complaint Popup Component */}
                    <ComplaintPopup
                        title={selectedComplaint.title}
                        description={selectedComplaint.description}
                        profilePicture={selectedComplaint.profilePicture}
                        client={selectedComplaint.client}
                        currentDate={selectedComplaint.currentDate}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
            {/* PostComplaintPopup Component */}
            {showPostComplaintPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <PostComplaintPopup
                        complaintId={selectedComplaint._id }
                        onClose={handleClosePopup}
                    />
                </div>
            )}

            {showCommentPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <CommentComplaintPopup
                        selectedComplaint={selectedComplaint}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}


export default ComplaintsOverview;


/**

 <button
 className="btn btn-primary"
 onClick={() => deleteComplaint(complaints._id)}
 >
 Delete Complaint
 </button>




 <div className="col-lg-4 pb-1">
 <div className="card">
 <div className="card-body">
 <h5 className="card-title">Total</h5>
 <p className="card-text">
 <strong>Shekel: </strong>
 {TotalCommand}
 <br />
 </p>
 </div>
 </div>
 <div className="icon" onClick={() => history.replace("/user/overviewComplaint")}>
 ➕
 </div>
 <div
 className="icon"
 onClick={() => history.replace("/user/overviewComplaint")}>
 ❮❮
 </div>
 </div>


 **/