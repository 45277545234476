import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Image, Grid, SimpleGrid } from "@chakra-ui/react";


import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useColorModeValue } from "@chakra-ui/react";
import Card from "UIX/components/card/Card.js";
import PollService from "../../../services/pollService";
import {useHistory} from "react-router-dom";
function renderBarChart(data) {
    console.log(data)
    return (
        <Card>
        <BarChart width={500} height={300} data={data}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis dataKey="option" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
        </Card>
    );
}
export default function WeeklyRevenue(props) {
    const history = useHistory();
    const [polls, setPolls] = useState([]);
    const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
    const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await PollService.getAll();
            if (response.data && Array.isArray(response.data.polls)) {
                setPolls(response.data.polls);
                const array = response.data.polls[0].options;
                const convertedData = array.map(item => ({
                    ...item,
                    count: parseInt(item.count)
                }));
                setData(convertedData);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };
    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                    md: "1fr 1fr", // Two columns for medium screens and above
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
            >
                {polls.map((pollData, index) => (
                    <Flex
                        key={index}
                        flexDirection="column"
                        alignItems="center" // Center align the content
                    >
                        <Card>
                            <h2>{pollData.Subject}</h2>
                            {renderBarChart(pollData.options)}
                        </Card>
                    </Flex>
                ))}
            </Grid>
        </Box>
    );
}
