// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    // Existing imports...
    IoPencilOutline,
    IoTrashOutline,
} from "react-icons/io5";
// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useEffect, useState} from "react";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import { MdOutlinePayments } from "react-icons/md";

import AuthService from "../../../views/services/authService";
import InvoiceService from "../../../views/services/invoiceService";
import InvoiceDataService from "../../../views/services/invoiceService";

export default function InvoicePaymentNFT({id,category, amount, name,dateSend}) {
    const currentUser = AuthService.getCurrentUser();
    const [payment, setPayment] = useState("");
    const [invoices, setInvoices] = useState("");
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");

    useEffect(() => {
        find(id, "_id")
        // Fetch invoices when the component mounts
    }, []);

    const find = (query, by) => {
        InvoiceDataService.find(query, by) // Replace 'id' with 'query'
            .then((response) => {
                setInvoices(response.data.invoices);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const handleUpdate = () => {
        find(id, "_id")
        const x = new Date().toLocaleString() + ""
        const updatedInvoice= {
            _id: id,
            category: invoices[0].category,
            amount: invoices[0].amount,
            name: invoices[0].name,
            dateSend: invoices[0].dateSend,
            datePayed : x
        };
        InvoiceService.updateInvoice(updatedInvoice)
            .then((response) => {
                alert("Finish");
                console.log("User updated successfully:", response);
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error updating user:", error);
            });
    };


    return (
        <Card p="10px" borderRadius="20px" boxShadow="md">
            {/* Like Button */}
            <Button
                position="absolute"
                bg="white"
                _hover={{ bg: "whiteAlpha.900" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
                p="0px !important"
                top="8px" // Adjust the distance from the top
                right="8px" // Adjust the distance from the right
                borderRadius="50%"
                minW="36px"
                h="36px"
                onClick={() => {
                    setLike(!like);
                }}
            >
                <Icon
                    transition="0.2s linear"
                    w="20px"
                    h="20px"
                    as={like ? IoHeart : IoHeartOutline}
                    color="brand.500"
                />
            </Button>
            <Flex direction="column" alignItems="center">
                {/* Name */}
                <Text
                    color={textColor}
                    fontSize={{
                        base: "xl",
                        md: "lg",
                        lg: "lg",
                        xl: "lg",
                        "2xl": "md",
                        "3xl": "lg",
                    }}
                    mb="5px"
                    fontWeight="bold"
                >
                    {name}
                </Text>

                {/* Category */}
                <Text color={textColor}>{category}</Text>

                {/* Amount */}
                <Text color={textColorBid} fontSize="xl">
                    {amount}
                </Text>

                {/* Edit and Delete Buttons */}
                <Flex direction="row" mt="10px">
                    <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="24px"
                        py="5px"
                        me="4px"
                        onClick={handleUpdate}
                    >
                        <Icon as={MdOutlinePayments} me="2px" />
                        Payment
                    </Button>
                </Flex>
            </Flex>
        </Card>
    );

}
