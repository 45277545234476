import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    Grid,
    SimpleGrid,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import CategoryDataService from "../../../../services/categoryService";
import Banner from "../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import CategoryNFT from "../../../../../UIX/components/card/CategoryNFT";

function CategoryPopup({name, frequency, profilePicture, onClose}) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{name}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Image
                        src={profilePicture}
                        w={{base: "100%", "3xl": "100%"}}
                        h={{base: "100%", "3xl": "100%"}}
                        borderRadius="20px"
                    />
                    <Text>
                        <strong>Name:</strong> {name}
                    </Text>
                    <Text>
                        <strong>Frequency:</strong> {frequency}
                    </Text>
                    {/* Add any other category details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function CategoriesOverview() {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        // Fetch categories when the component mounts
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await CategoryDataService.getAll();
            console.log(response);
            if (response.data && Array.isArray(response.data.categories)) {
                setCategories(response.data.categories);

            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleConfigurationClick = () => {
        console.log("handleConfigurationClick");
        history.push("/adminTask/addCategory"); // Redirect to the "Configuration" page
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection='column'
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}>
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{base: 1, md: 5}} gap="20px">
                            {categories.map((category, i) => (
                                <div key={i} onClick={() => handleCategoryClick(category)}>
                                    <CategoryNFT
                                        name={category.name}
                                        frequency={category.frequency}
                                        profilePicture={category.profilePicture}

                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex
                        flexDirection='column'
                        gridArea={{xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3"}}
                    >

                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedCategory && (
                <div className="category-popup">
                    {/* Your Category Popup Component */}
                    <CategoryPopup
                        name={selectedCategory.name}
                        frequency={selectedCategory.frequency}
                        profilePicture={selectedCategory.profilePicture}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default CategoriesOverview;
