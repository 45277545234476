import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Select,
    useColorModeValue,
} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/toast";
import MessageDataService from "../../../../services/messageService";
import UserDataService from "../../../../services/userService";
import AuthService from "../../../../services/authService";

function Index() {
    const toast = useToast();
    const [users, setUsers] = useState([]);
    const [initialNewMessage, setInitialNewMessage] = useState('');

    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();

    const [message, setMessage] = useState({
        fromUser: currentUser,
        toUser: "",
        conversation: [],
    });


    const retrieveUsers = () => {
        UserDataService.getAll()
            .then((response) => {
                setUsers(response.data.users);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrieveUsers();
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            const newMessage = {
                fromUser: currentUser,
                toUser: message.toUser,
                conversation: [
                    {
                        User: currentUser.username,
                        date: new Date().toISOString(),
                        text: initialNewMessage, // Modify this text as needed
                    },
                ],
            };
            const response = await MessageDataService.addConversation(newMessage);
            if (response.status === 200) {
                alert('Message Send');
                window.location.reload();
                setInitialNewMessage(''); // Clear the input field
                history.goBack(); // Redirect to the "Add Poll" page

            } else {
                alert("Error recording vote");
                // Handle error scenario if the vote
            }

        } catch (error) {
            console.error("Message creation error:", error);
            toast({
                title: "An error occurred during message creation. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
        }
    }
    const handleNewMessageChange = (event) => {
        console.log(initialNewMessage)
        setInitialNewMessage(event.target.value);
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setMessage({...message, [name]: value});
    };

    return (
        <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
            <Flex justify="center" align="center" height="100vh">
                <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                    <Heading as="h2" size="lg" mb="4">
                        New Message
                    </Heading>
                    <form onSubmit={handleSubmit}>
                        <FormControl mt="4">
                            <FormLabel>From User</FormLabel>
                            <Input
                                required
                                value={currentUser.fullName} // Display the full name of the sender
                                readOnly
                            />
                        </FormControl>
                        <FormControl mt="4">
                            <FormLabel>To User</FormLabel>
                            <Select
                                required
                                placeholder="Select recipient's username"
                                name="toUser" // Add this line
                                value={message.toUser}
                                onChange={handleInputChange}
                            >
                                {users
                                    .filter((user) => user._id !== currentUser.id)
                                    .map((user) => (
                                        <option key={user._id} value={user._id}>
                                            {user.fullName}
                                        </option>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl mt="4">
                            <FormLabel>Message</FormLabel>
                            <Input
                                type="text"
                                value={initialNewMessage}
                                onChange={handleNewMessageChange}
                                placeholder="Type your message..."
                            />
                        </FormControl>
                        <FormControl mt="4">
                            <Button variant="brand" w="100%" size="lg" type="submit">
                                Create Message
                            </Button>
                        </FormControl>
                    </form>
                </Box>
            </Flex>
        </Box>
    );
}

export default Index;
