import AuthService from "../../services/authService";
import UserDataService from "../../services/userService";
import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    Image,
    useColorModeValue,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import DefaultAuth from "layouts/auth/Default";

function Index() {
    const toast = useToast();

    const initialUser = {
        idNumber: "",
        mail: "",
        username: "",
        password: "",
        fullName: "",
        profilePicture: "",
        role: "",
        building: "",
    };

    const [user, setUser] = useState(initialUser);
    const [idNumber, setIdNumber] = useState("");
    const [mail, setMail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [profilePicture, setPic] = useState(""); // Updated to empty string
    const [building, setBuilding] = useState("");
    const [role, setRole] = useState("User");

    const [idNumberVerification, setIdNumberVerification] = useState("");
    const [mailVerification, setMailVerification] = useState("");
    const [usernameVerification, setUsernameVerification] = useState("");
    const [passwordVerification, setPasswordVerification] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordVerification, setConfirmPasswordVerification] = useState("");

    const [picLoading, setPicLoading] = useState(false);
    const history = useHistory();
    const textColor = useColorModeValue("navy.700", "white");

    async function handleRegister(event) {
        event.preventDefault();

        // Reset all error messages
        setIdNumberVerification("");
        setMailVerification("");
        setUsernameVerification("");
        setPasswordVerification("");
        setConfirmPasswordVerification("");

        // Verification checks
        if (!idNumber || idNumber.length !== 9) {
            setIdNumberVerification("ID Number must be 9 characters");
        }

        if (!mail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {

            setMailVerification("Invalid email format");
        }
        if(mail){
            const doc ={mail:mail};
            const checkMail = await UserDataService.getUserByAttribute(doc);
            if(checkMail.data.userExists)
            {
                setMailVerification("Mail already register ");
            }
        }

        if (!username) {
            setUsernameVerification("Username is required");
        }
        if (username) {
            const doc ={username:username};
            const checkUsername = await UserDataService.getUserByAttribute(doc);
            if(checkUsername.data.userExists)
            {
                setUsernameVerification("Username already take ");
            }
        }

        if (!password || password.length < 8) {
            setPasswordVerification("Password must be at least 8 characters");
        }

        if (password !== confirmPassword) {
            setConfirmPasswordVerification("Passwords do not match");
        }

        // If any verification error, return without making the API call
        if (
            idNumberVerification ||
            mailVerification ||
            usernameVerification ||
            passwordVerification ||
            confirmPasswordVerification
        ) {
            return;
        }

        try {
            const userDoc = {
                idNumber,
                mail,
                username,
                password,
                fullName,
                profilePicture,
                role,
                building,
            };




            const response = await UserDataService.signupUser(userDoc);
            if (response.data.status === "success") {
                setUser(response.data);
                history.replace("/sign-in");
            } else {
                alert("Registration failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("An error occurred during registration. Please try again later.");
        }
    }

    const postDetails = (pics) => {
        setPicLoading(true);

        if (pics === undefined) {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
            return;
        }

        if (pics.type === "image/jpeg" || pics.type === "image/png") {
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "piyushproj");

            fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
                method: "post",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    setPic(data.url.toString());
                    setPicLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPicLoading(false);
                });
        } else {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
            return;
        }
    };

    return (
        <DefaultAuth>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Register
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color="gray.400"
                        fontWeight="400"
                        fontSize="md"
                    >
                        Please enter your details 🙂
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                >
                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            idNumber
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            value={idNumber}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 9) {
                                    setIdNumber(inputValue);
                                } else {
                                    console.error("idNumber should not exceed 9 characters");
                                }
                            }}
                        />
                        {idNumberVerification && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                {idNumberVerification}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Email
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            type="email"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                        />
                        {mailVerification && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                {mailVerification}
                            </Text>
                        )}
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Full Name
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        {/* Add any specific validation message for fullName here if needed */}
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Username
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {usernameVerification && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                {usernameVerification}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Password
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordVerification && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                {passwordVerification}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Confirm Password
                            <Text color="red.500" ml="1" fontSize="md" fontWeight="bold">
                                *
                            </Text>
                        </FormLabel>
                        <Input
                            required
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {confirmPasswordVerification && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                {confirmPasswordVerification}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Upload your Picture</FormLabel>
                        <Flex align="center">
                            <Input
                                type="file"
                                p={1.5}
                                accept="image/*"
                                onChange={(e) => postDetails(e.target.files[0])}
                                display="none"
                                id="profilePictureInput"
                            />
                            <label htmlFor="profilePictureInput">
                                <Button as="span" variant="outline" colorScheme="brand" cursor="pointer">
                                    Select Image
                                </Button>
                            </label>
                            {profilePicture && (
                                <Box ml={4}>
                                    <Image src={profilePicture} alt="Profile Preview" boxSize="50px" objectFit="cover" borderRadius="full" />
                                </Box>
                            )}
                        </Flex>
                        {picLoading && (
                            <Text color="gray.400" fontSize="sm" mt="1">
                                Uploading picture...
                            </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                        <Button
                            variant="brand"
                            w="100%"
                            size="lg"
                            onClick={handleRegister}
                        >
                            Register
                        </Button>
                    </FormControl>

                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    >
                        <Text color="gray.400" fontWeight="400" fontSize="14px">
                            Already have an account?
                            <NavLink to="/login">
                                <Text color="brand.500" as="span" ms="5px" fontWeight="500">
                                    Log in
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default Index;
