import { Box, Button, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";
import { NavLink, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ComplaintDataService from "../../../../../services/complaintService";
import ComplaintLightNFT from "UIX/components/card/ComplaintLightNFT";

function ComplaintsOverview() {
    const brandColor = useColorModeValue("brand.500", "white");

    const [refreshKey, setRefreshKey] = useState(0);
    const [arrayT, setArrayT] = useState([]);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [showPostComplaintPopup, setShowPostComplaintPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [selectedComplaint, setSelectedComplaint] = useState(null);

    useEffect(() => {
        retrieveComplaints();
    }, [refreshKey]);

    const retrieveComplaints = () => {
        ComplaintDataService.getAll()
            .then((response) => {
                setArrayT(response.data.complaints);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handlePostComplaintButtonClick = (complaint) => {
        setSelectedComplaint(complaint);
        if (showComplaintPopup) {
            setShowComplaintPopup(false); // Close the complaints popup if open
        }
        setShowPostComplaintPopup(true); // Open the post complaints popup
    };

    return (
        <>
            {arrayT.map((complaint, i) => (
                <Box
                    key={i}
                    bgColor="gray.100"
                    borderRadius="xl"
                    p="4"
                    mb="20px" // Add margin-bottom for space
                    boxShadow="md" // Add a subtle box shadow for a card-like appearance
                >
                    <ComplaintLightNFT
                        title={complaint.title}
                        description={complaint.description}
                        client={complaint.client}
                        currentDate={complaint.currentDate}
                        comments={complaint.comments}
                        complaint={complaint}
                    />
                </Box>
            ))}
        </>


);


}

export default ComplaintsOverview;
