import {
    AvatarGroup,
    Checkbox,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
    FormControl,
    FormLabel,
} from "@chakra-ui/react";

import {
    // Existing imports...
    IoPencilOutline,
    IoTrashOutline,
} from "react-icons/io5";
// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, { useEffect, useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import AuthService from "../../../views/services/authService";
import UserDataService from "../../../views/services/userService";

const EditUser = ({_id, idNumber, fullName, mail, username,password, profilePicture,role, building, onClose, onSave }) => {
    console.log(_id,idNumber, fullName, mail, username,password, profilePicture,role, building)
    const [editedIdNumber, setEditedIdNumber] = useState(idNumber);
    const [editedFullName, setEditedFullName] = useState(fullName);
    const [editedMail, setEditedMail] = useState(mail);
    const [editedUsername, setEditedUsername] = useState(username);
    const [editedPassword, setEditedPassword] = useState(password);
    const [editedProfilePicture, setEditedProfilePicture] = useState(profilePicture);
    const [editedRole, setEditedRole] = useState(role);
    const [editedBuilding, setBuilding] = useState(building);

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "idNumber":
                editedIdNumber(value);
                break;
            case "fullName":
                setEditedFullName(value);
                break;
            case "mail":
                setEditedMail(value);
                break;
            case "username":
                setEditedUsername(value);
                break;
            case "password":
                setEditedPassword(value);
                break;
            case "profilePicture":
                setEditedProfilePicture(value);
                break;
            case "role":
                setEditedRole(value);
                break;
            case "building":
                setBuilding(value);
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        const updatedUser = {
            _id : _id,
            user_id: editedIdNumber,
            fullName: editedFullName,
            mail: editedMail,
            username: editedUsername,
            password: editedPassword,
            profilePicture: editedProfilePicture,
            role:editedRole,
            building: editedBuilding,
        };
        console.log(updatedUser);
        UserDataService.updateUser(updatedUser)
            .then((response) => {
                alert("Finish");
                console.log("User updated successfully:", response);
                onSave(updatedUser); // Call the onSave function with updated user details
                onClose(); // Close the modal after saving
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error updating user:", error);
            });
    };

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        <strong>User ID:</strong>
                    </Text>
                    <Input
                        value={editedIdNumber}
                        onChange={handleChange}
                        name="user_id"

                    />

                    <Text mt={2}>
                        <strong>Full Name:</strong>
                    </Text>
                    <Input
                        value={editedFullName}
                        onChange={handleChange}
                        name="fullName"
                    />

                    <Text mt={2}>
                        <strong>Mail:</strong>
                    </Text>
                    <Input
                        value={editedMail}
                        onChange={handleChange}
                        name="mail"
                    />

                    <Text mt={2}>
                        <strong>Username:</strong>
                    </Text>
                    <Input
                        value={editedUsername}
                        onChange={handleChange}
                        name="username"
                    />

                    <Text mt={2}>
                        <strong>Password:</strong>
                    </Text>
                    <Input
                        value={editedPassword}
                        onChange={handleChange}
                        name="password"
                    />
                    <Text mt={2}>
                        <strong>profilePicture:</strong>
                    </Text>
                    <Input
                        value={editedProfilePicture}
                        onChange={handleChange}
                        name="profilePicture"
                    />
                    <Text mt={2}>
                        <strong>Role:</strong>
                    </Text>
                    <Checkbox
                        isChecked={editedRole}
                        onChange={(e) => setEditedRole(e.target.checked)}
                        name="role"
                    />
                    <Button onClick={handleSave}>Save</Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default function UserNFT({_id, user_id, fullName, mail, username,password, profilePicture,isAdmin, isManager, building, onClose, onSave }) {
    const user = { user_id, fullName, mail, username,password, profilePicture,isAdmin, isManager, building, onClose, onSave };
    //console.log(user)
    const [users, setUsers] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");
    const [showPopup, setShowPopup] = useState(false);
    const [selectedUser, setSelectedUser] = useState(user);
    const [showEditPopup, setShowEditPopup] = useState(false);

    useEffect(() => {
        // Fetch users when the component mounts
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await UserDataService.getAll();
            if (response.data && Array.isArray(response.data.users)) {
                setUsers(response.data.users);
                console.log(users)
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };
    const handleDeleteUser = (userID) => {
        console.log("Deleting user with ID:", userID);
        UserDataService.deleteUser(userID)
            .then((response) => {
                console.log(response.data);
                fetchUsers(); // Refresh the user list after deletion
            })
            .catch((e) => {
                console.log(e);
            });
        window.location.reload();
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        console.log(selectedUser)
        setShowEditPopup(true);
    };

    const handleSaveEditUser = (updatedUser) => {
        // Call the API or do any necessary processing to save the updated user details
        console.log("Updated user details:", updatedUser);
        // For now, we'll just update the users state with the updated details (no API call)
        setUsers((prevUsers) =>
            prevUsers.map((user) => (user.user_id === selectedUser.user_id ? { ...user, ...updatedUser } : user))
        );
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    return (
        <Card p="10px">
            {/* Like Button */}
            <Button
                position="absolute"
                bg="white"
                _hover={{ bg: "whiteAlpha.900" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
                p="0px !important"
                top="8px" // Adjust the distance from the top
                right="8px" // Adjust the distance from the right
                borderRadius="50%"
                minW="36px"
                h="36px"
                onClick={() => {
                    setLike(!like);
                }}
            >
                <Icon
                    transition="0.2s linear"
                    w="20px"
                    h="20px"
                    as={like ? IoHeart : IoHeartOutline}
                    color="brand.500"
                />
            </Button>
            <Flex direction="column" alignItems="center">
                {/* account Picture */}
                <Box mb="20px" position="relative">
                    <Image
                        src={profilePicture}
                        w="150px" // Adjust the width and height as needed
                        h="150px"
                        borderRadius="20px"
                        objectFit="cover"
                    />
                </Box>

                {/* Name */}
                <Text
                    color={textColor}
                    fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
                    mb="5px"
                    fontWeight="bold"
                >
                    {fullName}
                </Text>

                {/* Username */}
                <Text color={textColor}>{username}</Text>

                {/* Mail */}
                <Text color={textColor}>{mail}</Text>

                {/* Admin status */}
                <Text color={textColor}>Admin: {user.isAdmin === "true" ? "Yes" : "No"}</Text>

                {/* manager status */}
                <Text color={textColor}>Manager: {user.isManager === "true" ? "Yes" : "No"}</Text>


                {/* Edit and Delete Buttons */}
                <Flex direction="row">
                    <Button
                        as="a"
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="24px"
                        py="5px"
                        me="4px"
                        onClick={() =>
                            handleEditUser({ user_id, fullName, mail, username,password, profilePicture,isAdmin, isManager, building, onClose, onSave })
                        }
                    >
                        <Icon as={IoPencilOutline} me="2px" />
                        Edit
                    </Button>
                    <Button
                        variant="darkBrand"
                        color="white"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        px="12px"
                        py="5px"
                        onClick={() => {
                            handleDeleteUser(_id);
                        }}
                    >
                        <Icon as={IoTrashOutline} me="2px" />
                        Delete
                    </Button>
                </Flex>
            </Flex>
            {/* Popup for editing user details */}
            {showEditPopup && selectedUser && (
                <div className="edit-user-popup">
                    <EditUser
                        _id = {_id}
                        user_id={selectedUser.user_id}
                        fullName={selectedUser.fullName}
                        mail={selectedUser.mail}
                        username={selectedUser.username}
                        password={selectedUser.password}
                        profilePicture={selectedUser.profilePicture}
                        isAdmin={selectedUser.isAdmin}
                        isManager={selectedUser.isManager}
                        onClose={() => setShowEditPopup(false)}
                        onSave={handleSaveEditUser}
                    />
                </div>
            )}
        </Card>
    );
}
