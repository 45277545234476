

import React from "react";

// Chakra imports
import {
  Box,
    Flex,
  Grid,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components


// Assets

import AuthService from "../../../services/authService";
import TotalSpent from "../../dashboardUser/default/components/TotalSpent";
import PieCard from "../../dashboardUser/default/components/PieCard";

export default function Finance(props) {

    const idBuilding = props.idBuilding
    console.log(idBuilding)
  // Chakra Color Mode
    const currentUser = AuthService.getCurrentUser();
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {/* Main Fields */}
            <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent="space-between"
                gap={{ base: "20px", xl: "20px" }}
            >
                {/* Add your main content here */}
            </Flex>
            <SimpleGrid columns={1} mt={3} mb={3} rowGap={4}>
                <TotalSpent
                    p="2" // Adjust padding
                    borderRadius="md" // Adjust border radius
                    textAlign="center"
                    color="white"
                    idBuilding={idBuilding}
                />

                <PieCard
                    p="2" // Adjust padding
                    borderRadius="md" // Adjust border radius
                    textAlign="center"
                    color="white"
                    idBuilding={idBuilding}
                />
            </SimpleGrid>

        </Box>
    );

}
