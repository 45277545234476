

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {  Flex, Icon, Box, Text } from '@chakra-ui/react'; // Assuming you're using Chakra UI components
import { MdOutlineAccountBalance } from 'react-icons/md';
import BuildingDataService from '../../../services/buildingService'; // Import your BuildingDataService
import Card from "UIX/components/card/Card.js";
import IconBox from "UIX/components/icons/IconBox";
import AuthService from "../../../services/authService";
import Menu from "../../../../UIX/components/menu/BuldingManagerMenu";

export default function Banner(rest) {
    let idBuilding = rest.idBuilding
    console.log(rest)
    const [buildings, setBuildings] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();

    useEffect(() => {
        // Fetch buildings when the component mounts
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const response = await BuildingDataService.getAll();
            console.log(response.data.buildingsList);
            if (response.data && Array.isArray(response.data.buildingsList)) {
                const filteredBuildings = response.data.buildingsList.filter(building => building._id === idBuilding);
                setBuildings(filteredBuildings);
            }
            setLoading(false); // Set loading to false once buildings are fetched
        } catch (error) {
            // Handle the error
            console.error("Error fetching buildings:", error);
            setLoading(false); // Set loading to false in case of an error
        }
    };

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
    }
    const textColorSecondary = "secondaryGray.600";


    return (
        <div>
            {buildings.map((building) => (
                <Card mb={{ base: "0px", lg: "20px" }} align='center' key={building.id}>
                    <Flex w='100%'>
                        <Menu ms='auto' idBuilding={idBuilding}/>
                    </Flex>
                    <IconBox
                        mx='auto'
                        h='100px'
                        w='100px'
                        icon={
                            <Icon as={MdOutlineAccountBalance} color={building.brandColor} h='46px' w='46px' />
                        }
                        bg={building.box}
                    />
                    <Text color={textColorSecondary} fontWeight='bold' fontSize='xl' mt='10px'>
                        {building.apartments
                            ? `Apartment : ${
                                building.apartments.find(
                                    (apartment) => apartment.email === currentUser.mail
                                )?.number || 'N/A'
                            }`
                            : 'N/A'}
                    </Text>
                    <Text color={textColorSecondary} fontSize='md' maxW={{ base: "100%", xl: "80%", "3xl": "60%" }} mx='auto'>
                        {building.address ? `${building.address} : ${building.enter} ` : 'Supervise your drive space in the easiest way'}
                    </Text>
                    <Box w='100%' mt='auto'>
                        <Flex w='100%' justify='space-between' mb='10px'>
                            <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                                {building.numberOfApartment ? `Number of Apartments: ${building.numberOfApartment}` : 'N/A'}
                            </Text>
                            <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                                {building.manager ? `Manager: ${building.manager.fullName}` : 'N/A'}
                            </Text>
                        </Flex>
                    </Box>
                </Card>
            ))}
        </div>
    );
}
