// Chakra imports
import {
    Avatar,
    Box,
    SimpleGrid,
    Stack,
    Button,
    Flex,
    Grid,
    Icon,
    Image,
    Text,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Input,
    Textarea,
} from "@chakra-ui/react";
import Card from "UIX/components/card/Card.js";
import React, { useState, useEffect } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import AuthService from "../../../views/services/authService";
import ComplaintDataService from "../../../views/services/complaintService";
import UserDataService from "../../../views/services/userService";
import { MdAddCircle } from "react-icons/md";

function ComplaintPopup({ title, description, profilePicture, client, currentDate, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Image
                        src={profilePicture}
                        w={{ base: "100%", "3xl": "100%" }}
                        h={{ base: "100%", "3xl": "100%" }}
                        borderRadius="20px"
                    />
                    <p>
                        <strong>Client:</strong> {client}
                    </p>
                    <p>
                        <strong>Date:</strong> {currentDate}
                    </p>
                    <p>{description}</p>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function CommentComplaintPopup({ selectedComplaint, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    {selectedComplaint.comments && selectedComplaint.comments.length > 0 ? (
                        <Table variant="striped" colorScheme="teal">
                            <Thead>
                                <Tr>
                                    <Th>Username</Th>
                                    <Th>Comment</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectedComplaint.comments.map((comment, i) => (
                                    <Tr key={i}>
                                        <Td>{comment.username}</Td>
                                        <Td>{comment.text}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    ) : (
                        <Box textAlign="center">
                            <Text>No comments for this complaint.</Text>
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function PostComplaintPopup({ complaintId, onClose }) {
    const user = AuthService.getCurrentUser();
    const [username, setUsername] = useState(user.username);
    const [commentText, setCommentText] = useState("");
    const userDoc = {
        username,
        complaintId,
        commentText,
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        ComplaintDataService.postComment(userDoc);
        setUsername("");
        setCommentText("");
    };

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Post a Comment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Username:
                            <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        </label>

                        <label>
                            Comment:
                            <Textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} required />
                        </label>
                        <Button type="submit">Submit</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function ComplaintLightNFTContent(props) {
    const textColor = useColorModeValue("navy.700", "white");

    return (
        <Table variant="unstyled">
            <Tbody>
                {/* Main Content Row */}
                <Tr>
                    <Td textAlign="center">
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                            {/* First Column: Title and Client information */}
                            <Box>
                                <Text
                                    color={textColor}
                                    fontSize={{ base: "xl", md: "lg" }}
                                    mb="5px"
                                    fontWeight="bold"
                                    fontFamily="Helvetica, sans-serif"
                                    textDecoration="underline"
                                >
                                    {props.title}
                                </Text>
                                <Text
                                    color={textColor}
                                    fontSize={{ base: "md", md: "md" }}
                                    fontStyle="italic"
                                >
                                    {props.client}
                                </Text>
                            </Box>

                            {/* Second Column: Date */}
                            <Box>
                                <Text
                                    color="gray.500"
                                    fontSize={{ base: "md", md: "sm" }}
                                    mb="5px"
                                    fontWeight="bold"
                                    fontStyle="italic"
                                >
                                    {props.currentDate}
                                </Text>
                            </Box>

                            {/* Third Column: Avatar */}
                            <Box>
                                <Avatar src={props.clientPic} />
                            </Box>
                        </SimpleGrid>
                    </Td>
                </Tr>

                {/* Buttons Row */}
                <Tr>
                    <Td colSpan={3} textAlign="center">
                        <SimpleGrid columns={1} spacing={4}>
                            {/* Buttons for Mobile View */}
                            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                                <Button
                                    onClick={() => props.handleClick(props.complaint)}
                                    variant="outline"
                                    borderWidth="1px"
                                    color="gray.500"
                                    borderRadius="xl"
                                    _hover={{
                                        bg: "gray.500",
                                        color: "white",
                                    }}
                                >
                                    Details
                                </Button>
                                <Button
                                    onClick={() => props.handlePostComplaintButtonClick(props.complaint)}
                                    variant="outline"
                                    borderWidth="1px"
                                    color="gray.500"
                                    borderRadius="xl"
                                    _hover={{
                                        bg: "gray.500",
                                        color: "white",
                                    }}
                                >
                                    Comment
                                </Button>
                                <Button
                                    onClick={() => props.handleCommentButtonClick(props.complaint)}
                                    variant="outline"
                                    borderWidth="1px"
                                    color="gray.500"
                                    borderRadius="xl"
                                    _hover={{
                                        bg: "gray.500",
                                        color: "white",
                                    }}
                                >
                                    Chat
                                </Button>
                            </Stack>
                        </SimpleGrid>
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    );


}

export default function ComplaintLightNFT(props) {

    const [like, setLike] = useState(false);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [showPostComplaintPopup, setShowPostComplaintPopup] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [selectedComplaint, setSelectedComplaint] = useState(null);

    useEffect(() => {
        setSelectedComplaint(props.complaint);
    }, [props.complaint]);

    const handleClosePopup = () => {
        setShowComplaintPopup(false);
        setShowPostComplaintPopup(false);
        setShowCommentPopup(false);
    };

    return (
        <Card p="5px">
            {showComplaintPopup && selectedComplaint && (
                <div className="complaint-popup">
                    <ComplaintPopup
                        title={selectedComplaint.title}
                        description={selectedComplaint.description}
                        profilePicture={selectedComplaint.profilePicture}
                        client={selectedComplaint.client}
                        currentDate={selectedComplaint.currentDate}
                        onClose={handleClosePopup}
                    />
                </div>
            )}

            {showPostComplaintPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <PostComplaintPopup complaintId={props._id} onClose={handleClosePopup} />
                </div>
            )}

            {showCommentPopup && selectedComplaint && (
                <div className="post-complaint-popup">
                    <CommentComplaintPopup selectedComplaint={props} onClose={handleClosePopup} />
                </div>
            )}

            <ComplaintLightNFTContent
                title={props.title}
                description={props.description}
                client={props.client}
                currentDate={props.currentDate}
                complaint={props.complaint}
                like={like}
                onLike={() => setLike(!like)}
                handleClick={(complaint) => {
                    setSelectedComplaint(complaint);
                    setShowPostComplaintPopup(false);
                    setShowComplaintPopup(true);
                }}
                handlePostComplaintButtonClick={(complaint) => {
                    setSelectedComplaint(complaint);
                    setShowComplaintPopup(false);
                    setShowPostComplaintPopup(true);
                }}
                handleCommentButtonClick={(complaint) => {
                    setSelectedComplaint(complaint);
                    setShowPostComplaintPopup(false);
                    setShowCommentPopup(true);
                }}
            />
        </Card>
    );
}

// ... (Remaining code)
