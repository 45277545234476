import React from "react";
import { Icon } from "@chakra-ui/react";
import {
    MdBarChart,
    MdPerson,
    MdTableRows,
    MdHandyman,
    MdMessage,
    MdOutlineReceiptLong,
    MdOutlineHowToVote,
    MdManageAccounts, MdSpaceDashboard,
} from "react-icons/md";

// client Imports
import Home from "views/manager/dashboardManager/default"; // Updated path for manager dashboard
import editAccount from "views/manager/dashboardManager/account/editAccount"; // Updated path for manager dashboard
import newComplaints from "views/manager/buldings/complaints/newComplaints"; // Updated path for manager newComplaints
import overviewComplaint from "views/manager/buldings/complaints/overviewComplaint"; // Updated path for manager newComplaints overview
import Finance from "views/manager/buldings/finance"; // Updated path for manager finance
import Dashboard from "views/manager/dashboardManager/dashboard"; // Updated path for manager dashboard
import NewMessageForm from "views/manager/dashboardManager/messenger/newDiscution"; // Updated path for manager new discussion
import Messenger from "views/manager/dashboardManager/messenger/conversationOverview"; // Updated path for manager messenger
import historyInvoice from "../views/manager/buldings/invoice/historyInvoice";
import pollsOverview from "../views/manager/buldings/poll/pollsOverview";
import newPollsOverview from "../views/manager/buldings/poll/newPolls";
import addInvoiceNew from "../views/manager/buldings/invoice/addInvoice";
import invoicesOverview from "../views/manager/buldings/invoice/invoicesOverview";
import dashboardInvoice from "../views/manager/buldings/invoice/dashboardInvoice";
import BuldingsOverview from "../views/manager/buldings/bulding/buldingsOverview";
import mangerDashboardInvoice from "../views/manager/buldings/invoice/managerDashboardInvoice"; // Updated path for manager add invoice


const routesManager =  [
    {
        name: "Home",
        layout: "/manager",
        path: "/default",
        icon: <Icon as={MdTableRows} width='20px' height='20px' color='inherit'/>,
        component: Home,
    },
    {
        name: "editAccount",
        layout: "/managerTask",
        path: "/editAccount/:idUser",
        icon: <Icon as={MdTableRows} width='20px' height='20px' color='inherit'/>,
        component:editAccount,
    },
    {
        name: "Flux",
        layout: "/managerTask",
        path: "/overviewComplaint/:idBuilding",
        icon: <Icon as={MdHandyman} width='20px' height='20px' color='inherit'/>,
        component: overviewComplaint,
    },
    {
        name: "historyInvoice",
        layout: "/managerTask",
        path: "/historyInvoice",
        icon: <Icon as={MdHandyman} width='20px' height='20px' color='inherit'/>,
        component: historyInvoice,
    },
    {
        name: "Pool",
        layout: "/managerTask",
        path: "/poolDashBoard/:idBuilding",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
        component: pollsOverview,
    },
    {
        name: "Pool",
        layout: "/managerTask",
        path: "/newPool/:idBuilding",
        icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
        component: newPollsOverview,
    },
    {
        name: "Financials",
        layout: "/managerTask",
        path: "/data-tables/:idBuilding",
        icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit'/>,
        component: Finance,
    },
    {
        name: "Dashboard",
        layout: "/manager",
        path: "/dashboard",
        icon: <Icon as={MdSpaceDashboard} width='20px' height='20px' color='inherit'/>,
        component: Dashboard,
    },
    {
        name: "newComplaints",
        layout: "/managerTask",
        path: "/newComplaints/:idBuilding",
        icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit'/>,
        component: newComplaints,
    },
    {
        name: "New Invoice",
        layout: "/managerTask",
        path: "/addInvoiceNew/:idBuilding",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: addInvoiceNew,
    },

    {
        name: "Invoice",
        layout: "/managerTask",
        path: "/dashboardInvoice2",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: invoicesOverview,
    },
    {
        name: "Invoice",
        layout: "/managerTask",
        path: "/dashboardInvoice",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: dashboardInvoice,
    },
    {
        name: "Messenger",
        layout: "/managerTask",
        path: "/Messenger",
        icon: <Icon as={MdMessage} width='20px' height='20px' color='inherit'/>,
        component: Messenger,
    },
    {
        name: "Messenger",
        layout: "/managerTask",
        path: "/newDiscution",
        component: NewMessageForm,
    },
    {
        name: "Buldings",
        layout: "/managerTask",
        path: "/buldingsOverview",
        icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
        component: BuldingsOverview,

    },
    //currentUser && currentUser.isManager === "true" &&
    {
        name: "manager Invoice",
        layout: "/managerTask",
        path: "/mangerDashboardInvoice/:idBuilding",
        icon: <Icon as={MdOutlineReceiptLong} width='20px' height='20px' color='inherit'/>,
        component: mangerDashboardInvoice,

    },

];

export default routesManager;
