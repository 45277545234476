// Chakra imports
import {Icon, Box, Flex, Spacer, Text, SimpleGrid, Grid} from "@chakra-ui/react";
import {
    MdBarChart,
    MdPerson,
    MdTableRows,
    MdOutlineHowToVote,
    MdOutlineReceiptLong,
    MdCategory,
    MdManageAccounts
} from "react-icons/md";
import CategoriesOverview from "views/admin/managment/category/categoriesOverview"
import InvoicesOverview from "views/admin/invoice/invoicesOverview";
import UsersOverview from "views/admin/users/usersOverview"

import pollsOverview from "views/admin/poll/pollsOverview";

import Banner from "./components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import ManagmentNFT from "../../../../UIX/components/card/ManagmentNFT";
// Admin Imports
import React from "react";
import {useState} from "react";
import {Route, useHistory} from "react-router-dom";
import AuthService from "../../../services/authService";
import BuldingsOverview from "../../buldings/bulding/buldingsOverview";
import ResidentsOverview from "../../buldings/bulding/residentsOverview";

export default function UserReports() {
    const history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    const updatedRoutesAdmin = [
        {
            name: "Category",
            layout: "/adminTask",
            path: "/categoriesOverview",
            icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit"/>,
            component: CategoriesOverview,
        },
        {
            name: "Pool",
            layout: "/adminTask",
            path: "/poolDashBoard",
            icon: <Icon as={MdOutlineHowToVote} width="20px" height="20px" color="inherit"/>,
            component: pollsOverview,
        },
        {
            name: "Invoice",
            layout: "/adminTask",
            path: "/invoicesOverview",
            icon: (
                <Icon as={MdOutlineReceiptLong} width="20px" height="20px" color="inherit"/>
            ),
            component: InvoicesOverview,
        },
        {
            name: "User",
            layout: "/adminTask",
            path: "/usersOverview",
            icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
            component: UsersOverview,
        },
        {
            name: "Buldings",
            layout: "/adminTask",
            path: "/buldingsOverview",
            icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
            component: BuldingsOverview,
        },
        {
            name: "Residents",
            layout: "/adminTask",
            path: "/residentsOverview",
            icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit'/>,
            component: ResidentsOverview,
        },
        // residentsOverview Add more routes here if needed
    ];
    const [activeRoute, setActiveRoute] = useState(updatedRoutesAdmin[0]);

    const navigateToRoute = (path) => {
        history.push(path);
    };

    return (
        <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                <Flex flexDirection="column" gridArea={{ xl: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
                    <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                        <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                    </Box>
                    <SimpleGrid columns={{ base: 1, md: 5 }} gap="20px">

                    {updatedRoutesAdmin.map((route, index) => (
                            <ManagmentNFT
                                name={route.name}
                                icon={route.icon}
                                layout={route.layout}
                                path={route.path}
                            />

                    ))}
                    </SimpleGrid>
                </Flex>
                <Flex flexDirection="column" gridArea={{ xl: "1 / 2 / 2 / 3", "2xl": "1 / 2 / 2 / 3" }}>
                    {/* You can add any additional content for the second column here */}
                </Flex>
            </Grid>

            {/* Render Routes */}
            {updatedRoutesAdmin.map((route) => (
                <Route
                    key={route.path}
                    exact
                    path={route.layout + route.path}
                    component={route.component}
                />
            ))}
        </Box>
    );
}