import React, { useState, useEffect } from 'react';
import {getLocation} from "current-location-geo";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";


function Localisation(){
    const defaultCenter = {lat: -34.397, lng: 150.644}; // Default center if location is not available

    const [currentLocation, setCurrentLocation] = useState(defaultCenter);
    useEffect(() => {
        getLocation(function (err, position) {
            if (err) {
                console.error('Error:', err);
            } else {
                console.log('Latitude:', position.latitude);
                console.log('Longitude:', position.longitude);
                console.log('Address:', position.address);
            }
        });
    }, []);
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                () => {
                    // Handle error or set default location
                }
            );
        }
    }, []);
    const containerStyle = {
        width: '400px',
        height: '400px'
    };

    return (
        <>
            <LoadScript googleMapsApiKey='AIzaSyDfrnILnl2nGhvNGM68-ACmYVxb4cWDypo'>
                <div style={{ width: '250px', height: '250px', border: '1px solid #ccc', borderRadius: '4px' }}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={currentLocation}
                        zoom={15}
                    >
                        <Marker position={currentLocation} />
                    </GoogleMap>
                </div>

            </LoadScript>

        </>
    );

}

export default Localisation;
