import AuthService from "../../../services/authService";
import UserDataService from "../../../services/userService";
import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    Radio,
    RadioGroup,
    Checkbox,
    useColorModeValue,
    Select,
} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/toast";
import DefaultAuth from "layouts/auth/Default";
import Banner from "../components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import BuildingDataService from "../../../services/buildingService";

function Index() {
    const toast = useToast();
    const initialUser = {
        user_id: "",
        fullName: "",
        mail: "",
        username: "",
        password: "",
        profilePicture: "",
        isAdmin: false,
        isManager: false,
        building: "",
    };


    const [user, setUser] = useState(initialUser);
    const [profilePicture, setProfilePicture] = useState(null);
    const [picLoading, setPicLoading] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [isManager, setIsManager] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPictureUploaded, setIsPictureUploaded] = useState(false);
    const [selectBulding, setSelectBulding] = useState([]);
    const history = useHistory();
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const postDetails = (pics) => {
        setPicLoading(true);
        if (!pics) {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
            return;
        }

        if (pics.type === "image/jpeg" || pics.type === "image/png") {
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "piyushproj");
            fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
                method: "post",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    setProfilePicture(data.url.toString());
                    setPicLoading(false);
                    setIsPictureUploaded(true);

                })
                .catch((err) => {
                    console.log(err);
                    setPicLoading(false);
                });
        } else {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
        }
    };

    const retrieveBulding = async () => {
        try {
            const response = await BuildingDataService.getAll();
            if (response.data && Array.isArray(response.data.buildingsList)) {
                setBuildings(response.data.buildingsList);
            }
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };
    useEffect(() => {
        // Fetch buildings when the component mounts
        retrieveBulding();
    }, []);

    async function handleRegister(event) {
        console.log(user)
        event.preventDefault();
        const userDoc = {
            user_id: user.user_id,
            fullName: user.fullName,
            mail: user.mail,
            username: user.username,
            password: user.password,
            profilePicture: profilePicture,
            isAdmin: isAdmin,
            isManager: isManager,
            building: selectBulding,
        };

        try {
            const response = await UserDataService.createUser(userDoc);
            if (response.data.status === "success") {
                // Registration successful
                setUser(response.data);
                history.goBack(); // Redirect to the login page
            } else {
                // Registration failed
                alert("Registration failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("An error occurred during registration. Please try again later.");
        }
    }

    return (
        <div>
            <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
                <Box mb="70px">
                    <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                </Box>
                <Flex justify="center" align="center" height="100vh">
                    <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                        <Heading as="h2" size="lg" mb="4">
                            Register
                        </Heading>
                        <form onSubmit={handleRegister}>
                            <FormControl mt="4">
                                <FormLabel>User ID</FormLabel>
                                <Input
                                    value={user.user_id}
                                    onChange={(e) => setUser({...user, user_id: e.target.value})}
                                />
                            </FormControl>

                            <FormControl mt="4">
                                <FormLabel>Full Name</FormLabel>
                                <Input
                                    value={user.fullName}
                                    onChange={(e) => setUser({...user, fullName: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={user.mail}
                                    onChange={(e) => setUser({...user, mail: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Username</FormLabel>
                                <Input
                                    value={user.username}
                                    onChange={(e) => setUser({...user, username: e.target.value})}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    value={user.password}
                                    onChange={(e) => setUser({...user, password: e.target.value})}
                                />
                            </FormControl>


                            <FormControl mt="4">
                                <FormLabel>Profile Picture</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    display="none"
                                    id="file-input"
                                    onChange={(e) => postDetails(e.target.files[0])}
                                />
                                <label htmlFor="file-input">
                                    <Button
                                        as="span"
                                        bg="blue.500"
                                        color="white"
                                        borderRadius="md"
                                        _hover={{bg: "blue.600"}}
                                        cursor="pointer"
                                    >
                                        Select File
                                    </Button>
                                </label>
                                {isPictureUploaded && (
                                    <Checkbox mt="2" isChecked={true} isDisabled>
                                        Picture uploaded
                                    </Checkbox>
                                )}
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Admin Status</FormLabel>
                                <RadioGroup value={isAdmin.toString()} onChange={(e) => setIsAdmin(e === 'true')}>
                                    <Radio value="true">Admin</Radio>
                                    <Radio value="false">Not Admin</Radio>
                                </RadioGroup>
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Manager Status</FormLabel>
                                <RadioGroup value={isManager.toString()} onChange={(e) => setIsManager(e === 'true')}>
                                    <Radio value="true">Manager</Radio>
                                    <Radio value="false">Not Manager</Radio>
                                </RadioGroup>
                            </FormControl>
                            {isManager && (
                                <FormControl mt="4">
                                    <FormLabel>Building</FormLabel>
                                    <Select
                                        required
                                        placeholder="Select a building"
                                        name="building"
                                        value={selectBulding ? selectBulding._id : ''}
                                        onChange={(e) => {
                                            const selectedBuildingId = e.target.value;
                                            const selectedBuilding = buildings.find(building => building._id === selectedBuildingId);
                                            setSelectBulding(selectedBuilding);
                                        }}
                                    >
                                        {buildings.map((building) => (
                                            <option key={building._id} value={building._id}>
                                                {building.address}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}


                            <FormControl mt="4">
                                <Button variant="brand" w="100%" size="lg" type="submit">
                                    Register
                                </Button>
                            </FormControl>


                            <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%"
                                  mt="0px">
                                <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                                    Already have an account?{' '}
                                    <NavLink to="/sign-in">
                                        <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                            Log in
                                        </Text>
                                    </NavLink>
                                </Text>
                            </Flex>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </div>
    );
}

export default Index;
