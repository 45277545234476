import instance from "./http-common";
import authHeader from './auth-header';

class UserDataService {
    getAllToken(currentUser,page = 0) {
        return instance.get(`user?page=${page}`,currentUser);
    }
    getAll(currentUser,page = 0) {
        return instance.get(`user?page=${page}`);
    }
    get(id) {
        return instance.get(`user?id=${id}`);
    }
    find(query, by, page = 0) {
        return instance.get(`user?${by}=${query}&page=${page}`);
    }

    signupUser(data) {
  //      console.log(data)
        return instance.post("/signup", data);
    }

    createUser(data) {
    //    console.log(data)
        return instance.post("/user", data);
    }

    deleteUser(userId) {
        return instance.delete(`/user?id=${userId}`);
    }


    updateUser(data) {
      //  console.log("updateUser",data)
        return instance.put("/user", data);
    }

    getUserById(idUser) {
        return instance.get(`/user/${idUser}`);
    }

    getUserByName(username) {
     //   console.log(username);
        return instance.get(`/userByName/${username}`);
    }
    updateUserByAttribute(userId, attributeName, newValue) {
        const data = {
            userId: userId,
            attributeName: attributeName,
            newValue: newValue
        }
 //       console.log(data)
        return instance.post("/updateUserByAttribute", data);
    }

    getUserByAttribute(data) {
   //     console.log(data)
        return instance.post('/getUserByAttribute', data);
    }
}

export default new UserDataService();
