// Chakra imports
import {
    Box,
    Text,
    SimpleGrid,
    Grid, Icon, Flex, useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from 'react';
import PieCard from "views/admin/default/components/PieCard";
import TotalSpent from "views/admin/default/components/TotalSpent";
import AuthService from "../../../services/authService";
import InvoicesOverview from "../../buldings/invoice/invoicesOverview/Light";
import Complaints from "../../buldings/complaints/overviewComplaint/Light";
import {MdAddCircle} from "react-icons/md";



const UserReports = () => {
    const currentUser = AuthService.getCurrentUser();
    const [activeComponent, setActiveComponent] = useState(null);

    const handleBoxClick = (section) => {
        // Handle click logic here
        if (activeComponent === section) {
            // If the same box is clicked again, close the component
            setActiveComponent(null);
        } else {
            // Open the clicked component
            console.log(`Clicked on ${section}`);
            setActiveComponent(section);
        }
    };

    const renderBox = (title, component) => (
        <Box
            key={title}
            onClick={() => handleBoxClick(title)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            borderRadius="lg"
            p="6"
            bg={activeComponent === title ? 'midnightblue' : 'blue.900'}
            color="white"
            cursor="pointer"
            transition="all 0.3s"X
        >
            <Text as="h1" fontSize="2xl" fontWeight="bold" color={activeComponent === title ? 'teal.300' : 'white'}>
                {title}
            </Text>
            {activeComponent === title && (
                <Box mt="4" bg="white" borderRadius="xl" p="4" width="100%" height="auto">
                    {component}
                </Box>
            )}
        </Box>
    );

    const components = {
        InvoicesOverview: <InvoicesOverview />,
        Complaints: <Complaints />,
        'Total Spent': <TotalSpent />,
        'Pie Chart': <PieCard />,
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={{ base: "20px", xl: "20px" }}>
                {Object.keys(components).map((title) => renderBox(title, components[title]))}
                {/* Replace 'Banner' with your actual implementation */}
            </SimpleGrid>
        </Box>
    );
};


export default UserReports;
