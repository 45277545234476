import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
    Grid,
    SimpleGrid,
    Input,
    Button,
} from "@chakra-ui/react";
import UserDataService from "../../../services/userService"; // Import the UserDataService with the correct path
import Banner from "../../../admin/users/components/Banner";
import UserNFT from "../../../../UIX/components/card/UserNFT";
import banner from "../../../../UIX/assets/img/auth/banner.png";

const UserPopup = ({ fullName, mail, username, isAdmin, profilePicture, onClose }) => {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{fullName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
                        <Image
                            src={profilePicture}
                            w={{ base: "100%", "3xl": "100%" }}
                            h={{ base: "100%", "3xl": "100%" }}
                            borderRadius="20px"
                        />
                    </Box>
                    <Text>
                        <strong>Username:</strong> {username}
                    </Text>
                    <Text>
                        <strong>Email:</strong> {mail}
                    </Text>
                    {isAdmin && <Text color="red">Admin</Text>}
                    {/* Add any other user details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

function UsersOverview() {
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        // Fetch users when the component mounts
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await UserDataService.getAll();
            console.log(response);
            if (response.data && Array.isArray(response.data.users)) {
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{ base: "20px", xl: "20px" }}
                >
                    {/* Main Fields */}
                    <Flex flexDirection="column" gridArea={{ xl: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{ base: 1, md: 5 }} gap="20px">
                            {users.map((user) => (
                                <div key={user.user_id} onClick={() => handleUserClick(user)}>
                                    <UserNFT
                                        _id={user._id}
                                        user_id={user.user_id}
                                        fullName={user.fullName}
                                        mail={user.mail}
                                        username={user.username}
                                        password={user.password}
                                        profilePicture={user.profilePicture}
                                        apartment={user.apartment}
                                        isAdmin={user.isAdmin}
                                        isManager={user.isManager}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                    <Flex flexDirection="column" gridArea={{ xl: "1 / 2 / 2 / 3", "2xl": "1 / 2 / 2 / 3" }}>
                        {/* You can add any additional content for the second column here */}
                    </Flex>
                </Grid>

                {/* Popup for user details */}
                {showPopup && selectedUser && (
                    <div className="user-popup">
                        <UserPopup
                            user_id={selectedUser.user_id}
                            fullName={selectedUser.fullName}
                            mail={selectedUser.mail}
                            username={selectedUser.username}
                            password={selectedUser.password}
                            profilePicture={selectedUser.profilePicture}
                            apartment={selectedUser.apartment}
                            isAdmin={selectedUser.isAdmin}
                            isManager={selectedUser.isManager}
                            onClose={handleClosePopup}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
}

export default UsersOverview;
