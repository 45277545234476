import React, {useState, useEffect} from "react";
import PollService from "../../../../services/pollService"; // Update the import path based on your project structure
import PollsNFT from "../../../../../UIX/components/card/PollsNFT";
import Banner from "../../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import {
    SimpleGrid,
    Text,
    Box,
    Button,
    Flex,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Grid,
    Td,
    TableCaption,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import AuthService from "../../../../services/authService";
import {useHistory, useParams} from "react-router-dom";

function SearchBar({searchTerm, idBuilding,onChange, onClick}) {
    console.log(idBuilding)
    const history = useHistory();
    const handleCreateCatagory = () => {
        console.log(1)
        // Push a new entry onto the history stack and redirect to the desired location
        history.push(`/managerTask/newPool/${idBuilding}`);
    };
    const handleHistoryInvoice = () => {
        history.push(`/managerTask/historyPool/${idBuilding}`);
    };
    return (
        <Flex
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
            bg="gray.200"
            p="10px"
            borderRadius="8px"
        >
            <Input
                variant="filled"
                fontSize="sm"
                bg="white"
                color="gray.800"
                fontWeight="500"
                _placeholder={{color: "gray.400", fontSize: "14px"}}
                borderRadius="4px"
                placeholder="Search by Category, Date, or Month..."
                value={searchTerm}
                onChange={onChange}
                mr="2"
            />
            <Button colorScheme="teal" variant="solid" size="sm" onClick={onClick}>
                Search
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleCreateCatagory}>
                New
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleHistoryInvoice}>
                Hystory
            </Button>
        </Flex>
    );
}

function PollOption({option, onVote, UserInArray}) {
    const [isChecked, setIsChecked] = useState(false);

    const handleVote = () => {
        setIsChecked(!isChecked);
        onVote(option);
    };

    return (
        <Td>
            {UserInArray ? null : (
                <input type="checkbox" checked={isChecked} onChange={handleVote}/>
            )}
            {option.option}
        </Td>
    );
}

function PollPopup({pollId, question, options, user, onClose}) {
    const currentUser = AuthService.getCurrentUser();
    const [isVote, setIsVote] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleChoice = async (votedOption) => {
        const updatedOptions = options.map((option) => {
            if (option.option === votedOption.option) {
                return {...option, count: String(Number(option.count) + 1)};
            }
            return option;
        });
        setIsVote(updatedOptions);

    };
    const handleSumbitVote = async () => {

        const pollDoc = {
            pollId: pollId,
            Subject: question,
            updatedOptions: isVote,
            currentUser: currentUser

        };
        try {
            // Call the API to handle the vote using 'updatedOptions'
            const response = await PollService.vote(pollDoc);
            if (response.data.status === "success") {
                alert('Vote recorded');
                window.location.reload();
                // If the vote is successfully recorded on the server, you may choose to update the state here if necessary.
            } else {
                alert("Error recording vote");
                // Handle error scenario if the vote is not recorded successfully on the server.
            }
        } catch (error) {
            console.error("Vote recording error:", error);
            // Handle any errors that occurred during the API call.
        }
        setIsClicked((prevIsClicked) => !prevIsClicked);
        onClose();
        // After closing the pop-up, update the state 'VOTE' with the new vote data.
        // For example, if you have a function to fetch updated vote data, you can call it here.
    };
    let isUserInArray = false;
    if (user !== undefined) {
        isUserInArray = user.some(users => users.id === currentUser.id);
    }


    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{question}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Table variant="striped" colorScheme="teal">
                        <Tbody>
                            {options.map((option, i) => (
                                <Tr key={i}>
                                    <PollOption option={option} onVote={handleChoice} UserInArray={isUserInArray}/>
                                    <Td isNumeric>{option.count}</Td>
                                </Tr>
                            ))}
                            <Tr>
                                <Td

                                    onClick={isUserInArray ? null : handleSumbitVote}
                                    style={{
                                        background: isClicked ? 'blue' : 'transparent',
                                        borderRadius: '8px',
                                        cursor: isClicked ? 'pointer' : 'not-allowed', // Change cursor style based on isClicked.
                                        opacity: isUserInArray ? 1 : 0.5, // Adjust opacity based on user presence in array.
                                    }}
                                >
                                    {isUserInArray ? 'Cannot Votes' : 'Submit Vote'}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function PollsOverview(props) {
    const {idBuilding} = useParams();
    const [polls, setPolls] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState(null);

    useEffect(() => {
        // Fetch polls when the component mounts
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await PollService.getAll();
            if (response.data && Array.isArray(response.data.polls)) {

                const filteredInvoices = response.data.polls.filter(
                    (poll) => poll.idBuilding === idBuilding
                );
                setPolls(filteredInvoices);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSearchClick = () => {
        const result = polls.filter(
            (invoice) =>
                invoice.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.dateSend.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.datePayed.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setPolls(result);
    };
    const handlePollClick = (poll) => {
        setSelectedPoll(poll);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding}/>
                        <SearchBar searchTerm={searchTerm}  idBuilding={idBuilding} onChange={handleSearchChange} onClick={handleSearchClick}
                                  />
                        {polls.length > 0 ? (
                            <Table variant="simple" colorScheme="green" size="sm">
                                <TableCaption fontWeight="bold">Poll Details</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Question</Th>
                                        <Th>Category</Th>
                                        <Th>Options</Th>
                                        {/* Add more headers as needed */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {polls.map((poll, i) => (
                                        <Tr key={i} _hover={{ bg: "green.100" }} onClick={() => handlePollClick(poll)}>
                                            <Td>
                                                <Text fontWeight="bold" color="teal.500">{poll.Subject}</Text>
                                            </Td>
                                            <Td>
                                                <Text fontWeight="semibold" color="green.700">
                                                    {poll.category}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text color="gray.600">
                                                    {poll.options.map((option) => `${option.option}:${option.count}`).join(', ')}
                                               </Text>
                                            </Td>
                                            {/* Add more cells as needed */}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text fontSize="lg" color="gray.600">
                                No matching polls found.
                            </Text>
                        )}
                        {/*
                        <SimpleGrid columns={{base: 1, md: 5}} gap="20px">
                            {polls.map((poll, i) => (
                                <div key={i} onClick={() => handlePollClick(poll)}>
                                    <PollsNFT
                                        subject={poll.Subject}
                                        idBuilding={idBuilding}
                                     />
                                </div>
                            ))}
                        </SimpleGrid>
                            */}
                    </Flex>
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3"}}
                    >
                        {/* Add any other components or content related to PollsOverview */}
                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedPoll && (
                <div className="poll-popup">
                    {/* Your Poll Popup Component */}
                    <PollPopup
                        pollId={selectedPoll._id}
                        question={selectedPoll.Subject}
                        options={selectedPoll.options}
                        user={selectedPoll.user}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default PollsOverview;
