// Chakra imports
import {
    AvatarGroup,
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "UIX/components/card/Card.js";
// Assets
import React, {useState} from "react";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import AuthService from "../../../views/services/authService";

export default function ComplaintNFT(props) {
    const currentUser = AuthService.getCurrentUser();
    let clientPic = currentUser.profilePicture
    const {
        title,
        description,
        client,
        currentDate,
    } = props;
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");
    return (
        <Card p="20px">
            <Flex direction={{base: "column"}} justify="center">
                <Box mb={{base: "20px", "2xl": "20px"}} position="relative">
                    <Button
                        position="absolute"
                        bg="white"
                        _hover={{bg: "whiteAlpha.900"}}
                        _active={{bg: "white"}}
                        _focus={{bg: "white"}}
                        p="0px !important"
                        top="14px"
                        right="14px"
                        borderRadius="50%"
                        minW="36px"
                        h="36px"
                        onClick={() => {
                            setLike(!like);
                        }}
                    >
                        <Icon
                            transition="0.2s linear"
                            w="20px"
                            h="20px"
                            as={like ? IoHeart : IoHeartOutline}
                            color="brand.500"
                        />
                    </Button>
                </Box>
                <Flex flexDirection="column" justify="space-between" h="100%">
                    <Flex
                        justify="space-between"
                        direction={{
                            base: "row",
                            md: "column",
                            lg: "row",
                            xl: "column",
                            "2xl": "row",
                        }}
                        mb="auto"
                    >
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "xl",
                                    md: "lg",
                                    lg: "lg",
                                    xl: "lg",
                                    "2xl": "md",
                                    "3xl": "lg",
                                }}
                                mb="5px"
                                fontWeight="bold"
                                me="14px"
                            >
                                {title}
                            </Text>
                            <Text
                                color="secondaryGray.600"
                                fontSize={{
                                    base: "sm",
                                }}
                                fontWeight="400"
                                me="14px"
                            >
                                {client} - {currentDate}
                            </Text>
                        </Flex>
                        <AvatarGroup
                            max={3}
                            color={textColorBid}
                            size="sm"
                            mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                                "2xl": "0px",
                            }}
                            fontSize="12px"
                        >

                        </AvatarGroup>
                    </Flex>

                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                        {/* Left column containing the description */}
                        <Text mt="25px" color={textColor}>
                            {description}
                        </Text>
                        <Avatar src={clientPic}/>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}



/** <Link
 href={download}
 mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                                "2xl": "0px",
                            }}
 >
 <Button
 variant="darkBrand"
 color="white"
 fontSize="sm"
 fontWeight="500"
 borderRadius="70px"
 px="24px"
 py="5px"
 >
 Delete
 </Button>
 </Link> **/