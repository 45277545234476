import React, {useState, useEffect} from "react";
import {
    Stack,
    Box,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid,
    SimpleGrid
} from "@chakra-ui/react";
import PollService from "../../../../services/pollService"; // Update the import path based on your project structure
import PollsNFT from "../../../../../UIX/components/card/PollsNFT";
import {Table, Tbody, Tr, Td} from '@chakra-ui/react';
import AuthService from "../../../../services/authService";


function PollOption({option, onVote, UserInArray}) {
    const [isChecked, setIsChecked] = useState(false);
    const handleVote = () => {
        setIsChecked(!isChecked);
        onVote(option);
    };

    return (
        <Td>
            {UserInArray ? null : (
                <input type="checkbox" checked={isChecked} onChange={handleVote}/>
            )}
            {option.option}
        </Td>
    );
}

function PollPopup({ question, options, onClose }) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{question}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant="striped" colorScheme="teal">
                        <Tbody>
                            {options.map((option, i) => (
                                <Tr key={i}>
                                    <Td>{option.option}</Td>
                                    <Td isNumeric>{option.count}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function PollsOverview(props) {
    const idBuilding = props.idBuilding
    console.log(idBuilding)
    const [polls, setPolls] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState(null);

    useEffect(() => {
        // Fetch polls when the component mounts
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await PollService.getAll();
            if (response.data && Array.isArray(response.data.polls)) {
                const filteredPolls = response.data.polls.filter(
                    (poll) => poll.idBuilding === idBuilding
                );
                setPolls(filteredPolls);
            }
        } catch (error) {
            console.error("Error fetching polls:", error);
        }
    };

    const handlePollClick = (poll) => {
        console.log(poll)
        setSelectedPoll(poll);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    justifyContent="space-between"
                    gap={{ base: "20px", xl: "20px" }}
                >
                    <Stack spacing={6}> {/* Increase spacing for better readability */}
                        {polls.map((poll, i) => (
                            <div key={i} onClick={() => handlePollClick(poll)}>
                                <PollsNFT
                                    subject={poll.Subject}
                                />
                            </div>
                        ))}
                    </Stack>

                    {/* Add any other components or content related to PollsOverview */}
                    <Flex
                        direction="column"
                        mt={{ base: "20px", lg: "0" }} // Adjust margin top for responsiveness
                    >
                        {/* Your content for the second column */}
                    </Flex>
                </Flex>
            </Box>

            {showPopup && selectedPoll && (
                <div className="poll-popup">
                    {/* Your Poll Popup Component */}
                    <PollPopup
                        question={selectedPoll.Subject}
                        options={selectedPoll.options}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );

}

export default PollsOverview;
