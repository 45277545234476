import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Banner from "views/admin/complaint/addComplaints/components/Banner";
import AuthService from "../../../services/authService";
import ComplaintDataService from "../../../services/complaintService";
import banner from "../../../../UIX/assets/img/auth/banner.png";

import {
    Box,
    Button,
    Flex,
    Grid,
    Text,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useColorModeValue,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";

function Complaints() {
    const currentUser = AuthService.getCurrentUser();
    const initialComplaint = {
        title: "",
        description: "",
        profilePicture: "",
        username: currentUser.username,
        currentDate: "",
    };
    const toast = useToast();
    const [profilePicture, setPic] = useState();
    const [picLoading, setPicLoading] = useState(false);
    const [complaint, setComplaint] = useState(initialComplaint);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const history = useHistory();
    const textColor = useColorModeValue("navy.700", "white");

    const postDetails = (pics) => {
        setPicLoading(true);
        if (pics === undefined) {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
        }
        if (pics.type === "image/jpeg" || pics.type === "image/png") {
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "piyushproj");
            fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
                method: "post",
                body: data,
            })
                .then((res) => res.json())
                .then((data) => {
                    setPic(data.url.toString());
                    setPicLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPicLoading(false);
                });
        }
        else {
            toast({
                title: "Please Select an Image!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setPicLoading(false);
            return;
        }
    };
    async function handleComplaintPost(event) {
        console.log(currentUser)
        let client = currentUser.username;
        console.log(client)
        event.preventDefault();
        const complaintDoc = {
            title,
            description,
            profilePicture,
            client,
            currentDate,
        };
        try {
            console.log(complaintDoc)
            const response = await ComplaintDataService.createComplaint(complaintDoc);
            if (response.data.status === "success") {
                // Registration successful
                setComplaint(response.data);
                history.replace("/admin/overviewComplaint"); // Redirect to the login page
            } else {
                // Registration failed
                alert("Registration failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("An error occurred during registration. Please try again later.");
        }
    }

    return (
        <div>
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
                <Box mb="5px">
                    {/* Add a margin to create space between Banner and Form */}
                    {/* You may pass the "banner" prop based on your requirements */}
                    <Banner gridArea="1 / 1 / 2 / 2" banner={banner} />
                </Box>
                <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg" mx="auto">
                    <Heading as="h2" size="lg" mb="4" textAlign="center">
                        Add Complaint
                    </Heading>
                    <form onSubmit={handleComplaintPost}>
                        <FormControl mt="4">
                            <FormLabel>Title:</FormLabel>
                            <Input
                                type="text"
                                value={complaint.title}
                                onChange={(e) => setComplaint({ ...complaint, title: e.target.value })}
                            />
                        </FormControl>

                        <FormControl mt="4">
                            <FormLabel>Description:</FormLabel>
                            <Input
                                type="text"
                                value={complaint.description}
                                onChange={(e) => setComplaint({ ...complaint, description: e.target.value })}
                            />
                        </FormControl>

                        <FormControl id="pic" mt={4}>
                            <FormLabel>Upload your Picture</FormLabel>
                            <Input type="file" p={1.5} accept="image/*" onChange={(e) => postDetails(e.target.files[0])} />
                        </FormControl>

                        <Button type="submit" mt="4" colorScheme="blue" width="100%">
                            Add Complaint
                        </Button>
                    </form>
                </Box>
            </Box>
        </div>
    );

}

export default Complaints;
