import http from "./http-common";
import instance from "./http-common";

class PollDataService {
    getAll(page = 0) {
        return http.get(`polls?page=${page}`);
    }

    get(id) {
        return http.get(`polls/${id}`);
    }

    getNames() {
        return instance.get(`/pollNames`);
    }

    find(query, by = "name") {
        return http.get(`polls?${by}=${query}`);
    }

    create(data) {
        console.log(data)
        return http.post("/polls", data);
    }

    delete(id) {
        return http.delete(`/polls/${id}`);
    }

    deleteAll() {
        return http.delete('/polls/deleteAll');
    }

    vote(data) {
        return http.post("/polls/vote", data);
    }

    getPollsByUserId(userId) {
        return http.get(`/polls/admin?userId=${userId}`);
    }

    addOption(data) {
        return http.post("/polls/addOption", data);
    }
}

export default new PollDataService();
