// Chakra imports
import { Flex, Text, Box, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalBody, Icon, useDisclosure } from "@chakra-ui/react";

// Custom components
import Card from "UIX/components/card/Card.js";
import IconBox from "UIX/components/icons/IconBox";
import Menu from "UIX/components/menu/BuldingManagerMenu";

import React, {useEffect, useState} from "react";
import { MdOutlineAccountBalance, MdClose } from "react-icons/md";
import AuthService from "../../../../services/authService";

import BuldingManagerNFT from "UIX/components/card/BuldingManagerNFT"; // Update the import path for BuildingCard


export default function Banner({ buildings }) {
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const boxColor = "gray.100";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const handleBuildingClick = (building) => {
        setSelectedBuilding(building);
        onOpen();
    };

    return (
        <Card mb={{ base: "0px", lg: "20px" }} align="center">
            <Flex w="100%" justify="left" py={4}>
                <Text fontSize="xl" fontWeight="bold">
                    Building
                </Text>
                {/* Assuming there is a Menu component */}
            </Flex>
            {buildings.map((building, index) => (
                <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    marginBottom="10px"
                    cursor="pointer"
                    onClick={() => handleBuildingClick(building)}
                    _hover={{
                        transform: "scale(1.1)", // Increase the scale value for a bigger effect
                    }}
                >
                    <Box>
                        {/* Assuming there is an IconBox component */}
                        <IconBox
                            mx="auto"
                            h="30px" // Adjust the height as needed
                            w="30px" // Adjust the width as needed
                            icon={<MdOutlineAccountBalance color={brandColor} h="16px" w="16px" />}
                            bg={boxColor}
                        />
                    </Box>
                    <Box ml="5px">
                        {/* Adjust margin as needed */}
                        <Text color={textColorSecondary} fontSize="md">
                            {building.address ? `${building.address} : ${building.enter} ` : "N/A"}
                        </Text>
                    </Box>
                </Box>
            ))}
            {/* Modal for BuldingManagerNFT */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Icon as={MdClose} onClick={onClose} position="absolute" top="0" right="0" boxSize={6} cursor="pointer" />
                        {selectedBuilding && (
                            <BuldingManagerNFT
                                _id={selectedBuilding._id}
                                address={selectedBuilding.address}
                                enter={selectedBuilding.enter}
                                numberOfApartment={selectedBuilding.numberOfApartment}
                                manager={selectedBuilding.manager}
                                apartments={selectedBuilding.apartments}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Card>
    );
}