import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid,
    SimpleGrid, Image,
} from "@chakra-ui/react";

import InvoiceDataService from "../../../../services/invoiceService";
import Banner from "../../components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import InvoicePaymentNFT from "../../../../../UIX/components/card/InvoicePaymentNFT";
import AuthService from "../../../../services/authService";



function InvoicePopup({name, category, amount, dateSend, datePayed, onClose}) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{name}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Text>
                        <strong>Category:</strong> {category}
                    </Text>
                    <Text>
                        <strong>Amount:</strong> {amount}
                    </Text>
                    <Text>
                        <strong>Send :</strong> {dateSend}
                    </Text>
                    <Text>
                        <strong>Payed:</strong> {datePayed}
                    </Text>
                    {/* Add any other invoice details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

let dictQuantity = [];

function InvoicesOverview() {
    const history = useHistory();
    const currentUser = AuthService.getCurrentUser();
    const [content, setContent] = useState("");
    const [complaints, setComplaints] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const ByClient = (complaints) => {
        let count = 0;
        if (dictQuantity.length > 0) {
            dictQuantity = [];
        }
        complaints.map((complaint, i) => {
            if (complaint.name === currentUser.fullName && complaint.datePayed === '') {
                count = count + 1;
                dictQuantity[count] = complaint;
            }
        });
        console.log(dictQuantity);
        setComplaints(dictQuantity);
        return dictQuantity;
    };

    useEffect(() => {
        // Fetch invoices when the component mounts
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            if (response.data && Array.isArray(response.data.invoices)) {
                setInvoices(ByClient(response.data.invoices));
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };
    const handleInvoiceClick = (invoice) => {
        setSelectedInvoice(invoice);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleAddInvoiceClick = () => {
        console.log("handleAddInvoiceClick");
        history.push("/adminTask/addInvoice"); // Redirect to the "AddInvoice" page
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{base: 1, md: 3}} gap="20px">
                            {invoices.map((invoice, i) => (
                                <div key={i} onClick={() => handleInvoiceClick(invoice)}>
                                    <InvoicePaymentNFT
                                        id={invoice._id}
                                        name={invoice.name}
                                        category={invoice.category}
                                        amount={invoice.amount}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedInvoice && (
                <div className="invoice-popup">
                    {/* Your Invoice Popup Component */}
                    <InvoicePopup
                        name={selectedInvoice.name}
                        category={selectedInvoice.category}
                        amount={selectedInvoice.amount}
                        dateSend={selectedInvoice.dateSend}
                        datePayed={selectedInvoice.datePayed}

                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default InvoicesOverview;
