import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Grid,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Select,
} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/toast";
import CategoryDataService from "../../../services/categoryService";
import Banner from "../../../admin/invoice/components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import CategoryNFT from "../../../../UIX/components/card/CategoryNFT";
import UserDataService from "../../../services/userService";
import InvoiceDataService from "../../../services/invoiceService";
import AuthService from "../../../services/authService";

function AddInvoicePopup({nameCategory, onClose}) {
    const [users, setUsers] = useState([]);
    const [currentUserManager , setCurrentUserManager] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const toast = useToast();

    useEffect(() => {
        retrieveUsers();
    }, []);

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(async (response) => {
                const fetchedUsers = response.data.users;
                const currentUserManager = await UserDataService.getUserByName(
                    currentUser.username
                );
                setCurrentUserManager(currentUserManager.data)
                const usersWithSameBuilding = fetchedUsers.filter((user) => {
                    return (
                        user.building !== null &&
                        user.building._id == currentUserManager.data.building._id
                    );
                });
                setUsers(usersWithSameBuilding);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const initialInvoiceData = {
        category: nameCategory,
        amount: "",
        user: "",
        building: "",
    };

    const [invoiceData, setInvoiceData] = useState(initialInvoiceData);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setInvoiceData({...invoiceData, [name]: value});
    };

    const handleInvoiceAdd = async (event) => {
        event.preventDefault();
        if (!invoiceData.amount || !invoiceData.user) {
            toast({
                title: "Please fill in all fields!",
                status: "warning",
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
        }

        try {
            const userDoc = {
                ...invoiceData,
                building: currentUserManager.building, // Use the current user's building
            };

            const response = await InvoiceDataService.postInvoice(userDoc);
            if (response.data.status === "success") {
                // Invoice addition successful
                onClose();
            } else {
                alert("ERROR");
            }
        } catch (error) {
            console.error("Invoice addition error:", error);
        }
    };

    const handleInvoiceAddAllUser = async (event) => {


        /***              const updatedInvoice = {
                    ...invoice,
                    building: currentUserManager.data.building,
                };
         setInvoice(updatedInvoice)***/



        event.preventDefault();
        console.log(currentUserManager.data)
        try {
            // Create a separate invoice for each user
            const invoices = users.map((user) => ({
                ...invoiceData,
                user,
                building: currentUserManager.building, // Use the current user's building
            }));

            // Post each invoice to the server
            const responseArray = await Promise.all(
                invoices.map((userDoc) => InvoiceDataService.postInvoice(userDoc))
            );

            // Check if all the invoices were posted successfully
            const allSuccess = responseArray.every(
                (response) => response.data.status === "success"
            );

            if (allSuccess) {
                // All invoices added successfully
                onClose();
            } else {
                alert("ERROR: Some invoices could not be added.");
            }
        } catch (error) {
            console.error("Invoice addition error:", error);
        }
    };

    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{nameCategory}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <form onSubmit={handleInvoiceAdd}>
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Input type="text" name="category" value={nameCategory} readOnly/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Amount</FormLabel>
                            <Input
                                type="number"
                                name="amount"
                                value={invoiceData.amount}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Select User:</FormLabel>
                            <Select
                                name="selectedUser"
                                value={invoiceData.selectedUser}
                                onChange={handleInputChange}
                            >
                                <option value="">Select User</option>
                                {users.map((user) => (
                                    <option key={user._id} value={user._id}>
                                        {user.username}
                                    </option>
                                ))}
                            </Select>
                            {invoiceData.selectedUser && (
                                <p>
                                    Selected User:{" "}
                                    {users.find((user) => user._id === invoiceData.selectedUser)
                                        ?.username}
                                </p>
                            )}
                        </FormControl>
                        <Button type="submit">Add Invoice</Button>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={handleInvoiceAddAllUser}>Send to all User</Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function DashboardInvoice() {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        // Fetch categories when the component mounts
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await CategoryDataService.getAll();
            if (response.data && Array.isArray(response.data.categories)) {
                setCategories(response.data.categories);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleConfigurationClick = () => {
        console.log("handleConfigurationClick");
        history.push("/adminTask/addCategory"); // Redirect to the "Configuration" page
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px">
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <SimpleGrid columns={{base: 1, md: 3}} gap="20px">
                            {categories.map((category, i) => (
                                <div key={i} onClick={() => handleCategoryClick(category)}>
                                    <CategoryNFT
                                        name={category.name}
                                        frequency={category.frequency}
                                        profilePicture={category.profilePicture}
                                    />
                                </div>
                            ))}
                        </SimpleGrid>
                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedCategory && (
                <div className="category-popup">
                    <AddInvoicePopup
                        nameCategory={selectedCategory.name}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default DashboardInvoice;
