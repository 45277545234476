import BuildingDataService from "../../../../services/buildingService"; // Import BuildingDataService instead of UserDataService
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom"; // Removed NavLink since it's not needed in this context
import {
    Text,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input, Select,
    useColorModeValue,
} from "@chakra-ui/react";


import {useToast} from "@chakra-ui/toast";
import Banner from "../../../users/components/Banner";
import banner from "../../../../../UIX/assets/img/auth/banner.png";
import UserNFT from "../../../../../UIX/components/card/UserNFT";
import UserDataService from "../../../../services/userService";
import AuthService from "../../../../services/authService";


function BuildingRegister() { // Rename the function to BuildingRegister
    const toast = useToast();
    const initialBuilding = {
        address: "",
        enter: "",
        numberOfApartment: "",
        manager: "",
        residents: [],
    };
    const [building, setBuilding] = useState(initialBuilding); // Use building state instead of user state
    const [address, setAddress] = useState(""); // Rename username and other fields
    const [enter, setEnter] = useState("");
    const [numberOfApartment, setNumberOfApartment] = useState("");
    const [manager, setManager] = useState("");
    const [residents, setResidents] = useState([]);
    const history = useHistory();
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [users, setUsers] = useState([]);
    const currentUser = AuthService.getCurrentUser();

    async function handleRegister(event) {
        event.preventDefault();
        // Assuming numberOfApartment is a string, parse it to an integer
        const numberOfApartments = parseInt(numberOfApartment, 10);
        const apartments = [];

        // Check if parsing was successful
        if (!isNaN(numberOfApartments)) {
            // Initialize an empty array to hold apartment objects

            // Create apartment objects based on the number of apartments
            for (let i = 1; i <= numberOfApartments; i++) {
                // You can create an apartment object with number, email, and account properties
                const apartment = {
                    number: i,  // Apartment number (e.g., 1, 2, 3, ...)
                    email: "",  // Initialize email as empty string
                    account: "", // Initialize account as empty string

                };

                // Push the apartment object to the apartments array
                apartments.push(apartment);
            }

            // Now, you have an array of apartment objects ready to be used

        } else {
            // Handle the case where numberOfApartment is not a valid number
            console.error("Invalid numberOfApartment value:", numberOfApartment);
        }
        try {
            const buildingDoc = {
                address,
                enter,
                numberOfApartment: numberOfApartments, // Update numberOfApartment to the parsed number
                manager,
                apartments,
            };
            console.log(buildingDoc);
            const response = await BuildingDataService.postBuilding(buildingDoc);
            console.log(response)// Use BuildingDataService.postBuilding instead of UserDataService.createUser
            if (response.status === 200) {
                // Registration successful
                setBuilding(response.data);
                history.goBack(); // Redirect to the login page
            } else {
                // Registration failed
                alert("Registration failed. Please check the fields.");
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("An error occurred during registration. Please try again later.");
        }
    }

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then((response) => {
                setUsers(response.data.users);
                console.log(users)
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrieveUsers();
    }, []);

    return (
        <div>
            <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
                <Box mb="70px">
                    <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                </Box>
                <Flex justify="center" align="center" height="100vh">
                    <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                        <Heading as="h2" size="lg" mb="4">
                            Register Building
                        </Heading>
                        <form onSubmit={handleRegister}>
                            <FormControl mt="4">
                                <FormLabel>Address</FormLabel>
                                <Input
                                    required
                                    placeholder="Enter the building address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Enter</FormLabel>
                                <Input
                                    required
                                    placeholder="Enter the building entrance"
                                    value={enter}
                                    onChange={(e) => setEnter(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Number of Apartments</FormLabel>
                                <Input
                                    required
                                    type="number"
                                    placeholder="Enter the number of apartments"
                                    value={numberOfApartment}
                                    onChange={(e) => setNumberOfApartment(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Manager</FormLabel>
                                <Select
                                    required
                                    placeholder="Select recipient's username"
                                    name="toUser"
                                    value={manager ? manager._id : ''}
                                    onChange={(e) => {
                                        const selectedUserId = e.target.value;
                                        const selectedUser = users.find(user => user._id === selectedUserId);
                                        setManager(selectedUser);
                                    }}
                                >
                                    {users
                                        .filter(user => user.role ="Manager" && user._id !== currentUser.id)
                                        .map((user) => (
                                            <option key={user._id}
                                                    value={user._id}> {/* Set the value to the user's _id */}
                                                {user.fullName}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>

                            {/* Residents can be added similarly */}
                            <FormControl mt="4">
                                <Button variant="brand" w="100%" size="lg" onClick={handleRegister}>
                                    Register Building
                                </Button>
                            </FormControl>
                        </form>
                    </Box>
                    {manager && (
                        <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                            <Heading as="h2" size="lg" mb="4">
                                Selected Manager
                            </Heading>
                            <Box>
                                {/* Display manager's details here */}
                                <Text>{manager.fullName}</Text>
                                <Text>{manager.email}</Text>
                                {/* Add more details as needed */}
                            </Box>
                        </Box>
                    )}
                    {console.log("manager:", manager)} {/* Add this line */}
                </Flex>
            </Box>
        </div>
    );
}

export default BuildingRegister; // Export the updated component
