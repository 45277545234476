import http from "./http-common";
import instance from "./http-common";

class InvoiceDataService {
    getAll(page = 0) {
        return http.get(`invoices?page=${page}`);
    }

    get(id) {
        return http.get(`invoices?id=${id}`);
    }

    getNames() {
        return http.get(`/invoiceNames`);
    }

    find(query, by) {
        console.log(query, by)
        return http.get(`invoices?${by}=${query}`);
    }
    upload(query, by) {
        console.log(query, by);
        return http.get(`upload?${by}=${query}`);
    }

    postInvoice(data) {
        console.log(data)
        return http.post("/invoices", data);
    }
    updateInvoice(data) {
        console.log(data)
        return instance.put("/invoices", data);
    }
    postManagerInvoice(data) {
        console.log(data);
        return instance.post("/managerInvoicesAdd", data);
    }

    getManagerInvoices() {
        return http.get("/managerInvoicesGet");
    }

    deleteInvoice(dateSend) {
        console.log(dateSend)
        return http.delete(`/invoices?dateSend=${dateSend}`);
    }

    deleteAllInvoices() {
        return http.delete('/deleteAllInvoices');
    }

    getInvoicesByClientId(ClientId) {
        return http.get(`/getCoursesDetails?ClientId=${ClientId}`);
    }
}

export default new InvoiceDataService();
