import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {FormControl, FormLabel, Heading, Input, Button, Flex, Select, Box, Text, Checkbox} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import {useToast} from "@chakra-ui/toast";
import AuthService from "../../../../services/authService";
import UserDataService from "../../../../services/userService";
import InvoiceDataService from "../../../../services/invoiceService";
import CategoryDataService from "../../../../services/categoryService";
import Banner from "../../components/Banner";
import LoadPdf from "../managerDashboardInvoice/components/LoadPdf";
import banner from "../../../../../UIX/assets/img/auth/banner.png";

function AddInvoice(props) {
    const {idBuilding} = useParams();
    console.log(idBuilding)
    const {username} = AuthService.getCurrentUser();
    const history = useHistory();
    const [invoicePDF, setInvoicePDF] = useState(null);
    const [isPDFUploaded, setIsPDFUploaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [invoiceImage, setInvoiceImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imageurl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);
    const initialInvoice = {
        category: "",
        amount: "",
        dateSend: "",
        datePayed: "",
        building: "",
        invoiceImage: "",
    };
    const [invoice, setInvoice] = useState(initialInvoice);
    const toast = useToast();

    useEffect(() => {
        retrieveUsers();
        retrieveCategories();
    }, []);


    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(async (response) => {
                const fetchedUsers = response.data.users;
                const currentUserManager = await UserDataService.getUserByName(username);
                const updatedInvoice = {
                    ...invoice,
                    building: idBuilding,
                };
                setInvoice(updatedInvoice);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveCategories = () => {
        CategoryDataService.getAll()
            .then((response) => {
                setCategories(response.data.categories);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setInvoice({...invoice, category: category.name});
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('image', file);
        try {
            const response = await InvoiceDataService.upload(formData);

            if (response.ok) {
                // Handle success or show a success message
            } else {
                // Handle error
                console.error('File upload failed');
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error:', error);
        }
    };

    async function convertPdfToImage(file) {
        const pdfData = new Uint8Array(await file.arrayBuffer());
        console.log('Loaded PDF data:', pdfData);
        const STRING_CHAR = pdfData.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
        }, '');
        console.log(STRING_CHAR);
        let base64String = btoa(STRING_CHAR);
        const imageUrl = 'data:image/jpeg;base64,' + base64String;

        setImageUrl(imageUrl);
        console.log(imageUrl)
        downloadBase64AsJPEG(imageUrl, 'downloaded_image.jpg');

        //  downloadImage(imageUrl);
    }

    function downloadBase64AsJPEG(base64Data, fileName) {
        const byteCharacters = atob(base64Data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpeg'});

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName || 'image.jpg';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const postInvoicePDF = (pdfFile) => {
        const data = new FormData();
        data.append("file", pdfFile);
        data.append("upload_preset", "chat-app");
        data.append("cloud_name", "piyushproj");
        fetch("https://api.cloudinary.com/v1_1/piyushproj/image/upload", {
            method: "post",
            body: data,
        })
            .then((res) => res.json())
            .then((data) => {
                setInvoicePDF(data.url.toString());
                setIsPDFUploaded(true);
                console.log(invoicePDF)
            })
            .catch((err) => {
                console.log(err);
                toast({
                    title: "Error uploading PDF file",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom",
                });
            });
    };

    const handleInvoiceAdd = async (event) => {
        event.preventDefault();
        try {
            const userDoc = {
                ...invoice,
                building:idBuilding,
                invoiceImage: invoiceImage,
            };
            console.log(userDoc)
            const response = await InvoiceDataService.postManagerInvoice(userDoc);
            if (response.data.status === "success") {
                history.goBack();
            } else {
                alert("ERROR");
            }
        } catch (error) {
            console.error("Invoice addition error:", error);
        }
    };

    return (
        <Box mt={{base: "180px", md: "80px", xl: "80px"}}>
            <Banner gridArea="1 / 1 / 2 / 2" banner={banner} />
            <Flex justify="center" align="center" height="100vh">
                <Box
                    width="400px"
                    p="4"
                    borderWidth="1px"
                    borderRadius="lg"
                    boxShadow="lg"
                    mx="auto"
                >
                    <Heading as="h2" size="lg" mb="4" textAlign="center">
                        Add Invoice
                    </Heading>
                    <form onSubmit={handleInvoiceAdd}>
                        <FormControl mt="4">
                            <FormLabel>Select Category:</FormLabel>
                            <Select
                                id="categorySelect"
                                value={selectedCategory ? selectedCategory._id : ""}
                                onChange={(e) =>
                                    handleCategorySelect(
                                        categories.find((category) => category._id === e.target.value)
                                    )
                                }
                            >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Select>
                            {selectedCategory && (
                                <Text mt="2" color="gray.500">
                                    Selected Category: {selectedCategory.name}
                                </Text>
                            )}
                        </FormControl>

                        <FormControl mt="4">
                            <FormLabel>Amount:</FormLabel>
                            <Input
                                type="number"
                                id="amountInput"
                                value={invoice.amount}
                                onChange={(e) =>
                                    setInvoice({...invoice, amount: e.target.value})
                                }
                            />
                        </FormControl>

                        <FormControl mt="4">
                            <FormLabel>Invoice Image</FormLabel>
                            <Input
                                type="file"
                                accept="image/*,.pdf" // Accepts both image and PDF files
                                display="none"
                                id="file-input"
                                onChange={(e) => handleFileChange(e.target.files[0])}
                            />
                            <label htmlFor="file-input">
                                <Button
                                    as="span"
                                    bg="blue.500"
                                    color="white"
                                    borderRadius="md"
                                    _hover={{bg: "blue.600"}}
                                    cursor="pointer"
                                >
                                    Select File
                                </Button>
                            </label>
                            {isImageUploaded && (
                                <Checkbox mt="2" isChecked={true} isDisabled>
                                    Image uploaded
                                </Checkbox>
                            )}

                        </FormControl>
                        <Button type="submit" mt="4" colorScheme="blue">
                            Add Invoice
                        </Button>
                    </form>
                </Box>
            </Flex>
        </Box>

);
}

export default AddInvoice;
