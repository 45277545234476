

import React from "react";

// Chakra imports
import {
    Box,
    Grid,
    Text,
    useColorModeValue,
    SimpleGrid, Flex, Input, Button,
} from "@chakra-ui/react";

// Custom components
import Banner from "../components/Banner";

// Assets

import AuthService from "../../../services/authService";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import avatar from "../../../../UIX/assets/img/avatars/avatar4.jpeg";
import TotalSpent from "../../dashboardManager/default/components/TotalSpent";
import PieCard from "../../dashboardManager/default/components/PieCard";
import Interventions from "../../dashboardManager/dashboard/components/Interventions";
import {useHistory, useParams} from "react-router-dom";
function SearchBar({searchTerm, idBuilding,onChange, onClick}) {
    console.log(idBuilding)
    const history = useHistory();
    const handleCreateCatagory = () => {
        console.log(1)
        // Push a new entry onto the history stack and redirect to the desired location
        history.push(`/managerTask/newPool/${idBuilding}`);
    };
    const handleHistoryInvoice = () => {
        history.push(`/managerTask/historyPool/${idBuilding}`);
    };
    return (
        <Flex
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
            bg="gray.200"
            p="10px"
            borderRadius="8px"
        >
            <Input
                variant="filled"
                fontSize="sm"
                bg="white"
                color="gray.800"
                fontWeight="500"
                _placeholder={{color: "gray.400", fontSize: "14px"}}
                borderRadius="4px"
                placeholder="Search by Category, Date, or Month..."
                value={searchTerm}
                onChange={onChange}
                mr="2"
            />
            <Button colorScheme="teal" variant="solid" size="sm" onClick={onClick}>
                Search
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleCreateCatagory}>
                New
            </Button>
            <Button colorScheme="teal" variant="solid" size="sm" onClick={handleHistoryInvoice}>
                Hystory
            </Button>
        </Flex>
    );
}

export default function Finance(props) {
    const {idBuilding} = useParams();
    console.log(idBuilding)
  // Chakra Color Mode
    const currentUser = AuthService.getCurrentUser();
    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/* Main Fields */}
            <Grid
                templateColumns={{
                    base: "1fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{base: "20px", xl: "20px"}}
            >
                <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                    <Banner gridArea="1 / 1 / 2 / 3" banner={banner} idBuilding={idBuilding}/>
                </Box>
            </Grid>

            <Box mt={3} mb={3}>

                <SimpleGrid columns={{md: 2, xl: 2}} mt={3} mb={3} columnGap={4}>
                    <TotalSpent idBuilding={idBuilding} />
                    <PieCard idBuilding={idBuilding} />
                </SimpleGrid>

            </Box>
        </Box>

    );
}
