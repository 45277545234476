import http from "./http-common";
import instance from "./http-common";

class BuildingDataService {
    getAll(page = 0) {
        return http.get(`buildings?page=${page}`);
    }

    get(id) {
        return http.get(`buildings/${id}`);
    }

    getByAddress(address) {
        return http.get(`buildings/address/${address}`);
    }

    getDetails(id) {
        return http.get(`buildings/details/${id}`);
    }

    assignManager(id, managerId) {
        return http.put(`buildings/${id}/assign-manager`, { managerId });
    }

    addResident(id, residentId) {
        return http.put(`buildings/${id}/add-resident`, { residentId });
    }

    removeResident(id, residentId) {
        return http.put(`buildings/${id}/remove-resident`, { residentId });
    }

    postBuilding(data) {
        console.log(data)
        return http.post("/buildings", data);
    }

    updateBuilding(data) {
        console.log(data)
        return http.put(`/buildings`, data);
    }

    deleteBuilding(id) {
        return http.delete(`/buildings/${id}`);
    }
}

export default new BuildingDataService();
