import React, { useEffect } from 'react'; // Import useEffect
import ReactDOM from 'react-dom';
import io from 'socket.io-client'; // Ensure socket.io-client is imported
import 'UIX/assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import AdminTaskLayout from 'layouts/adminTask';
import ManagerLayout from 'layouts/manager';
import ManagerTaskLayout from 'layouts/managerTask';
import UserLayout from 'layouts/user';
import UserTaskLayout from 'layouts/userTask';
import Login from 'views/auth/signIn';
import ForgotPassword from 'views/auth/forgotPassword';
import Register from 'views/auth/register';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'UIX/theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import AuthService from "./views/services/authService";

// Create a component for handling notifications and socket connections
const NotificationHandler = () => {
   // const currentUser = AuthService.getCurrentUser();
//    console.log(currentUser)
    useEffect(() => {
        // Request notification permission
        Notification.requestPermission();
        // Establish a socket.io connection
        // const socket = io("http://localhost:5000")
        const socket = io('https://coprobid.onrender.com:5000');
        socket.on("receiveNotification", (data) => {
            if (Notification.permission === "granted") {
                new Notification(data.title, {
                    body: data.message,
                    // Additional options like icons can be added here
                });
                // Corrected variable name from notification to data
                alert(`${data.title}: ${data.message}`);
            }
        });

        socket.on("receive_message", (data) => {
            alert(data.message);
        });

        // Cleanup on component unmount
        return () => {
            socket.off("receiveNotification");
            socket.disconnect();
        };
    }, []);

    return null; // This component does not render anything
};

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <ThemeEditorProvider>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/sign-in" />} />
                        <Route path="/auth" component={AuthLayout} />
                        <Route path="/admin" component={AdminLayout} />
                        <Route path="/adminTask" component={AdminTaskLayout} />
                        <Route path="/user" component={UserLayout} />
                        <Route path="/userTask" component={UserTaskLayout} />
                        <Route path="/manager" component={ManagerLayout} />
                        <Route path="/managerTask" component={ManagerTaskLayout} />
                        <Route path="/sign-in" component={Login} />
                        <Route path="/sign-up" component={Register} />
                        <Route path="/forgotPassword" component={ForgotPassword} />
                    </Switch>
                    <NotificationHandler /> {/* Add the notification handler */}
                </HashRouter>
            </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>,
    document.getElementById('root')
);
