import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid,
    SimpleGrid,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    useColorModeValue,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import InvoiceDataService from "../../../services/invoiceService";
import Banner from "../Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png";
import InvoiceNFT from "../../../../UIX/components/card/InvoiceNFT";

// Invoice popup component
function InvoicePopup({name, category, amount, dateSend, datePayed, onClose}) {
    return (
        <Modal isOpen={true} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{name}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Text>
                        <strong>Category:</strong> {category}
                    </Text>
                    <Text>
                        <strong>Amount:</strong> {amount}
                    </Text>
                    <Text>
                        <strong>Send :</strong> {dateSend}
                    </Text>
                    <Text>
                        <strong>Payed:</strong> {datePayed}
                    </Text>
                    {/* Add any other invoice details you want to display */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function InvoicesOverview() {
    const history = useHistory();
    const [invoices, setInvoices] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [totalInvoice, setTotalInvoice] = useState(null);
    const [showAmount, setShowAmount] = useState(false);

    const [searchTerm, setSearchTerm] = useState(""); // New state for the search term
    const [filteredInvoices, setFilteredInvoices] = useState([]); // New state for filtered invoices
    const searchIconColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    useEffect(() => {
        // Fetch invoices when the component mounts
        totalInvoices();
        fetchInvoices();
    }, []);

    const totalInvoices = () => {
        const sum = invoices.reduce((acc, invoice) => acc + parseInt(invoice.amount,10), 0);
        setTotalInvoice(sum);
        setShowAmount(true);
    };
    const handleDontShowAmount= async () => {
        setShowAmount(false);

    }
    const fetchInvoices = async () => {
        try {
            const response = await InvoiceDataService.getAll();
            console.log(response);
            if (response.data && Array.isArray(response.data.invoices)) {
                setInvoices(response.data.invoices);
            }
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const handleInvoiceClick = (invoice) => {
        setSelectedInvoice(invoice);
        setShowPopup(true);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const refreshList = () => {
        fetchInvoices();
    };
    
    const find = (query, by) => {
        console.log(query, by);
        InvoiceDataService.find(query, by)
            .then((response) => {
                setInvoices(response.data.invoices);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const findByName = () => {
        console.log("findByName");
        console.log(searchTerm);
        if (searchTerm === "All names") {
            refreshList();
        } else {
            find(searchTerm, "name");
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleAddInvoiceClick = () => {
        console.log("handleAddInvoiceClick");
        history.push("/adminTask/addInvoice"); // Redirect to the "AddInvoice" page
    };

    return (
        <div>
            <Box pt={{base: "180px", md: "80px", xl: "80px"}}>
                {/* Main Fields */}
                <Grid
                    templateColumns={{
                        base: "1fr",
                    }}
                    templateRows={{
                        base: "repeat(3, 1fr)",
                        lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}
                >
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2"}}
                    >
                        <Box mb="20px"> {/* Add a margin to create space between Banner and SimpleGrid */}
                            <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                        </Box>
                        <Flex
                            w={{sm: "100%", md: "auto"}}
                            alignItems="center"
                            flexDirection="row"
                            bg={menuBg}
                            p="10px"
                            borderRadius="30px"
                            boxShadow={shadow}
                        >
                            {/* Search input */}
                            <Box > {/* Add a margin to create space between Banner and SimpleGrid */}
                                <InputGroup w={{base: "100%", md: "200px"}}>
                                <InputLeftElement
                                    children={
                                        <IconButton
                                            bg="inherit"
                                            borderRadius="inherit"
                                            _hover="none"
                                            _active={{
                                                bg: "inherit",
                                                transform: "none",
                                                borderColor: "transparent",
                                            }}
                                            _focus={{
                                                boxShadow: "none",
                                            }}
                                            icon={<SearchIcon color={searchIconColor} w="15px" h="15px"/>}
                                            onClick={findByName} // Call the findByName function when the search icon is clicked
                                        />
                                    }
                                />
                                <Input
                                    variant="search"
                                    fontSize="sm"
                                    bg={inputBg}
                                    color={inputText}
                                    fontWeight="500"
                                    _placeholder={{color: "gray.400", fontSize: "14px"}}
                                    borderRadius="30px"
                                    placeholder="Search..."
                                    value={searchTerm} // Bind the value of the input to the 'searchTerm' state
                                    onChange={handleSearchChange} // Call the handleSearchChange function when the input changes
                                />
                            </InputGroup>
                            </Box>
                            <Box ml={{ base: 0, md: 4 }}>
                            {!showAmount && (
                            <Button
                                variant="darkBrand"
                                color="white"
                                fontSize="sm"
                                fontWeight="500"
                                borderRadius="70px"
                                px="12px"
                                py="5px"
                                onClick={() => {
                                    totalInvoices();
                                }}>   Calculate Total
                            </Button>
                            )}
                            {showAmount && (
                                <Button
                                    variant="darkBrand"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="500"
                                    borderRadius="70px"
                                    px="12px"
                                    py="5px"
                                    onClick={handleDontShowAmount}
                                   >   {totalInvoice !== null ? totalInvoice : 'Calculating...'} shekel
                                </Button>
                            )}
                            </Box>
                        </Flex>
                        <Box pt={{base: "180px", md: "80px", xl: "40px"}}>
                            <SimpleGrid columns={{base: 1, md: 3}} gap="20px">
                                {invoices.map((invoice, i) => (
                                    <div key={i} onClick={() => handleInvoiceClick(invoice)}>
                                        <InvoiceNFT
                                            name={invoice.name}
                                            category={invoice.category}
                                            amount={invoice.amount}
                                            dateSend={invoice.dateSend}
                                        />
                                    </div>
                                ))}
                            </SimpleGrid>
                        </Box>
                    </Flex>
                    <Flex
                        flexDirection="column"
                        gridArea={{xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3"}}
                    >
                    </Flex>
                </Grid>
            </Box>

            {showPopup && selectedInvoice && (
                <div className="invoice-popup">
                    {/* Your Invoice Popup Component */}
                    <InvoicePopup
                        name={selectedInvoice.name}
                        category={selectedInvoice.category}
                        amount={selectedInvoice.amount}
                        dateSend={selectedInvoice.dateSend}
                        datePayed={selectedInvoice.datePayed}
                        onClose={handleClosePopup}
                    />
                </div>
            )}
        </div>
    );
}

export default InvoicesOverview;
