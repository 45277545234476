import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {FormControl, Box, FormLabel, Heading, Input, Button, Flex, Select} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import {useToast} from "@chakra-ui/toast";
import AuthService from "../../../services/authService";
import UserDataService from "../../../services/userService";
import CategoryDataService from "../../../services/categoryService";
import InvoiceDataService from "../../../services/invoiceService";
import PollService from "../../../services/pollService";
import Banner from "../components/Banner";
import banner from "../../../../UIX/assets/img/auth/banner.png"; // Update the import path based on your project structure

function AddPoll(props) {
    const {idBuilding} = useParams();
    const currentUser = AuthService.getCurrentUser();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();

    const [selectedCategory, setSelectedCategory] = useState(null);

    const initialPoll = {
        Subject: "",
        category: "",
        options: ["", "", "", ""],
    };
    const [poll, setPoll] = useState(initialPoll);

    const toast = useToast();

    useEffect(() => {
        retrieveUsers();
        retrieveCategories();
    }, []);

    const retrieveUsers = () => {
        UserDataService.getAll()
            .then(response => {
                setUsers(response.data.users);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retrieveCategories = () => {
        CategoryDataService.getAll()
            .then((response) => {
                setCategories(response.data.categories);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handlePollAdd = async (event) => {
        console.log(poll);
        event.preventDefault();
        try {
            // console.log(poll);
            const pollDoc = {
                Subject: poll.Subject,
                category: category['name'],
                options: poll.options, // Remove empty options

            };
            console.log(pollDoc);
            // Call the API to create the poll using 'pollDoc'
            // ... (code to call API goes here)
            const response = await PollService.create(pollDoc);
            console.log(response);
            if (response.data.status === "success") {
                alert('Poll Add')
                // Invoice addition successful
                history.goBack();
            } else {
                alert("ERROR");
            }
            // Reset the form and state after successful poll creation
            setPoll(initialPoll);
            setSelectedCategory(null);
            setSelectedUser(null);


        } catch (error) {
            console.error("Poll addition error:", error);
        }
    };
    const handleUserCategory = (category) => {

        setCategory(category);
        console.log(category['name'])

    };
    const handleOptionChange = (index, value) => {
        const updatedOptions = [...poll.options];
        updatedOptions[index] = value;
        setPoll({...poll, options: updatedOptions});
    };

    return (
        <div>
            <Box pt={{base: '180px', md: '80px', xl: '80px'}}>
                <Box mb="20px">
                    {/* Add a margin to create space between Banner and Form */}
                    {/* You may pass the "banner" prop based on your requirements */}
                    <Banner gridArea="1 / 1 / 2 / 2" banner={banner}/>
                </Box>
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <Box width="400px" p="4" borderWidth="1px" borderRadius="lg" boxShadow="lg">
                        <Heading as="h2" size="lg" mb="4">
                            Add Poll
                        </Heading>
                        <form onSubmit={handlePollAdd}>
                            <FormControl>
                                <FormLabel>Question:</FormLabel>
                                <Input
                                    type="text"
                                    value={poll.Subject}
                                    onChange={(e) => setPoll({...poll, Subject: e.target.value})}
                                />
                            </FormControl>

                            <FormControl mt="4">
                                <FormLabel>Options:</FormLabel>
                                {poll.options.map((option, index) => (
                                    <Input
                                        key={index}
                                        type="text"
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                        mb="2"
                                    />
                                ))}
                            </FormControl>

                            <FormControl>
                                <FormLabel>Select Category:</FormLabel>
                                <Select
                                    onChange={(e) =>
                                        handleUserCategory(categories.find((category) => category._id === e.target.value))
                                    }
                                >
                                    <option value="">Select Category</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button type="submit" mt="4" colorScheme="blue">
                                Add Poll
                            </Button>
                        </form>
                    </Box>
                </Flex>
            </Box>
        </div>
    );
}

export default AddPoll;
